import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import DeleteModal from 'components/popUpModel/DeleteModal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { PiEye, PiPencil, PiTrash } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  DeleteMerchnatData,
  ListMerchantData,
} from 'reactRedux/thunks/merchnatThunk/merchantThunk';
import CommonTable from '../../../components/commonTable/CommonTable';
import SyncDataModal from 'components/popUpModel/SyncDataModal';
import { SyncDataMerchant } from 'reactRedux/thunks/merchnatThunk/merchantThunk';
import SyncDataLoader from 'components/wholePageLoader/SyncDataLoader';
const MerchantList = () => {
  const [page, setPage] = useState(1);
  // const [search, setSearch] = useState("")
  const dispatch = useDispatch();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const merchantDeleteRef = useRef(null);
  const navigate = useNavigate();
  const { isLoading, merchantListData, isSyncLoading } = useSelector(
    (state) => state.MerchantSlice,
  );

  const [modalType, setModalType] = useState(null); // Track which modal to open

  const openDeleteModal = (merchantId) => {
    setModalType('delete');
    merchantDeleteRef.current = merchantId;
  };

  const openSyncModal = () => {
    setModalType('sync');
  };

  const closeModal = () => {
    setModalType(null);
  };

  const getMerchantList = useCallback(() => {
    dispatch(
      ListMerchantData({
        page: page,
        limit: 10,
        // search: search
      }),
    );
  }, [dispatch, page]);

  useEffect(() => {
    getMerchantList();
  }, [getMerchantList]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.merchantId, {
      id: 'merchantId',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Merchant Id
        </Text>
      ),
      cell: (info) => (
        <Link
          to={`/admin/merchants/details/${info?.row?.original?.merchantId}`}
          className="btn btn-primary rounded-2 p-1"
        >
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {info.getValue()}
          </Text>
        </Link>
      ),
    }),
    columnHelper.accessor((row) => row?.name, {
      id: 'name',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Merchant
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
          {info.getValue() ? info.getValue() : 'N/A'}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.address, {
      id: 'address',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Address
        </Text>
      ),
      cell: (info) => (
        <Flex align="left">
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.locationId, {
      id: 'locationId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Location Id
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor((row) => row?.actions, {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="space-between"
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="50px"
          whiteSpace="nowrap"
        >
          ACTION
        </Text>
      ),
      cell: (row) => (
        <Flex gap={'20px'}>
          <Link
            to={`/admin/merchants/edit/${row?.row?.original?.merchantId}`}
            className="btn btn-light rounded-3 p-1"
          >
            <PiPencil size={20} title="Edit" />
          </Link>
          <Link
            to={`/admin/merchants/details/${row?.row?.original?.merchantId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <PiEye size={20} title="View" className="text-white" />
          </Link>
          <div className="btn btn-danger rounded-3 p-1">
            <PiTrash
              size={20}
              className="text-white"
              onClick={() => openDeleteModal(row?.row?.original?.merchantId)}
              title="Delete"
            />
          </div>
        </Flex>
      ),
    }),
  ];

  return (
    <>
      {isSyncLoading && <SyncDataLoader />}
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex justify="flex-end" mb={4} gap="20px">
          {/* <CommonSearch search={search} setSearch={setSearch} setPage={setPage} /> */}
          <Button
            bg="reviveOrange"
            color="primary"
            borderRadius="12px"
            _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
            onClick={() => navigate('/admin/merchants/add')}
          >
            <Icon
              as={FaPlus}
              width="15px"
              height="15px"
              borderRadius="12px"
              marginRight="5px"
              color="inherit"
            />
            Add Merchant
          </Button>

          <Flex gap="20px" alignItems="center">
            <Button backgroundColor={'red.500'} onClick={() => openSyncModal()}>
              Sync Data
            </Button>
          </Flex>
        </Flex>
        <CommonTable
          columns={columns}
          data={merchantListData?.list}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />

        {merchantListData?.total > 10 && (
          <CommonPagination
            totalCount={merchantListData?.total}
            activePage={page}
            pageCount={Math.ceil(
              merchantListData?.total / merchantListData?.limit,
            )}
            onPageChange={(e) => setPage(e)}
          />
        )}

        {modalType === 'delete' && (
          <DeleteModal
            isOpen={modalType === 'delete'}
            onClose={closeModal}
            title="Are you sure you want to delete this merchant?"
            SolidBTNText="Delete"
            onDelete={() => {
              dispatch(
                DeleteMerchnatData(merchantDeleteRef.current, {}, () => {
                  getMerchantList();
                  closeModal();
                }),
              );
            }}
            loader={isLoading}
          />
        )}

        {modalType === 'sync' && (
          <SyncDataModal
            isOpen={modalType === 'sync'}
            onClose={closeModal}
            title="Are you sure you want to sync data? This will sync merchant, station, and power bank data."
            SolidBTNText="Sync"
            onClick={() => {
              closeModal();
              dispatch(
                SyncDataMerchant(() => {
                  getMerchantList();
                }),
              );
            }}
            loader={isLoading}
          />
        )}
      </Box>
    </>
  );
};

export default MerchantList;
