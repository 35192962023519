import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';

const DeleteModal = ({
  title,
  isOpen,
  onClose,
  SolidBTNText,
  loader,
  onDelete,
}) => {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent
          maxWidth="400px"
          borderRadius="lg"
          boxShadow="lg"
          p={4}
        >
          {/* <AlertDialogHeader
            fontSize="lg"
            fontWeight="medium"
            textAlign="center"
          >
            Confirm Deletion
          </AlertDialogHeader> */}
          <AlertDialogBody
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py={6}
          >
            <FaTrashAlt color="#dc3545" size={60} mb={4} />
            <Text fontSize="18px" fontWeight="medium" textAlign="center" mt={5}>
              {title}
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter justifyContent="center">
            <Button
              variant="outline"
              onClick={onClose}
              padding="10px 20px"
              mr={4}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => onDelete()}
              padding="10px 20px"
              isDisabled={loader}
            >
              {loader ? <Spinner size="md" color="white" /> : SolidBTNText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default React.memo(DeleteModal);
