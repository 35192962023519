import React from 'react';
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
// Assets
import { MdEdit } from 'react-icons/md';

export default function Project(props) {
  const { title, ranking, link, image, ...rest } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const brandColor = useColorModeValue('secondary', 'white');
  const bg = useColorModeValue('white', 'navy.700');

  return (
    <Card bg={bg} {...rest} p="14px">
      <Flex align="center" direction={{ base: 'column', md: 'row' }}>
        {/* Project Image */}
        <Image
          h="80px"
          w="80px"
          src={image}
          borderRadius="8px"
          me="20px"
          alt={`${title} thumbnail`}
        />

        {/* Project Details */}
        <Box mt={{ base: '10px', md: '0' }}>
          <Text
            color={textColorPrimary}
            fontWeight="500"
            fontSize="md"
            mb="4px"
          >
            {title}
          </Text>
          <Text
            color={textColorSecondary}
            fontWeight="500"
            fontSize="sm"
            me="4px"
          >
            Project#{ranking} •{' '}
            <Link
              fontWeight="500"
              color={brandColor}
              href={link}
              fontSize="sm"
              isExternal
            >
              See project details
            </Link>
          </Text>
        </Box>

        {/* Edit Icon */}
        <Link
          href={link}
          variant="no-hover"
          ms="auto"
          p="0"
          aria-label={`Edit ${title}`}
        >
          <Icon as={MdEdit} color="secondaryGray.500" h="18px" w="18px" />
        </Link>
      </Flex>
    </Card>
  );
}
