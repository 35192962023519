import React from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import CommonTable from 'components/commonTable/CommonTable';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PiEye, PiPencil, PiTrash } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  DeleteStationData,
  ListStationData,
} from 'reactRedux/thunks/stationThunk/stationThunk';
import { stationStatus, stationStatusColors } from 'utils/StaticData';
import { sideBarRoutes } from 'utils/StaticData';
import DeleteModal from 'components/popUpModel/DeleteModal';

const StationList = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const stationDeleteRef = useRef(null);
  // const [search, setSearch] = useState("")
  const { isLoading, stationListData } = useSelector(
    (state) => state.StationSlice,
  );
  const { adminDetails } = useSelector((state) => state.authSlice);
  const getStationList = useCallback(() => {
    dispatch(
      ListStationData({
        page: page,
        limit: 10,
        // search: search
      }),
    );
  }, [dispatch, page]);

  useEffect(() => {
    getStationList();
  }, [getStationList]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.stationId, {
      id: 'stationId',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Station Id
        </Text>
      ),
      cell: (info) => (
        <Link
          to={`/admin/stations/details/${info?.row?.original?.stationId}`}
          className="btn btn-primary rounded-2 p-1"
        >
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {info.getValue()}
          </Text>
        </Link>
      ),
    }),
    columnHelper.accessor((row) => row?.slots, {
      id: 'slots',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Slots
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.merchantName, {
      id: 'merchantName',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Merchant
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
          {info.getValue() ? info.getValue() : 'N/A'}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.status, {
      id: 'status',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        const status = info.getValue();
        const statusText = stationStatus[status] || 'N/A';
        const statusColor = stationStatusColors[status] || 'red.500';
        return (
          <Text color={statusColor} fontSize="sm" fontWeight="600" align="left">
            {statusText}
          </Text>
        );
      },
    }),
    columnHelper.accessor((row) => row?.actions, {
      id: 'actions',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          ACTION
        </Text>
      ),
      cell: (row) => (
        <Flex gap={'20px'}>
          {adminDetails?.role === sideBarRoutes?.Admin && (
            <Link
              to={`/admin/stations/edit/${row?.row?.original?.stationId}`}
              className="btn btn-light rounded-3 p-1"
            >
              <PiPencil size={20} title="Edit" />
            </Link>
          )}
          <Link
            to={`/admin/stations/details/${row?.row?.original?.stationId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <PiEye size={20} title="View" className="text-white" />
          </Link>
          {adminDetails?.role === sideBarRoutes?.Admin && (
            <div className="btn btn-danger rounded-3 p-1">
              <PiTrash
                size={20}
                className="text-white"
                onClick={() => {
                  // setShow(true);
                  onOpen();
                  stationDeleteRef.current = row?.row?.original?.stationId;
                }}
                title="Delete"
              />
            </div>
          )}
        </Flex>
      ),
    }),
  ];

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex justify="flex-end" mb={4} gap="20px">
          {/* <CommonSearch search={search} setSearch={setSearch} setPage={setPage} /> */}
          {/* <Button
            bg="reviveOrange"
            color="primary"
            borderRadius="12px"
            _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
            onClick={() => navigate('/admin/stations/add')}
          >
            <Icon
              as={FaPlus}
              width="15px"
              height="15px"
              marginRight="5px"
              color="inherit"
            />
            Add Station
          </Button> */}
        </Flex>
        <CommonTable
          columns={columns}
          data={stationListData?.list}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />
        {stationListData?.total > 10 && (
          <CommonPagination
            totalCount={stationListData?.total}
            activePage={page}
            pageCount={Math.ceil(
              stationListData?.total / stationListData?.limit,
            )}
            onPageChange={(e) => setPage(e)}
          />
        )}

        <DeleteModal
          isOpen={isOpen}
          onClose={onClose}
          title="Are you sure you want to delete this station?"
          SolidBTNText="Delete"
          onDelete={() => {
            dispatch(
              DeleteStationData(stationDeleteRef.current, {}, () => {
                getStationList();
                onClose();
              }),
            );
          }}
          loader={isLoading}
        />
      </Box>
    </>
  );
};

export default StationList;
