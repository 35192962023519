import { Input, Box } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

const CommonSearch = ({ search, setSearch, setPage }) => {
  const { register } = useForm({ mode: "onChange" })

  const handleSearch = useCallback((e) => {
    setTimeout(() => {
      setSearch(e.target.value)
      setPage(1)
    }, 1000)
  }, [])

  return (
    <>
    <Box>
      <Input
        type="search"
        name="search"
        placeholder="Search"
        autoComplete='off'
        {...register('search', {
          onChange: (e) => {
            handleSearch(e)
          }
        })}
      />
      </Box>
    </>
  )
}

export default CommonSearch