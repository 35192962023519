import React from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FaChevronDown } from 'react-icons/fa';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { enGB } from 'date-fns/locale';
import { formatCurrency } from 'utils/CommonFunction';
import { sideBarRoutes } from 'utils/StaticData';
import { customStaticRanges } from 'utils/StaticData';
import PropTypes from 'prop-types';

const RevenueOverViewGraph = ({
  adminDetails,
  handleSelectChange,
  selectedMerchant,
  currency,
  merchantNameList,
  revenueOverviewData,
  showCalendar,
  setShowCalendar,
  tempDateRange,
  dateRange,
  calendarFilterTitle,
  handleApply,
  handleClear,
  handleSelect,
}) => {
  return (
    <>
      {/* Revenue Overview */}
      <Box>
        {/* Header and Filters in One Row */}
        <Flex justify="space-between" align="center" flexWrap="wrap" gap="20px">
          <Text fontSize="2xl" fontWeight="semibold">
            Revenue Overview
          </Text>

          {/* Filters */}
          <HStack spacing="5">
            {adminDetails?.role === sideBarRoutes?.Admin && (
              <Select
                placeholder="All merchants"
                bg="white"
                borderColor="reviveGray"
                color="#292D32"
                fontWeight="semibold"
                borderRadius="8px"
                onChange={handleSelectChange}
                value={selectedMerchant}
              >
                {merchantNameList?.map((merchant) => (
                  <option key={merchant.merchantId} value={merchant.merchantId}>
                    {merchant.name}
                  </option>
                ))}
              </Select>
            )}
            <Box position="relative" flexShrink="0">
              <Button
                py="10px"
                px="15"
                fontWeight="semibold"
                type="button"
                bg="white"
                border="1px solid"
                borderColor="reviveGray"
                color="#292D32"
                borderRadius="8px"
                onClick={() => setShowCalendar(!showCalendar)}
              >
                {calendarFilterTitle}{' '}
                <FaChevronDown size={11} style={{ marginLeft: '8px' }} />
              </Button>
              {/* Date Range Picker Dropdown */}
              {showCalendar && (
                <>
                  <Box
                    position="fixed"
                    bg="rgb(0 0 0 / 40%)"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    zIndex="9"
                    onClick={() => setShowCalendar(false)}
                  ></Box>
                  <Box
                    p="32px"
                    mt="8px"
                    position="absolute"
                    right="0"
                    zIndex="10"
                    bg="white"
                    boxShadow="0px 0px 40px rgba(32, 57, 70, 0.06)"
                    borderRadius="20px"
                    className="custom-date-picker"
                  >
                    <DateRangePicker
                      ranges={tempDateRange}
                      onChange={handleSelect}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      direction="horizontal"
                      locale={enGB}
                      minDate={new Date(2000, 0, 1)}
                      maxDate={new Date()}
                      staticRanges={customStaticRanges}
                      inputRanges={[]}
                    />
                    <Flex justifyContent="right" gap="12px" marginTop="30px">
                      <Button
                        className="clear-btn"
                        border="1px solid"
                        borderColor="reviveGray"
                        borderRadius="10px"
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                      <Button
                        className="apply-btn"
                        bg="#6658fd"
                        border="1px solid"
                        borderColor="#6658fd"
                        color="white"
                        borderRadius="10px"
                        _hover={{
                          bg: 'transparent',
                          color: '#6658fd',
                        }}
                        onClick={handleApply}
                      >
                        Apply
                      </Button>
                    </Flex>
                  </Box>
                </>
              )}
            </Box>
          </HStack>
        </Flex>
      </Box>

      {/* Charts */}

      <Flex
        mt="6"
        gap={{ base: '24px', md: '16px', lg: '24px' }}
        flexWrap="wrap"
      >
        <Box
          w={{
            base: '100%',
            xl: 'calc(33.33% - 21.33px)',
          }}
          p={{ base: '4', md: '5' }}
          bg="white"
          border="1px solid #D9D9D9"
          borderRadius="20px"
          boxShadow="0px 0px 20px rgba(32, 57, 70, 0.06)"
        >
          {/* Selected Period Title */}
          <Text
            fontSize={{ base: '16px', md: '24px' }}
            fontWeight="semibold"
            mb="30px"
            color="primary"
          >
            {`Selected Period ${dateRange[0].startDate.toLocaleDateString()} - ${dateRange[0].endDate.toLocaleDateString()}`}
          </Text>

          {/* Data Rows */}
          <VStack spacing="3" mt="4" align="stretch">
            <Flex justify="space-between">
              <Text fontWeight="medium" color="#292D32">
                Captured
              </Text>
              <Text fontSize="sm">
                {formatCurrency(revenueOverviewData?.totalRevenue, currency)}
              </Text>
            </Flex>
            <hr />

            <Flex justify="space-between">
              <Text fontWeight="medium" color="#292D32">
                Failed
              </Text>
              <Text fontSize="sm">{revenueOverviewData.failedCount}</Text>
            </Flex>
            <hr />
            <Flex justify="space-between">
              <Text fontWeight="medium" color="#292D32">
                Powerbanks Rented
              </Text>
              <Text fontSize="sm">{revenueOverviewData.totalRented}</Text>
            </Flex>
            <hr />
            <Flex justify="space-between">
              <Text fontWeight="medium" color="#292D32">
                Active Stations
              </Text>
              <Text fontSize="sm">{revenueOverviewData.stationCount}</Text>
            </Flex>
          </VStack>
        </Box>
        <Box
          w={{
            base: '100%',
            lg: 'calc(50% - 16px)',
            xl: 'calc(33.33% - 21.33px)',
          }}
          p={{ base: '4', md: '5' }}
          bg="white"
          border="1px solid #D9D9D9"
          borderRadius="20px"
          boxShadow="0px 0px 20px rgba(32, 57, 70, 0.06)"
        >
          <Text
            fontSize={{ base: '16px', md: '24px' }}
            fontWeight="semibold"
            mb="30px"
            color="primary"
          >
            Gross Revenue
          </Text>
          <ResponsiveContainer width={'100%'} height={300}>
            <LineChart
              data={revenueOverviewData?.grossRevenue}
              margin={{ top: 20 }}
              accessibilityLayer
            >
              <XAxis dataKey="time" padding={{ left: 30, right: 30 }} />
              <YAxis
                width={80}
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => formatCurrency(value, currency)}
              />
              <Tooltip
                formatter={(value) => formatCurrency(value, currency)}
                labelFormatter={(label) => `${label}`}
              />
              <CartesianGrid
                strokeDasharray="3 3"
                horizontal={true}
                vertical={false}
              />
              <Line
                type="monotone"
                dataKey="total"
                stroke="#000000"
                strokeWidth={2}
                activeDot={{ r: 8 }}
              ></Line>
            </LineChart>
          </ResponsiveContainer>
        </Box>
        <Box
          w={{
            base: '100%',
            lg: 'calc(50% - 16px)',
            xl: 'calc(33.33% - 21.33px)',
          }}
          p={{ base: '4', md: '5' }}
          bg="white"
          border="1px solid #D9D9D9"
          borderRadius="20px"
          boxShadow="0px 0px 20px rgba(32, 57, 70, 0.06)"
        >
          <Text
            fontSize={{ base: '16px', md: '24px' }}
            fontWeight="semibold"
            mb="30px"
            color="primary"
          >
            Powerbanks Rented
          </Text>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={revenueOverviewData.grossRevenueCount}
              margin={{ top: 20 }}
            >
              {/* X-Axis */}
              <XAxis dataKey="time" padding={{ left: 30, right: 30 }} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              {/* Grid with Dotted Lines */}
              <CartesianGrid
                strokeDasharray="3 3"
                horizontal={true}
                vertical={false}
              />

              {/* Line Chart */}
              <Line
                type="monotone"
                dataKey="total"
                stroke="#000000"
                strokeWidth={2}
                dot={true}
                activeDot={{ r: 6 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Flex>
    </>
  );
};

RevenueOverViewGraph.propTypes = {
  revenueOverviewData: PropTypes.any,
  currency: PropTypes.string,
  adminDetails: PropTypes.object,
  handleSelectChange: PropTypes.func.isRequired,
  selectedMerchant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  merchantNameList: PropTypes.arrayOf(PropTypes.object),
  showCalendar: PropTypes.bool,
  setShowCalendar: PropTypes.func.isRequired,
  tempDateRange: PropTypes.array,
  dateRange: PropTypes.any,
  calendarFilterTitle: PropTypes.string,
  handleApply: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default RevenueOverViewGraph;
