import { Box, FormLabel, Input, Text } from '@chakra-ui/react'
import React from 'react'

const CommonInput = ({ labelText, textColor, brandStars, placeholder, register, errors, name, type }) => {
  return (
    <>
      <Box>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          mb='8px'>
          {labelText}<Text color={brandStars}>*</Text>
        </FormLabel>
        <Input
        type={type}
          isRequired
          variant='auth'
          fontSize='sm'
          placeholder={placeholder}
          fontWeight='500'
          size='lg'
          {...register}
          name={name}
        />
        {errors && (
          <p style={{ color: "red", fontSize: '14px' }}>{errors?.message}</p>
        )}
      </Box>
    </>
  )
}

export default CommonInput