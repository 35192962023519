import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import ProtectedRoute from 'components/routing/ProtectedRoutes';
import PublicRoute from 'components/routing/PublicRoutes';
import AdminLayout from 'layouts/admin';
import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignInCentered from 'views/auth/signIn';
import './assets/css/App.css';
import initialTheme from './theme/theme';
import useAuthCheck from 'components/checkToken/useAuthCheck';
import NotFound from 'NotFound';
import ResetPassword from 'views/auth/resetPassword/ResetPassword';
import ForgotPassword from 'views/auth/forgotPassword/ForgotPassword';

export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  useAuthCheck(); ////// Check token is valid or not after geeting from login
  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        {/* Public routes */}
        <Route element={<PublicRoute />}>
          <Route path="auth/*" element={<SignInCentered />} />
        </Route>

        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
          <Route
            path="/admin/*"
            element={
              <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
            }
          >
            <Route />
          </Route>
        </Route>

        <Route path="/404" element={<NotFound />} />
        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/set-password" element={<ResetPassword />} />
      </Routes>
    </ChakraProvider>
  );
}
