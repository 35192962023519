import React from 'react';
import {
  Box,
  Tooltip as ChakraTooltip,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { sideBarRoutes } from 'utils/StaticData';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import PropTypes from 'prop-types';
import { errorsColor } from 'utils/StaticData';

const RecurringuserGraph = ({
  adminDetails,
  showTooltip,
  setShowTooltip,
  errorOverviewData,
  recurringUsers,
}) => {
  const { errorsByStation = [], errorCounts = [] } = errorOverviewData || {};
  let errorlabales = [];

  if (errorsByStation?.length) {
    errorlabales = Object.keys(errorsByStation[0]).filter(
      (e) => e !== 'station',
    );
  }

  const CustomTooltipForRecurring = ({ active, payload }) => {
    const label =
      adminDetails?.role !== sideBarRoutes?.Admin ? 'Station' : 'Merchant';

    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            background: '#fff',
            padding: '10px',
            border: '1px solid #ccc',
          }}
        >
          <p>
            {label}: {data.name}
          </p>
          <p>Card Last 4: {data.last4}</p>
          <p>Count: {data.count}</p>
        </div>
      );
    }
    return null;
  };

  CustomTooltipForRecurring.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
  };

  return (
    <>
      <Box
        w={{
          base: '100%',
          // lg: 'calc(50% - 16px)',
          '2xl': 'calc(33.33% - 21.33px)',
        }}
        p={{ base: '4', md: '5' }}
        bg="white"
        boxShadow="0px 0px 20px rgba(32, 57, 70, 0.06)"
        borderRadius="20px"
        border="1px solid #D9D9D9"
      >
        <Flex justifyContent="space-between" alignItems="center" mb="30px">
          <Text
            fontSize={{ base: '16px', md: '24px' }}
            fontWeight="semibold"
            mb="30px"
            color="primary"
          >
            Number of Reoccurring Users
          </Text>
          <ChakraTooltip
            label="The Graph Highlights the Number of Reoccurring Users. A Reoccurring User is Defined as the User, whose last 4 Digits of Card Occurred in the Dataset more than once."
            fontSize="sm"
            placement="bottom"
            isOpen={showTooltip}
            backgroundColor={'white'}
            color={'black'}
          >
            <IconButton
              marginBottom="6"
              aria-label="Info"
              icon={<IoMdInformationCircleOutline size={28} />}
              variant="ghost"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />
          </ChakraTooltip>
        </Flex>

        {recurringUsers?.length > 0 ? (
          <ResponsiveContainer
            width="100%"
            height={300}
            className="chart_scale"
          >
            <BarChart
              data={recurringUsers}
              margin={{ top: 20, right: 10, left: 0, bottom: 50 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                horizontal={true}
                vertical={false}
              />
              <XAxis
                dataKey="name"
                interval={0}
                tick={{
                  angle: recurringUsers?.length < 2 ? 0 : -60,
                  textAnchor: recurringUsers?.length < 2 ? 'middle' : 'end',
                  fontSize: 12,
                }}
              />
              <YAxis
                domain={[
                  0,
                  Math.max(...recurringUsers.map((item) => item.name), 0) + 1,
                ]}
                tickInterval={1}
                allowDecimals={false}
                axisLine={false}
                tickLine={false}
              />
              <Tooltip content={<CustomTooltipForRecurring />} />

              <Bar
                dataKey="count"
                fill="url(#colorGradient)"
                radius={[5, 5, 0, 0]}
              >
                <LabelList
                  dataKey="count"
                  position="top"
                  fill="#000"
                  fontSize={14}
                />
              </Bar>
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#00ff99" />
                  <stop offset="100%" stopColor="#00cc66" />
                </linearGradient>
              </defs>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Flex justify="center" alignItems="center" height="300px">
            <Text fontSize="18px" fontWeight="bold" color="gray.500">
              No records available
            </Text>
          </Flex>
        )}
      </Box>

      <Flex
        bg="white"
        p={{ base: '4', md: '5' }}
        gap={{ base: '16px', lg: '24px' }}
        flexWrap="wrap"
        boxShadow="0px 0px 20px rgba(32, 57, 70, 0.06)"
        borderRadius="20px"
        border="1px solid #D9D9D9"
        w={{
          base: '100%',
          // lg: 'calc(50% - 16px)',
          '2xl': 'calc(66.67% - 4.33px)',
        }}
      >
        {/* Station Data Graph */}
        <Box
          w={{
            base: '100%',
            lg: 'calc(60% - 16px)',
          }}
          p={{ base: '4', md: '5' }}
          bg="white"
          boxShadow="0px 0px 20px rgba(32, 57, 70, 0.06)"
          borderRadius="20px"
          border="1px solid #D9D9D9"
        >
          <Flex justify="space-between" align="baseline">
            {/* Title */}
            <Text
              fontSize={{ base: '16px', md: '24px' }}
              fontWeight="semibold"
              color="primary"
            >
              Failures Per Station
            </Text>

            {/* Legend */}
            <Flex flexWrap="wrap" align="left" flexDirection="column">
              {errorlabales?.map((item, index) => (
                <Flex key={index} align="center" ml="4">
                  <Box
                    w="10px"
                    h="10px"
                    bg={errorsColor[index]}
                    borderRadius="50%"
                    mr="2"
                  />
                  <Text fontSize={{ base: '8px', md: '12px' }}>{item}</Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
          {errorsByStation?.length > 0 ? (
            <ResponsiveContainer
              width="100%"
              height={300}
              className="chart_scale"
            >
              <BarChart
                data={errorsByStation}
                margin={{ top: 10, right: 30, left: 30, bottom: 30 }}
                barCategoryGap={errorsByStation?.length < 5 ? '10%' : '20%'}
                barGap={0}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  horizontal={true}
                  vertical={false}
                />
                <XAxis
                  dataKey="station"
                  interval={0}
                  tick={{
                    angle: errorsByStation?.length < 5 ? 0 : -30,
                    textAnchor: errorsByStation?.length < 5 ? 'middle' : 'end',
                    fontSize: 12,
                  }}
                  label={{
                    value: 'Station ID',
                    position: 'insideBottom',
                    dy: 34,
                    style: {
                      fontSize: '14px',
                      fill: '#333',
                      transform:
                        errorsByStation?.length < 5 ? 'none' : 'scaleX(1)',
                    },
                  }}
                />
                <YAxis
                  allowDecimals={false}
                  axisLine={false}
                  tickLine={false}
                />
                <Tooltip />
                {errorlabales?.map((e, i) => {
                  const CustomBarShape = (props) => {
                    // eslint-disable-next-line react/prop-types
                    const { x, y, width, height, station } = props;

                    // Calculate custom width based on number of stations
                    const customWidth =
                      errorsByStation?.length < 5
                        ? errorsByStation?.length === 1
                          ? 60
                          : errorsByStation?.length === 2
                            ? 60
                            : errorsByStation?.length === 3
                              ? 50
                              : 40
                        : width;

                    // Calculate centered position
                    // eslint-disable-next-line no-mixed-operators
                    const centeredX = x + (width - customWidth) / 2;

                    // Find active errors
                    const activeErrors = errorlabales.filter(
                      (error) =>
                        errorsByStation.find((s) => s.station === station)?.[
                          error
                        ] > 0,
                    );

                    const isTopBar =
                      activeErrors[activeErrors.length - 1] === e;

                    if (!isTopBar) {
                      return (
                        <rect
                          x={centeredX}
                          y={y}
                          width={customWidth}
                          height={height}
                          fill={errorsColor[i]}
                        />
                      );
                    }

                    // Rounded top corners only
                    const radius = 5;
                    return (
                      <path
                        d={`
                            M${centeredX},${y + radius} 
                            Q${centeredX},${y} ${centeredX + radius},${y}
                            L${centeredX + customWidth - radius},${y}
                            Q${centeredX + customWidth},${y} ${centeredX + customWidth},${y + radius}
                            L${centeredX + customWidth},${y + height}
                            L${centeredX},${y + height}
                            Z
                          `}
                        fill={errorsColor[i]}
                      />
                    );
                  };

                  return (
                    <Bar
                      key={e}
                      dataKey={e}
                      fill={errorsColor[i]}
                      name={e}
                      stackId="a"
                      shape={<CustomBarShape />}
                    />
                  );
                })}
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Flex justify="center" alignItems="center" height="300px">
              <Text fontSize="18px" fontWeight="bold" color="gray.500">
                No records available
              </Text>
            </Flex>
          )}
        </Box>
        {/* Decline reason Graph */}
        <Box
          w={{
            base: '100%',
            lg: 'calc(40% - 9px)',
          }}
          p="5"
          bg="white"
          boxShadow="0px 0px 20px rgba(32, 57, 70, 0.06)"
          borderRadius="20px"
          border="1px solid #D9D9D9"
        >
          <Text
            fontSize={{ base: '16px', md: '24px' }}
            fontWeight="semibold"
            color="primary"
          >
            Split of Decline Reasons
          </Text>
          {errorCounts?.length > 0 ? (
            <ResponsiveContainer
              width="100%"
              height={300}
              className="chart_round"
            >
              <PieChart>
                <Pie
                  data={errorCounts}
                  cx="50%"
                  cy="75%"
                  startAngle={180}
                  endAngle={0}
                  innerRadius={80} // Adjust inner radius
                  outerRadius={150} // Increase size for better visibility
                  dataKey="count"
                >
                  {errorCounts?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={errorsColor[index]} />
                  ))}
                </Pie>
                {/* tootip */}
                <Tooltip
                  formatter={(value, name) => [`${value}`, name]}
                  contentStyle={{
                    backgroundColor: '#fff',
                    padding: '8px',
                    boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
                  }}
                />

                {/* Center Number Label */}
                <text
                  x="50%"
                  y="70%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize="36px"
                  fontWeight="bold"
                  fill="#0F2C3E"
                >
                  {errorCounts.reduce((sum, item) => sum + item.count, 0)}
                </text>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Flex justify="center" align="center" height="300px">
              <Text fontSize="18px" fontWeight="bold" color="gray.500">
                No records available
              </Text>
            </Flex>
          )}

          {/* Legend */}
          <Flex mt="4" justify="center" flexWrap="wrap">
            {errorlabales?.map((item, index) => (
              <Flex key={index} alignItems="center" mx="2">
                <Box
                  w="10px"
                  h="10px"
                  bg={errorsColor[index]}
                  borderRadius="50%"
                  mr="2"
                />
                <Text fontSize="12px">{item}</Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

RecurringuserGraph.propTypes = {
  adminDetails: PropTypes.object,
  showTooltip: PropTypes.bool,
  setShowTooltip: PropTypes.func,
  errorOverviewData: PropTypes.any,
  recurringUsers: PropTypes.any,
};

export default RecurringuserGraph;
