import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import {
  FormLabel,
  Text,
  Input,
  Box,
  List,
  ListItem,
  useColorModeValue,
  GridItem,
} from '@chakra-ui/react';

const GoogleLatLng = ({ coordinates, setCoordinates, initialAddress }) => {
  const textColor = useColorModeValue('navy.700', 'white');
  const brandStars = useColorModeValue('secondary', 'brand.400');
  const [suggestions, setSuggestions] = useState([]);
  const [address, setAddress] = useState(initialAddress);
  const handleAddressChange = async (event) => {
    const input = event.target.value;
    setAddress(input);

    if (input) {
      try {
        const response = await fetch(
          `https://cors.stage.eugeniuses.com/https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSuggestions(data.predictions);
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = async (placeId) => {
    try {
      const response = await fetch(
        `https://cors.stage.eugeniuses.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const location = data.result.geometry.location;

      setCoordinates({
        lat: location.lat,
        lng: location.lng,
      });
      setAddress(data.result.formatted_address);
      setSuggestions([]);
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
  };

  useEffect(() => {
    setAddress(initialAddress);
  }, [initialAddress]);

  return (
    <>
      <Box position="relative">
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Coordinates <Text color={brandStars}>*</Text>
        </FormLabel>
        <Input
          variant="auth"
          fontSize="sm"
          mb="2px"
          fontWeight="500"
          size="lg"
          value={address}
          onChange={handleAddressChange}
          placeholder="Enter an address"
        />

        {suggestions.length > 0 && (
          <Box
            border="1px"
            position="absolute"
            zIndex="1"
            bg="white"
            borderColor="gray.200"
            borderRadius="md"
            maxH="200px"
            overflowY="auto"
          >
            <List spacing={1}>
              {suggestions?.map((suggestion) => (
                <ListItem
                  key={suggestion.place_id}
                  onClick={() => handleSuggestionClick(suggestion.place_id)}
                  cursor="pointer"
                  padding="8px"
                  _hover={{ backgroundColor: 'gray.100' }}
                >
                  {suggestion.description}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
      <GridItem colSpan={{ base: 1, lg: 3 }}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        >
          <GoogleMap
            mapContainerStyle={{ height: '400px', width: '100%' }}
            center={coordinates}
            zoom={10}
            onClick={(event) => {
              setCoordinates({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
              });
              setAddress(''); 
              setSuggestions([]); 
            }}
          >
            <Marker position={coordinates} />
          </GoogleMap>
        </LoadScript>
      </GridItem>
    </>
  );
};

export default GoogleLatLng;
