import {
  failureDashboardData,
  responseDashboardData,
  requestDashboardData,
  responseRevenueGraphData,
  requestRevenueGraphData,
  failureRevenueGraphData,
  requestRevenueOverviewData,
  responseRevenueOverviewData,
  failureRevenueOverviewhData,
  requestMerchantNameList,
  responseMerchantNameList,
  failureMerchantNameList,
  requestErrorOverviewData,
  responseErrorOverviewData,
  failureErrorOverviewData,
  requestRecurringUsers,
  responseRecurringUsers,
  failureRecurringUsers,
} from 'reactRedux/slices/dashboardSlice/dashboardSlice';
import Swal from 'sweetalert2';
import requestApi from 'utils/Request';
import {
  MERCHANTNAMELIST,
  DASHBOARD_STATUS,
  DASHBOARD_REVENUE,
  DASHBOARD_REVENUE_OVERVIEW,
  DASHBOARD_ERROR_OVERVIEW,
  DASHBOARD_RECURRING_USERS,
} from 'utils/StaticApiEndpoints';

export const GetDashboardGraphData =
  (payload, callback) => async (dispatch) => {
    try {
      dispatch(requestDashboardData());
      const res = await requestApi.get(DASHBOARD_STATUS);
      dispatch(responseDashboardData(res));
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error?.message,
      });
      dispatch(failureDashboardData(error?.message));
    }
  };

export const GetRevenueGraphData = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestRevenueGraphData());
    const res = await requestApi.get(DASHBOARD_REVENUE);
    dispatch(responseRevenueGraphData(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureRevenueGraphData(error?.message));
  }
};

export const RevenueOverview = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestRevenueOverviewData());
    const res = await requestApi.get(DASHBOARD_REVENUE_OVERVIEW, {
      params: payload,
    });
    dispatch(responseRevenueOverviewData(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureRevenueOverviewhData(error?.message));
  }
};

export const GetMerchantNameList = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestMerchantNameList());
    const res = await requestApi.get(MERCHANTNAMELIST, {
      params: payload,
    });
    dispatch(responseMerchantNameList(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureMerchantNameList(error?.message));
  }
};

export const ErrorsOverview = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestErrorOverviewData());
    const res = await requestApi.get(DASHBOARD_ERROR_OVERVIEW, {
      params: payload,
    });
    dispatch(responseErrorOverviewData(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureErrorOverviewData(error?.message));
  }
};

export const ReoccurringUsers = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestRecurringUsers());
    const res = await requestApi.get(DASHBOARD_RECURRING_USERS, {
      params: payload,
    });
    dispatch(responseRecurringUsers(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureRecurringUsers(error?.message));
  }
};
