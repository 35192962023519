import { Box, Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

const SyncDataLoader = () => {
  return (
    <>
      <Box
        pt={{ base: '130px', md: '80px', xl: '80px' }}
        position={'fixed'}
        inset={'0'}
        display={'flex'}
        backgroundColor={'#0000009e'}
        zIndex={'9'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Flex direction="column" align="center" justify="center">
          <Spinner size="lg" color="teal.500" />
        </Flex>
      </Box>
    </>
  );
};

export default SyncDataLoader;
