import { createSlice } from '@reduxjs/toolkit';

const AdminUserSlice = createSlice({
  name: 'AdminUserSlice',
  initialState: {
    isLoading: false,
    isViewLoading: false,
    adminUserListData: [],
    isError: null,
    viewAdminUserDetails: null,
  },
  reducers: {
    /////// Admin User list start //////

    requestAdminUserList: (state) => {
      return { ...state, isLoading: true };
    },
    responseAdminUserList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        adminUserListData: action?.payload?.data,
      };
    },
    failureAdminUserList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
      };
    },

    /////// Admin User Add-Edit //////
    requestAdminUserAdd: (state) => {
      return { ...state, isLoading: true };
    },
    responseAdminUserAdd: (state) => {
      return { ...state, isLoading: false };
    },
    failureAdminUserAdd: (state, action) => {
      return { ...state, isLoading: false, isError: action?.payload };
    },

    ////////// Admin User View /////////
    requestAdminUserView: (state) => {
      return { ...state, isViewLoading: true };
    },
    responseAdminUserView: (state, action) => {
      return {
        ...state,
        isViewLoading: false,
        viewAdminUserDetails: action.payload,
      };
    },
    failureAdminUserView: (state, action) => {
      return { ...state, isViewLoading: false, isError: action?.payload };
    },

    ////// Admin User Delete ////
    requestAdminUserDelete: (state) => {
      return { ...state, isLoading: true };
    },
    responseAdminUserDelete: (state) => {
      return { ...state, isLoading: false };
    },
    failureAdminUserDelete: (state, action) => {
      return { ...state, isLoading: false, isError: action?.payload };
    },
  },
});

export const {
  failureAdminUserAdd,
  failureAdminUserDelete,
  failureAdminUserList,
  failureAdminUserView,
  requestAdminUserAdd,
  requestAdminUserDelete,
  requestAdminUserList,
  requestAdminUserView,
  responseAdminUserAdd,
  responseAdminUserDelete,
  responseAdminUserList,
  responseAdminUserView,
} = AdminUserSlice.actions;
export default AdminUserSlice.reducer;
