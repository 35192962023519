/* eslint-disable react/prop-types */
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonTable from 'components/commonTable/CommonTable';
import React from 'react';
import { powerBankStatus } from 'utils/StaticData';

const PowerBankListDataTable = ({ tableData, isLoading }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.powerbankId, {
      id: 'powerbankId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Powerbank Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.slot, {
      id: 'slot',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Slot
        </Text>
      ),
      cell: (info) => {
        const slot = info.getValue();
        const slotValue = slot && typeof slot === 'object' ? slot.slot : slot;
        return (
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {slotValue || 'N/A'}
          </Text>
        );
      },
    }),
    columnHelper.accessor((row) => row?.batteryLevel, {
      id: 'batteryLevel',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Battery Level(%)
        </Text>
      ),
      cell: (info) => {
        return (
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {info.getValue() ? `${info.getValue()} %` : 'N/A'}
          </Text>
        );
      },
    }),
    columnHelper.accessor((row) => row?.status, {
      id: 'status',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        const status = info.getValue();
        const statusText = powerBankStatus[status] || 'N/A';
        return (
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {statusText}
          </Text>
        );
      },
    }),
  ];

  return (
    <>
      <Box>
        <CommonTable
          columns={columns}
          data={tableData}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />
      </Box>
    </>
  );
};

export default PowerBankListDataTable;
