import {
  responseUserList,
  requestUserList,
  failureUserList,
  requestUserAdd,
  responseUserAdd,
  failureUserAdd,
  requestUserView,
  responseUserView,
  failureUserView,
  requestUserDelete,
  responseUserDelete,
  failureUserDelete,
} from '../../slices/userSlice/userSlice';
import requestApi from 'utils/Request';
import { USER, USERLIST } from '../../../utils/StaticApiEndpoints';
import Swal from 'sweetalert2';

//////// User List //////////
export const ListUserData = (payload) => async (dispatch) => {
  try {
    dispatch(requestUserList());
    const res = await requestApi.get(USERLIST, { params: payload });
    dispatch(responseUserList(res));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureUserList(error?.message));
  }
};

//////// User Add //////
export const AddUserData = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestUserAdd());
    const res = await requestApi.post(USER, payload);
    dispatch(responseUserAdd(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureUserAdd(error?.message));
  }
};

//////// User Detail /////
export const ViewUserData = (payload) => async (dispatch) => {
  try {
    dispatch(requestUserView());
    const { data } = await requestApi.get(`${USER}/${payload?.id}`, payload);
    dispatch(responseUserView(data));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureUserView(error?.message));
  }
};

/////// User Edit //////
export const EditUserData = (id, payload, callback) => async (dispatch) => {
  try {
    dispatch(requestUserAdd());
    const res = await requestApi.put(`${USER}/${id}`, payload);
    dispatch(responseUserAdd(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureUserAdd(error?.message));
  }
};

/////// User Delete //////
export const DeleteUserData = (id, payload, callback) => async (dispatch) => {
  try {
    dispatch(requestUserDelete());
    const res = await requestApi.delete(`${USER}/${id}`, payload);
    dispatch(responseUserDelete(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureUserDelete(error?.message));
  }
};
