import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
    >
      <h1 style={{ fontSize: '3rem', color: 'primary', fontWeight: 'bold' }}>
        404 - Page Not Found
      </h1>
      <p style={{ fontSize: '1.2rem', color: 'primary' }}>
        The page you are looking for does not exist.
      </p>
      <Button
        type="button"
        fontSize="sm"
        variant="solid"
        fontWeight="500"
        w="150px"
        bg="primary"
        color="white"
        _hover={{ bg: 'primary', opacity: 0.8 }}
        h="50px"
        mt="24px"
        onClick={() => navigate('/auth/sign-in')}
      >
        Go Back
      </Button>
    </Box>
  );
};

export default NotFound;
