/* eslint-disable */
import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import {
  Box,
  Center,
  Flex,
  HStack,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { UpdateProfile } from 'reactRedux/thunks/authThunk/authThunk';
import { useDispatch, useSelector } from 'react-redux';

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  const dispatch = useDispatch();
  const { adminDetails, loginToken, isLoading } = useSelector(
    (state) => state.authSlice,
  );
  let activeColor = useColorModeValue('white', 'white');
  let inactiveColor = useColorModeValue(
    'secondaryGray.600',
    'secondaryGray.600',
  );
  let activeIcon = useColorModeValue('white', 'white');
  let textColor = useColorModeValue('secondaryGray.500', 'white');
  let brandColor = useColorModeValue('white', 'brand.400');

  const { routes } = props;

  /////// Call This Api When my page is reload and token is available then 
  useEffect(() => {
    if(loginToken && !adminDetails){
    dispatch(UpdateProfile());
   }
  }, [])
  

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
   const filteredRoutes = routes?.filter(
     (route) => !route.roles || route.roles.includes(adminDetails?.role),
   );
  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (filteredRoutes) => {
    return filteredRoutes?.map((route, index) => {
      if (route.category) {
        return (
          <React.Fragment key={index}>
            <Text
              fontSize="md"
              color={activeColor}
              fontWeight="medium"
              mx="auto"
              ps="16px"
              pt="18px"
              pb="12px"
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </React.Fragment>
        );
      } else if (!route.hidden) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            <Box>
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
                }
                py="5px"
                ps="10px"
              >
                <Flex w="100%" alignItems="center">
                  {route.icon && (
                    <Box
                      me="18px"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                    >
                      {route.icon}
                    </Box>
                  )}
                  <Text
                    me="auto"
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : textColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase())
                        ? 'medium'
                        : 'normal'
                    }
                  >
                    {route.name}
                  </Text>
                </Flex>
                <Box
                  h="36px"
                  w="4px"
                  bg={
                    activeRoute(route.path.toLowerCase())
                      ? brandColor
                      : 'transparent'
                  }
                  borderRadius="5px"
                />
              </HStack>
            </Box>
          </NavLink>
        );
      }
      return null;
    });
  };
  
  return createLinks(filteredRoutes);
}

export default SidebarLinks;
