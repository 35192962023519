import requestApi from 'utils/Request';
import {
  failureOrderList,
  failureOrderView,
  failureSyncDataOrder,
  requestOrderList,
  requestOrderView,
  requestSyncDataOrder,
  responseOrderList,
  responseOrderView,
  responseSyncDataOrder,
} from '../../slices/orderSlice/orderSlice';
import { ORDERLIST, SYNCDATAORDER } from 'utils/StaticApiEndpoints';
import Swal from 'sweetalert2';
import { ORDER } from 'utils/StaticApiEndpoints';

////// List Order ////
export const ListOrderData = (payload) => async (dispatch) => {
  try {
    dispatch(requestOrderList());
    const res = await requestApi.get(ORDERLIST, { params: payload });
    dispatch(responseOrderList(res));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureOrderList(error?.message));
  }
};

//// Order Detail ////
export const ViewOrderDetailsData = (payload) => async (dispatch) => {
  try {
    dispatch(requestOrderView());
    const { data } = await requestApi.get(`${ORDER}/${payload?.id}`, payload);
    dispatch(responseOrderView(data));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureOrderView(error?.message));
  }
};

/////   Sync Order Data /////
export const SyncDataOrders = (callback) => async (dispatch) => {
  try {
    dispatch(requestSyncDataOrder());
    const response = await requestApi.get(`${SYNCDATAORDER}`);
    dispatch(responseSyncDataOrder(response));
    Swal.fire({
      icon: 'success',
      text: response.message,
    }).then(() => callback());
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureSyncDataOrder(error?.message));
  }
};
