import { createSlice } from '@reduxjs/toolkit';

const PowerbankSlice = createSlice({
  name: 'PowerbankSlice',
  initialState: {
    isLoading: false,
    isViewLoading: false,
    powerBankListData: [],
    isError: null,
    viewPowerbankDetails: null,
  },
  reducers: {
    /////// Powerbank list start //////

    requestPowerbankList: (state) => {
      return { ...state, isLoading: true };
    },
    responsePowerbankList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        powerBankListData: action?.payload?.data,
      };
    },
    failurePowerbankList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
      };
    },

    /////// Powerbank Add-Edit //////
    requestPowerbankAdd: (state) => {
      return { ...state, isLoading: true };
    },
    responsePowerbankAdd: (state, action) => {
      return { ...state, isLoading: false };
    },
    failurePowerbankAdd: (state, action) => {
      return { ...state, isLoading: false, isError: action?.payload };
    },

    ////////// Powerbank View /////////
    requestPowerbankView: (state) => {
      return { ...state, isViewLoading: true };
    },
    responsePowerbankView: (state, action) => {
      return {
        ...state,
        isViewLoading: false,
        viewPowerbankDetails: action.payload,
      };
    },
    failurePowerbankView: (state, action) => {
      return { ...state, isViewLoading: false, isError: action?.payload };
    },

    ////// Powerbank Delete ////
    requestPowerbankDelete: (state) => {
      return { ...state, isLoading: true };
    },
    responsePowerbankDelete: (state, action) => {
      return { ...state, isLoading: false };
    },
    failurePowerbankDelete: (state, action) => {
      return { ...state, isLoading: false, isError: action?.payload };
    },
  },
});

export const {
  failurePowerbankList,
  responsePowerbankList,
  requestPowerbankList,
  failurePowerbankAdd,
  requestPowerbankAdd,
  responsePowerbankAdd,
  failurePowerbankView,
  requestPowerbankView,
  responsePowerbankView,
  failurePowerbankDelete,
  requestPowerbankDelete,
  responsePowerbankDelete,
} = PowerbankSlice.actions;
export default PowerbankSlice.reducer;
