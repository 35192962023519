import React from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import Dropzone from 'views/admin/profile/components/Dropzone';
// Assets
import { MdUpload } from 'react-icons/md';

export default function Upload(props) {
  const { used, total, ...rest } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('secondary', 'white');
  const textColorSecondary = 'gray.400';

  return (
    <Card {...rest} mb="20px" align="center" p="20px">
      <Flex h="100%" direction={{ base: 'column', '2xl': 'row' }}>
        {/* Dropzone Section */}
        <Dropzone
          w={{ base: '100%', '2xl': '268px' }}
          me="36px"
          maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
          minH={{ base: '60%', lg: '50%', '2xl': '100%' }}
          content={
            <Box textAlign="center">
              <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
              <Text fontSize="xl" fontWeight="700" color={brandColor} mt="12px">
                Upload Files
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                color="secondaryGray.500"
                mt="4px"
              >
                PNG, JPG, and GIF files are allowed
              </Text>
            </Box>
          }
        />

        {/* Profile Completion Section */}
        <Flex direction="column" pe="44px" flex="1">
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            textAlign="start"
            fontSize="2xl"
            mt={{ base: '20px', '2xl': '50px' }}
          >
            Complete your profile
          </Text>
          <Text
            color={textColorSecondary}
            fontSize="md"
            my={{ base: '20px', '2xl': '10px' }}
            textAlign="start"
          >
            Stay on the pulse of distributed projects with an online whiteboard
            to plan, coordinate, and discuss.
          </Text>
          <Flex>
            <Button
              w="140px"
              minW="140px"
              mt={{ base: '20px', '2xl': 'auto' }}
              variant="brand"
              fontWeight="500"
            >
              Publish now
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
