import React from 'react';
import { Box, Card, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageLoader from 'components/wholePageLoader/PageLoader';
import { ViewAdminUserData } from 'reactRedux/thunks/adminUserThunk/adminUserThunk';
import { loginUserRole } from 'utils/StaticData';

const AdminUserDetail = () => {
  const { adminUserDetailId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (adminUserDetailId) {
      dispatch(ViewAdminUserData({ id: adminUserDetailId }));
    }
  }, [adminUserDetailId, dispatch]);
  const { isViewLoading, viewAdminUserDetails } = useSelector(
    (state) => state.AdminUserSlice,
  );

  return (
    <>
      {isViewLoading ? (
        <PageLoader />
      ) : (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Flex direction="column" align="center" justify="center">
            {viewAdminUserDetails ? (
              <>
                <Card
                  w="100%"
                  boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                  borderRadius="20px"
                  paddingInline="40px"
                  paddingBlock="40px"
                  overflowX={{ sm: 'scroll', lg: 'hidden' }}
                >
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="10px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontWeight="normal">First Name:</Text>
                      <Text fontWeight="semibold">
                        {viewAdminUserDetails?.firstName}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="10px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontWeight="normal">Last Name:</Text>
                      <Text fontWeight="semibold">
                        {viewAdminUserDetails?.lastName || 'N/A'}
                      </Text>
                    </Flex>
                    {viewAdminUserDetails.email && (
                      <Flex
                        alignItems={{ sm: 'start', md: 'center' }}
                        direction={{ sm: 'column', md: 'row' }}
                        gap="10px"
                        padding="4px 8px"
                        borderLeft={'1px solid #ccc'}
                      >
                        <Text fontWeight="normal">Email:</Text>
                        <Text fontWeight="semibold">
                          {viewAdminUserDetails?.email}
                        </Text>
                      </Flex>
                    )}
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="10px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontWeight="normal">Role:</Text>
                      <Text fontWeight="semibold">
                        {loginUserRole[viewAdminUserDetails?.role] || 'N/A'}
                      </Text>
                    </Flex>
                    {viewAdminUserDetails.role === 3 && (
                      <Flex
                        alignItems={{ sm: 'start', md: 'center' }}
                        direction={{ sm: 'column', md: 'row' }}
                        gap="10px"
                        padding="4px 8px"
                        borderLeft={'1px solid #ccc'}
                      >
                        <Text fontWeight="normal">Merchant Name:</Text>
                        <Text fontWeight="semibold">
                          {viewAdminUserDetails?.merchantName || 'N/A'}
                        </Text>
                      </Flex>
                    )}
                  </SimpleGrid>
                </Card>
              </>
            ) : (
              <Text>No admin user details found.</Text>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default AdminUserDetail;
