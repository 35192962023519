import React from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import CommonTable from 'components/commonTable/CommonTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListMerchantData } from 'reactRedux/thunks/merchnatThunk/merchantThunk';
import PropTypes from 'prop-types';

const MerchantListModal = ({
  isOpen,
  onClose,
  setSelectedMerchants,
  isRemove,
  setIsRemove,
  setSelectedMerchantName,
  setValue,
}) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [page, setPage] = useState(1);
  const [selectedMerchantId, setSelectedMerchantId] = useState('');
  const dispatch = useDispatch();
  const { isLoading, merchantListData } = useSelector(
    (state) => state.MerchantSlice,
  );

  useEffect(() => {
    dispatch(ListMerchantData({ page: page, limit: 10 }));
  }, [page, dispatch]);

  const handleRadioChange = (id) => {
    setSelectedMerchantId(id);
    const selectedMerchant = merchantListData?.list.find(
      (merchant) => merchant.merchantId === id,
    );
    // Set the selected merchant's name
    if (selectedMerchant) {
      setSelectedMerchantName(selectedMerchant.name);
    }
  };

  useEffect(() => {
    if (isRemove) {
      setSelectedMerchantId('');
      setSelectedMerchantName('');
      setIsRemove(false);
    }
  }, [isRemove, setIsRemove, setSelectedMerchantName]);

  const handleSelectMerchant = () => {
    // setSelectedMerchants(selectedMerchantId);
    // onClose();
    setSelectedMerchants(selectedMerchantId);
    // setSelectedMerchantName(merchantName);
    setValue('merchantId', selectedMerchantId);
    onClose();
  };

  const onModalCloseIcon = () => {
    setSelectedMerchantId('');
    setIsRemove(false);
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.merchantId, {
      id: 'selectMerchant',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="60px"
          whiteSpace="nowrap"
        >
          Select
        </Text>
      ),
      cell: (info) => (
        <Radio
          value={info.getValue()}
          onChange={() => handleRadioChange(info.getValue())}
          isChecked={selectedMerchantId === info.getValue()}
        />
      ),
    }),
    columnHelper.accessor((row) => row?.name, {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Merchant
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          {/* <Radio
            value={info.getValue()}
            onChange={() => handleRadioChange(info.getValue())}
            isChecked={selectedMerchantId === info.getValue()}
          /> */}
          <Text color={textColor} fontSize="sm" fontWeight="600" ml={2}>
            {info.getValue() ? info.getValue() : 'N/A'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.locationId, {
      id: 'locationId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Location Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.address, {
      id: 'address',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="80px"
          whiteSpace="nowrap"
        >
          Address
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
  ];

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Merchant List</ModalHeader>
          <ModalCloseButton onClick={() => onModalCloseIcon()} />
          <ModalBody>
            <CommonTable
              columns={columns}
              data={merchantListData?.list}
              isLoading={isLoading}
              keyValue="id"
              rowclick={['actions', 'status']}
              excludes={['actions', 'status']}
            />
            {merchantListData?.total > 10 && (
              <CommonPagination
                totalCount={merchantListData?.total}
                activePage={page}
                pageCount={Math.ceil(
                  merchantListData?.total / merchantListData?.limit,
                )}
                onPageChange={(e) => setPage(e)}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={handleSelectMerchant}
              isDisabled={!selectedMerchantId}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

MerchantListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setSelectedMerchants: PropTypes.func.isRequired,
  isRemove: PropTypes.bool.isRequired,
  setIsRemove: PropTypes.func.isRequired,
  setSelectedMerchantName: PropTypes.func.isRequired,
  setValue: PropTypes.func,
};

export default MerchantListModal;
