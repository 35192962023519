import React from 'react'
import { Button, Spinner } from "@chakra-ui/react";

const CommonSubmitBtn = ({ BTNtext, isLoading }) => {
  return (
    <>
      <Button
        type="submit"
        fontSize='sm'
        variant='brand'
        fontWeight='500'
        w='100%'
        bg="primary"
        color="white"
        _hover={{ bg: "primary", opacity: 0.8 }}
        h='50'
        mt='24px'
        isLoading={isLoading}
        loadingText="Submitting"
      >
        {isLoading ? <Spinner size="sm" /> : BTNtext}
      </Button>
    </>
  )
}

export default CommonSubmitBtn