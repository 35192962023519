import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import illustration from 'assets/img/auth/auth.png';
import DefaultAuth from 'layouts/auth/Default';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordValidationSchema } from 'utils/CommonValidation';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ResetPasswordAuth } from 'reactRedux/thunks/authThunk/authThunk';
import SuccessModal from 'components/popUpModel/SuccessModal';

const ResetPassword = () => {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('secondary', 'brand.400');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { isLoading } = useSelector((state) => state.authSlice);
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(resetPasswordValidationSchema),
  });

  const onSubmit = (data) => {
    const payload = {
      code: code,
      password: data.newPassword,
    };

    dispatch(
      ResetPasswordAuth(payload, () => {
        setIsSuccessModalOpen(true);
        reset();
      }),
    );
  };

  const handleSuccessModalConfirm = () => {
    setIsSuccessModalOpen(false);
    navigate('/auth/sign-in');
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false);
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading
            color={textColor}
            fontSize="36px"
            mb="10px"
            fontFamily="Montserrat"
          >
            Reset Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your new password below.
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormControl>
              {/* New Password Field */}
              <Box mb="24px">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  New Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Enter new password"
                    mb="2px"
                    size="lg"
                    type={showPassword ? 'text' : 'password'}
                    variant="auth"
                    {...register('newPassword')}
                    name="newPassword"
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors?.newPassword && (
                  <p style={{ color: 'red', fontSize: '14px' }}>
                    {errors?.newPassword?.message}
                  </p>
                )}
              </Box>

              {/* Confirm Password Field */}
              <Box mb="24px">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Confirm Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Confirm new password"
                    mb="2px"
                    size="lg"
                    type={showConfirmPassword ? 'text' : 'password'}
                    variant="auth"
                    {...register('confirmPassword')}
                    name="confirmPassword"
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={
                        showConfirmPassword
                          ? RiEyeCloseLine
                          : MdOutlineRemoveRedEye
                      }
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  </InputRightElement>
                </InputGroup>
                {errors?.confirmPassword && (
                  <p style={{ color: 'red', fontSize: '14px' }}>
                    {errors?.confirmPassword?.message}
                  </p>
                )}
              </Box>

              {/* Submit Button */}
              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                bg="primary"
                color="white"
                _hover={{ bg: 'primary', opacity: 0.8 }}
                w="100%"
                h="50"
                mb="24px"
              >
                {isLoading ? <Spinner size="sm" /> : 'Reset Password'}
              </Button>
              <Box textAlign="center">
                <Link to="/auth/sign-in">
                  <Text
                    fontSize="sm"
                    color="blue.500"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    Back to Sign In
                  </Text>
                </Link>
              </Box>
            </FormControl>
          </form>
        </Flex>

        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleSuccessModalClose}
          title="Your password has been reset successfully. You can now sign in with your new password."
          onConfirm={handleSuccessModalConfirm}
          SolidBTNText="Ok"
        />
      </Flex>
    </DefaultAuth>
  );
};

export default ResetPassword;
