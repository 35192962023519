import {
  Box,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Text
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import CommonInput from "components/commonInput/CommonInput";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../assets/css/PhoneInputCustom.css';
import { useColorModeVariables } from "../../../components/colorModeVarialbels/ColorModeValues";
import CommonSubmitBtn from "../../../components/commonInput/CommonSubmitBtn";
import { AddUserData, EditUserData, ViewUserData } from "../../../reactRedux/thunks/userThunk/userThunk";
import { userAddEditValidation } from "../../../utils/CommonValidation";

const UserEdit = () => {
  const { editUserId } = useParams();
  const { textColor, brandStars } = useColorModeVariables();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isViewLoading, viewUserDetails, isLoading } = useSelector((state) => state.UserSlice);
  const { handleSubmit, reset, setValue, register, formState: { errors } } = useForm({ mode: "all", resolver: yupResolver(userAddEditValidation) })

  const onSubmit = (data) => {
    const { country_code, ...rest } = data;
    if (editUserId) {
      dispatch(
        EditUserData(editUserId, rest, () => {
          navigate('/admin/users');
        }),
      );
    } else {
      dispatch(
        AddUserData(rest, () => {
          navigate('/admin/users');
        }),
      );
    }
  };

  useEffect(() => {
    if (editUserId) {
      dispatch(ViewUserData({ id: editUserId }));
    }
  }, [editUserId]);

  useEffect(() => {
    if (viewUserDetails && editUserId) {
      setValue('firstName', viewUserDetails?.firstName);
      setValue('lastName', viewUserDetails?.lastName);
      // const countryCode = "+1"; // or retrieve it from viewUser Details if it's dynamic
      // const fullPhoneNumber = `${countryCode}${viewUserDetails?.phone};`;
      setValue('country_code', '+91');
      setValue('phone', viewUserDetails?.phone);
    }
  }, [viewUserDetails, editUserId, setValue]);


  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} >
        <Flex direction="column" align="center" justify="center">
          <Card
            w="100%"
            boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
            borderRadius="20px"
            paddingInline="40px"
            paddingBlock="50px"
            overflowX={{ sm: 'scroll', lg: 'hidden' }}
          >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl>
                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 3 }} // 1 column for mobile, 2 for tablets, 3 for desktop
                  spacing="24px"
                >
                  {/* First Name */}
                  <CommonInput
                    type = "text"
                    name="firstName"
                    labelText="First Name"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="First Name"
                    register={register("firstName")}
                    errors={errors?.firstName}
                  />

                  {/* Last Name */}
                  <CommonInput
                    type="text"
                    name="lastName"
                    labelText="Last Name"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Last Name"
                    register={register("lastName")}
                    errors={errors?.lastName}
                  />

                  {/* Phone Number */}
                  <Box>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Phone Number<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      isRequired
                      variant="auth"
                      fontSize="sm"
                      placeholder="Phone Number"
                      fontWeight="500"
                      size="lg"
                      {...register('phone')}
                      name="phone"
                    />
                    {errors?.phone && (
                      <p style={{ color: 'red', fontSize: '14px' }}>
                        {errors?.phone?.message}
                      </p>
                    )}
                  </Box>

                <CommonSubmitBtn BTNtext="Submit" isLoading={isLoading}/>
                </SimpleGrid>
              </FormControl>
            </form>
          </Card>
        </Flex>
      </Box>
    </>
  );
};

export default UserEdit;
