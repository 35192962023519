import authSlice from './authSlice/authSlice';
import UserSlice from './userSlice/userSlice';
import MerchantSlice from './merchantSlice/merchantSlice';
import StationSlice from './stationSlice/stationSlice';
import PowerBankSlice from './powerBankSlice/powerBankSlice';
import OrderSlice from './orderSlice/orderSlice';
import AdminUserSlice from './adminUserSlice/adminUserSlice';
import DashboardSlice from './dashboardSlice/dashboardSlice';

export const rootReducer = {
  authSlice,
  UserSlice,
  MerchantSlice,
  StationSlice,
  PowerBankSlice,
  OrderSlice,
  AdminUserSlice,
  DashboardSlice,
};
