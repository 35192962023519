export const LOGIN = '/admin/login';
export const DASHBOARD_STATUS = '/dashboard/status';
export const DASHBOARD_REVENUE = '/dashboard/earnings';
export const DASHBOARD_REVENUE_OVERVIEW = '/dashboard/grossRevenue';
export const DASHBOARD_ERROR_OVERVIEW = '/dashboard/errorsOverview';
export const DASHBOARD_RECURRING_USERS = '/dashboard/recurringUsers';
export const FORGOT_PASSWORD = '/admin/forgot-password';
export const RESET_PASSWORD = '/admin/reset-password';
export const EDIT_ADMIN_PROFILE = '/admin/profile/update';
export const UPDATEPROFILE = '/user/userInfo';
export const EDIT_PROFILE = '/user/edit-profile';
export const ADMINUSERLIST = '/admin/list';
export const ADMINUSERDELETE = '/admin/delete';
export const ADMINADDUSER = '/admin/create';
export const ADMINUSEREDIT = '/admin/update';
export const ADMINDETAILUSER = '/admin';
export const USERLIST = '/user/list';
export const USER = '/user';
export const MERCHANTLIST = '/merchant/list';
export const MERCHANTNAMELIST = '/merchant/nameList';
export const MERCHANT = '/merchant';
export const STATIONLIST = '/station/list';
export const STATION = '/station';
export const POWERBANKLIST = '/powerbank/list';
export const POWERBANK = '/powerbank';
export const ORDERLIST = '/order/list';
export const ORDER = '/order';
export const SYNCDATAORDER = '/sync-data/orders';
export const SYNCDATAMERCHANT = '/sync-data/merchant';
