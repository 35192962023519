import { Box, Card, Flex, FormControl, SimpleGrid } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import CommonInput from "components/commonInput/CommonInput";
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AddPowerBankData, EditPowerBankData, ViewPowerBankData } from "reactRedux/thunks/powerBankThunk/powerBankThunk";
import { useColorModeVariables } from '../../../components/colorModeVarialbels/ColorModeValues';
import CommonSubmitBtn from "../../../components/commonInput/CommonSubmitBtn";
import { powerbankIdAddEditValidation } from "../../../utils/CommonValidation";
import GoogleLatLng from "../stationPages/GoogleLatLng";

const PowerBankAddEdit = () => {
  const { editpowerbanksId } = useParams();
  const { textColor, brandStars } = useColorModeVariables()
  const [coordinates, setCoordinates] = useState({ lat: 23.305936, lng: 72.509766 })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [initialAddress, setInitialAddress] = useState('')
  const { isLoading, viewPowerbankDetails } = useSelector((state) => state.PowerBankSlice);
  const { handleSubmit, reset, setValue, register, formState: { errors }, control } = useForm({ mode: "onChange", resolver: yupResolver(powerbankIdAddEditValidation) })

  const onSubmit = (data) => {
    const { powerbankId, ...restData } = data;
    const transformedData = {
      ...restData,
      coordinates: [coordinates.lng, coordinates.lat],
    };
    if (editpowerbanksId) {
      dispatch(
        EditPowerBankData(editpowerbanksId, transformedData, () => {
          navigate('/admin/powerbanks');
        }),
      );
    } else {
      dispatch(
        AddPowerBankData({ ...transformedData, powerbankId }, () => {
          navigate('/admin/powerbanks');
        }),
      );
    }
  };

  useEffect(() => {
    if (editpowerbanksId) {
      dispatch(ViewPowerBankData({ id: editpowerbanksId }));
    }
  }, [editpowerbanksId]);

  const fetchAddress = async (coordinates) => {
    try {
      const response = await fetch(
        `https://cors.stage.eugeniuses.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates[1]},${coordinates[0]}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setInitialAddress(data.results[0].formatted_address);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  useEffect(() => {
    if (viewPowerbankDetails && editpowerbanksId) {
      setValue('powerbankId', viewPowerbankDetails?.powerbankId);
      setValue('terminalId', viewPowerbankDetails?.terminalId);
      if (
        viewPowerbankDetails.coordinates &&
        viewPowerbankDetails.coordinates.length === 2
      ) {
        setCoordinates({
          lat: viewPowerbankDetails.coordinates[1],
          lng: viewPowerbankDetails.coordinates[0],
        });
        fetchAddress(viewPowerbankDetails.coordinates);
      }
    }
  }, [viewPowerbankDetails, editpowerbanksId, setValue]);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column" align="center" justify="center">
          <Card
            w="100%"
            boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
            borderRadius="20px"
            paddingInline="40px"
            paddingBlock="50px"
            overflowX={{ sm: 'scroll', lg: 'hidden' }}
          >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="24px">
                  {/* station ID */}
                  <CommonInput
                    type="text"
                    name="powerbankId"
                    labelText="Powerbank Id"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Powerbank Id"
                    register={register("powerbankId")}
                    errors={errors?.powerbankId}
                  />

                  {/* terminal ID */}
                  <CommonInput
                    type="text"
                    name="terminalId"
                    labelText="Terminal Id"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Terminal Id"
                    register={register("terminalId")}
                    errors={errors?.terminalId}
                  />
                  <GoogleLatLng coordinates={coordinates} setCoordinates={setCoordinates} initialAddress={initialAddress} />
                </SimpleGrid>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="24px">
                  <CommonSubmitBtn BTNtext="Submit" isLoading={isLoading} />
                </SimpleGrid>
              </FormControl>
            </form>
          </Card>
        </Flex>
      </Box>
    </>
  );
};

export default PowerBankAddEdit;
