import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const CommonPagination = ({ onPageChange, pageCount, activePage }) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= pageCount) {
      onPageChange(page);
    }
  };

  return (
    <Flex alignItems="center" justifyContent="right" mt={4} gap={2}>
      <Button
        onClick={() => handlePageChange(activePage - 1)}
        isDisabled={activePage === 1}
        aria-label="Previous Page"
        size="md"
        px={6}
        py={2}
        variant="outline"
      >
        Previous
      </Button>
      <Button
        onClick={() => handlePageChange(activePage + 1)}
        isDisabled={activePage === pageCount}
        aria-label="Next Page"
        size="md"
        px={6}
        py={2}
        variant="outline"
      >
        Next
      </Button>
    </Flex>
  );
};

CommonPagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
};

export default CommonPagination;
