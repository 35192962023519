import { Box, Button, Flex, Icon, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import CommonPagination from 'components/commonPagination/CommonPagination'
import CommonTable from 'components/commonTable/CommonTable'
import { useEffect, useRef, useState } from 'react'
import { FaPlus } from "react-icons/fa"
import { PiEye, PiPencil, PiTrash } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import DeleteModal from '../../../components/deleteModalPopUp/DeleteModal'
import { DeleteUserData, ListUserData } from '../../../reactRedux/thunks/userThunk/userThunk'
import { useColorModeVariables } from '../../../components/colorModeVarialbels/ColorModeValues'
import CommonSearch from 'components/commonSearchBar/CommonSearch'
const UserList = () => {
  const textColor = useColorModeValue('primary', 'white'); 
  const { tabletextColor } = useColorModeVariables()
  const [page, setPage] = useState(1)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userDeleteRef = useRef(null);
  const navigate = useNavigate();
  const { isLoading, userListData } = useSelector((state) => state.UserSlice);
  const [search,setSearch] = useState("")
  const dispatch = useDispatch()

  const getUserList = () => {
    dispatch(ListUserData({ page: page, limit: 10 , search:search}))
  }

  useEffect(() => {
    getUserList()
  }, [page,search]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.firstName, {
      id: 'firstName',
      header: () => (
        <Text
          justifyContent="space-between"
          whiteSpace="nowrap"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="80px"
          fontFamily="Montserrat"
        >
          First Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={tabletextColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.lastName, {
      id: 'lastName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="80px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Last Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={tabletextColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.phone, {
      id: 'phone',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="125px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Contact Number
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={tabletextColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.actions, {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="50px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          ACTION
        </Text>
      ),
      cell: (row) => (
        <Flex align="center">
          <Link
            to={`/admin/users/edit/${row?.row?.original?.userId}`}
            className="btn btn-light rounded-3 p-1"
          >
            <PiPencil size={20} title="Edit" style={{ marginRight: '20px' }} />
          </Link>
          <Link
            to={`/admin/users/details/${row?.row?.original?.userId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <PiEye
              size={20}
              title="View"
              className="text-white"
              style={{ marginRight: '20px' }}
            />
          </Link>
          <div className="btn btn-danger rounded-3 p-1">
            <PiTrash
              size={20}
              className="text-white"
              onClick={() => {
                // setShow(true);
                onOpen();
                userDeleteRef.current = row?.row?.original?.userId;
              }}
              title="Delete"
            />
          </div>
        </Flex>
      ),
    }),
  ];

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex justify="space-between" mb={4}>
          <Text fontSize="2xl" fontWeight="medium">Users  List</Text>
          <CommonSearch search={search} setSearch={setSearch} setPage={setPage}/>
          <Button bg="reviveOrange" color="primary" borderRadius='12px' _hover={{ bg: "reviveOrange", opacity: 0.8 }} onClick={() => navigate('/admin/users/add')}>
            <Icon as={FaPlus} width="15px" height="15px" marginRight='5px' color="inherit" />
            Add User
          </Button>
        </Flex>
        <CommonTable
          columns={columns}
          data={userListData?.list}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />

        {userListData?.list?.length > 10 && (
          <CommonPagination
            totalCount={userListData?.total}
            activePage={page}
            pageCount={Math.ceil(userListData?.total / userListData?.limit)}
            onPageChange={(e) => setPage(e)}
          />
        )}

        <DeleteModal
          isOpen={isOpen}
          onClose={onClose}
          title="Are you sure you want to delete this user?"
          SolidBTNText="Delete"
          onDelete={() => {
            dispatch(DeleteUserData(userDeleteRef.current, {}, () => {
              if(page > 1 && userListData?.list?.length === 1){
                setPage(page - 1)
              } else {
                getUserList()
              }
              onClose()
            }))
          }}
          loader={isLoading}
        />
      </Box>
    </>
  );
};

export default UserList;
