import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Flex, Text } from '@chakra-ui/react';
import { formatCurrency } from 'utils/CommonFunction';
import PropTypes from 'prop-types';
const NetRevenueGraph = ({
  intervalValue,
  revenueGraphData,
  currency,
  dashboardData,
}) => {
  return (
    <>
      <Box
        p="5"
        bg="white"
        boxShadow="0px 0px 40px rgba(32, 57, 70, 0.06)"
        borderRadius="20px"
        w="full"
      >
        {/* Title & Amount */}
        <Text
          fontSize={{ base: '20px', md: '24px' }}
          fontWeight="semibold"
          mb="16px"
        >
          Net Revenue
        </Text>
        <Text fontSize="32px" color="primary" fontWeight="medium" mb="24px">
          {formatCurrency(revenueGraphData.totalRevenue, currency)}
        </Text>
        {/* Chart Container */}
        <ResponsiveContainer width="100%" height={230}>
          <LineChart
            data={revenueGraphData?.revenueData}
            margin={{ top: 20 }}
            accessibilityLayer
          >
            <XAxis
              dataKey="time"
              scale="point"
              interval={intervalValue}
              padding={{ left: 10, right: 10 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => formatCurrency(value, currency)}
            />
            <Tooltip
              formatter={(value) => formatCurrency(value, currency)}
              labelFormatter={(label) => `${label}`}
            />
            <CartesianGrid
              strokeDasharray="3 3"
              horizontal={true}
              vertical={false}
            />
            <Line
              type="monotone"
              dataKey="total"
              stroke="#53EB95"
              strokeWidth={2}
              dot={false}
              activeDot={{ r: 8 }}
            ></Line>
          </LineChart>
        </ResponsiveContainer>
      </Box>

      <Box
        w="full"
        maxWidth={{ base: '100%', xl: '486px' }}
        display="flex"
        flexWrap="wrap"
        gap={{ base: '12px', md: '16px', lg: '24px', xl: '32px' }}
      >
        {/* Stations Online */}
        <Flex
          w={{
            base: 'calc(33.33% - 8px)',
            md: 'calc(33.33% - 11px)',
            lg: 'calc(33.33% - 17px)',
            xl: '100%',
          }}
          p={{ base: '12px', md: '4' }}
          position="relative"
          gap={{ base: '15px', md: '24px' }}
          align={{ base: 'baseline', xl: 'center' }}
          flexDirection={{ base: 'column', xl: 'row' }}
          borderRadius="20px"
          border="1px solid"
          borderColor="reviveOrange"
          bg="linear-gradient(0deg, rgba(251, 176, 59, 0.1), rgba(251, 176, 59, 0.1)), #FFFFFF"
          boxShadow="0px 0px 40px rgba(32, 57, 70, 0.06)"
          overflow="hidden"
          _after={{
            content: '""',
            position: 'absolute',
            width: { base: '150px', md: '234px' },
            height: { base: '150px', md: '234px' },
            right: { base: '0px', md: '30px' },
            bottom: { base: '55px', md: '0' },
            background:
              'linear-gradient(107.66deg, #FBB03B 12.13%, rgba(251, 176, 59, 0) 87.41%)',
            opacity: '0.18',
            transform: 'matrix(-1, 0, 0, 1, 0, 0)',
            borderRadius: '50%',
            display: { base: 'block', lg: 'none', xl: 'block' },
          }}
        >
          <Flex
            bg="reviveOrange"
            borderRadius={{ base: '4px', md: '10px' }}
            height={{ base: '30px', md: '50px', xl: '73px' }}
            width={{ base: '30px', md: '50px', xl: '73px' }}
            align="center"
            justify="center"
          >
            <Box
              as="svg"
              width={{ base: '15px', md: '37px' }}
              height={{ base: '15px', md: '37px' }}
              viewBox="0 0 37 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="36"
                height="36"
                transform="translate(0.5 0.167969)"
                fill="#FBB03B"
              />
              <path
                d="M23 4.66797C22.9997 3.92214 22.7033 3.20694 22.1759 2.67956C21.6485 2.15218 20.9333 1.85577 20.1875 1.85547H6.6875C5.94167 1.85577 5.22647 2.15218 4.69909 2.67956C4.17171 3.20694 3.8753 3.92214 3.875 4.66797V30.543H23V4.66797ZM12.3125 28.293L13.2125 23.793H9.5L14.5625 17.043L13.6625 21.543H17.375L12.3125 28.293ZM19.625 14.2305H7.25V5.23047H19.625V14.2305ZM24.6875 32.793V34.4805H2.1875V32.793C2.1875 32.4946 2.30603 32.2084 2.517 31.9975C2.72798 31.7865 3.01413 31.668 3.3125 31.668H23.5625C23.8609 31.668 24.147 31.7865 24.358 31.9975C24.569 32.2084 24.6875 32.4946 24.6875 32.793ZM34.8125 5.79297V9.16797C34.8125 9.91389 34.5162 10.6293 33.9887 11.1567C33.4613 11.6842 32.7459 11.9805 32 11.9805H30.875C30.1291 11.9805 29.4137 11.6842 28.8863 11.1567C28.3588 10.6293 28.0625 9.91389 28.0625 9.16797V5.79297H34.8125ZM29.75 4.66797H28.625V1.85547C28.625 1.70628 28.6843 1.56321 28.7897 1.45772C28.8952 1.35223 29.0383 1.29297 29.1875 1.29297C29.3367 1.29297 29.4798 1.35223 29.5852 1.45772C29.6907 1.56321 29.75 1.70628 29.75 1.85547V4.66797ZM34.25 4.66797H33.125V1.85547C33.125 1.70628 33.1843 1.56321 33.2897 1.45772C33.3952 1.35223 33.5383 1.29297 33.6875 1.29297C33.8367 1.29297 33.9798 1.35223 34.0852 1.45772C34.1907 1.56321 34.25 1.70628 34.25 1.85547V4.66797ZM30.875 13.1055H32V26.6055C32 27.3514 31.7037 28.0668 31.1762 28.5942C30.6488 29.1216 29.9334 29.418 29.1875 29.418C28.4416 29.418 27.7262 29.1216 27.1988 28.5942C26.6713 28.0668 26.375 27.3514 26.375 26.6055V18.7305C26.3737 18.2833 26.1954 17.8549 25.8793 17.5387C25.5631 17.2225 25.1346 17.0443 24.6875 17.043H24.125V15.918H24.6875C25.4333 15.9183 26.1485 16.2147 26.6759 16.7421C27.2033 17.2694 27.4997 17.9846 27.5 18.7305V26.6055C27.5 27.053 27.6778 27.4822 27.9943 27.7987C28.3107 28.1152 28.7399 28.293 29.1875 28.293C29.635 28.293 30.0643 28.1152 30.3807 27.7987C30.6972 27.4822 30.875 27.053 30.875 26.6055V13.1055Z"
                fill="#203946"
              />
            </Box>
          </Flex>
          <Box>
            <Text
              fontSize={{ base: '24px', md: '36px', xl: '40px' }}
              lineHeight="1.2"
              fontWeight="semibold"
              color="primary"
            >
              {dashboardData.onlineStation} / {dashboardData.totalStations}
            </Text>
            <Text fontSize={{ base: '9px', md: '16px' }} color="#292D32">
              Stations Online
            </Text>
          </Box>
        </Flex>
        {/* Powerbanks Used */}
        <Flex
          w={{
            base: 'calc(33.33% - 8px)',
            md: 'calc(33.33% - 11px)',
            lg: 'calc(33.33% - 17px)',
            xl: '100%',
          }}
          p={{ base: '12px', md: '4' }}
          position="relative"
          gap={{ base: '15px', md: '24px' }}
          align={{ base: 'baseline', xl: 'center' }}
          flexDirection={{ base: 'column', xl: 'row' }}
          borderRadius="20px"
          border="1px solid"
          borderColor="#53EB95"
          bg="linear-gradient(0deg, rgba(83, 235, 149, 0.1), rgba(83, 235, 149, 0.1)), #FFFFFF"
          boxShadow="0px 0px 40px rgba(32, 57, 70, 0.06)"
          overflow="hidden"
          _after={{
            content: '""',
            position: 'absolute',
            width: { base: '150px', md: '234px' },
            height: { base: '150px', md: '234px' },
            right: { base: '0px', md: '30px' },
            bottom: { base: '55px', md: '0' },
            background:
              'linear-gradient(107.66deg, #53EB95 12.13%, rgba(83, 235, 149, 0) 87.41%)',
            opacity: '0.18',
            transform: 'matrix(-1, 0, 0, 1, 0, 0)',
            borderRadius: '50%',
            display: { base: 'block', lg: 'none', xl: 'block' },
          }}
        >
          <Flex
            bg="#53EB95"
            borderRadius={{ base: '4px', md: '10px' }}
            height={{ base: '30px', md: '50px', xl: '73px' }}
            width={{ base: '30px', md: '50px', xl: '73px' }}
            align="center"
            justify="center"
          >
            <Box
              as="svg"
              width={{ base: '15px', md: '37px' }}
              height={{ base: '15px', md: '37px' }}
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M34.3531 23.2172V25.7203C34.3531 26.5148 33.7625 27.1758 32.9961 27.2953V29.5453C32.9961 29.9531 32.6656 30.2766 32.2648 30.2766H29.5578C29.157 30.2766 28.8266 29.9531 28.8266 29.5453V27.2953C28.0602 27.1828 27.4695 26.5219 27.4695 25.7203V23.2172C27.4695 21.5719 28.6297 20.1938 30.1766 19.8562V5.00625C30.1766 3.05859 28.5875 1.46953 26.6398 1.46953H16.5922C15.0734 1.46953 13.7797 2.42578 13.2734 3.77578H11.7406C12.2891 1.61016 14.2578 0 16.5922 0H26.6398C29.3961 0 31.6461 2.24297 31.6461 5.00625V19.8562C33.193 20.1938 34.3531 21.5719 34.3531 23.2172ZM11.9727 20.5453L12.5352 17.7117L9.30078 22.4859H11.5367C11.9937 22.4859 12.3383 22.9008 12.2609 23.3508L11.7336 26.3602L15.2984 21.4242H12.6969C12.2328 21.4242 11.8812 21.0023 11.9727 20.5453Z"
                fill="#203946"
              />
              <path
                d="M21.007 7.68594H15.6141V7.40469C15.6141 6.21641 14.6508 5.24609 13.4555 5.24609H11.1914C10.0031 5.24609 9.03281 6.21641 9.03281 7.40469V7.68594H3.63281C1.9875 7.68594 0.644531 9.02891 0.644531 10.6742V33.0195C0.644531 34.6648 1.9875 36.0078 3.63281 36.0078H21.007C22.6523 36.0078 23.9953 34.6648 23.9953 33.0195V10.6672C23.9953 9.02187 22.6523 7.68594 21.007 7.68594ZM17.3227 21.1156L11.0437 29.8133C10.8469 30.0875 10.4742 30.193 10.1648 30.0594C9.84844 29.9258 9.66562 29.5953 9.72891 29.2578L10.6641 23.9562H7.91484C7.64062 23.9562 7.39453 23.8086 7.26797 23.5625C7.14141 23.3164 7.15547 23.0281 7.31016 22.8031L13.3641 13.8805C13.5609 13.5922 13.9336 13.4797 14.257 13.6133C14.5805 13.7469 14.7633 14.0914 14.693 14.4359L13.5961 19.9484H16.725C16.9992 19.9484 17.2523 20.1031 17.3789 20.3492C17.5055 20.6023 17.4844 20.8977 17.3227 21.1156Z"
                fill="#203946"
              />
            </Box>
          </Flex>
          <Box>
            <Text
              fontSize={{ base: '24px', md: '36px', xl: '40px' }}
              lineHeight="1.2"
              fontWeight="semibold"
              color="primary"
            >
              {dashboardData.powerbankOnRent}
            </Text>
            <Text fontSize={{ base: '9px', md: '16px' }} color="#292D32">
              Powerbanks Used
            </Text>
          </Box>
        </Flex>
        {/* Errors Captured */}
        <Flex
          w={{
            base: 'calc(33.33% - 8px)',
            md: 'calc(33.33% - 11px)',
            lg: 'calc(33.33% - 17px)',
            xl: '100%',
          }}
          p={{ base: '12px', md: '4' }}
          position="relative"
          gap={{ base: '15px', md: '24px' }}
          align={{ base: 'baseline', xl: 'center' }}
          flexDirection={{ base: 'column', xl: 'row' }}
          borderRadius="20px"
          border="1px solid"
          borderColor="#FD7654"
          bg="linear-gradient(0deg, rgba(253, 118, 84, 0.1), rgba(253, 118, 84, 0.1)), #FFFFFF"
          boxShadow="0px 0px 40px rgba(32, 57, 70, 0.06)"
          overflow="hidden"
          _after={{
            content: '""',
            position: 'absolute',
            width: { base: '150px', md: '234px' },
            height: { base: '150px', md: '234px' },
            right: { base: '0px', md: '30px' },
            bottom: { base: '55px', md: '0' },
            background:
              'linear-gradient(107.66deg, #FD7654 12.13%, rgba(253, 118, 84, 0) 87.41%)',
            opacity: '0.18',
            transform: 'matrix(-1, 0, 0, 1, 0, 0)',
            borderRadius: '50%',
            display: { base: 'block', lg: 'none', xl: 'block' },
          }}
        >
          <Flex
            bg="#FD7654"
            borderRadius={{ base: '4px', md: '10px' }}
            height={{ base: '30px', md: '50px', xl: '73px' }}
            width={{ base: '30px', md: '50px', xl: '73px' }}
            align="center"
            justify="center"
          >
            <Box
              as="svg"
              width={{ base: '15px', md: '37px' }}
              height={{ base: '15px', md: '37px' }}
              viewBox="0 0 37 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.8015 27.2862L21.5039 5.92947C21.1965 5.40624 20.7578 4.9724 20.2312 4.67096C19.7046 4.36952 19.1083 4.21094 18.5015 4.21094C17.8947 4.21094 17.2984 4.36952 16.7718 4.67096C16.2452 4.9724 15.8065 5.40624 15.4992 5.92947L3.20151 27.2862C2.90582 27.7923 2.75 28.3679 2.75 28.954C2.75 29.5401 2.90582 30.1157 3.20151 30.6218C3.50488 31.1482 3.94284 31.5844 4.47045 31.8857C4.99807 32.1869 5.59633 32.3423 6.20385 32.336H30.7992C31.4062 32.3418 32.0039 32.1862 32.531 31.8849C33.058 31.5837 33.4956 31.1478 33.7987 30.6218C34.0948 30.116 34.2511 29.5405 34.2516 28.9544C34.2521 28.3682 34.0968 27.7925 33.8015 27.2862ZM17.3765 15.461C17.3765 15.1627 17.495 14.8765 17.706 14.6655C17.917 14.4546 18.2031 14.336 18.5015 14.336C18.7999 14.336 19.086 14.4546 19.297 14.6655C19.508 14.8765 19.6265 15.1627 19.6265 15.461V21.086C19.6265 21.3844 19.508 21.6705 19.297 21.8815C19.086 22.0925 18.7999 22.211 18.5015 22.211C18.2031 22.211 17.917 22.0925 17.706 21.8815C17.495 21.6705 17.3765 21.3844 17.3765 21.086V15.461ZM18.5015 27.836C18.1678 27.836 17.8415 27.7371 17.564 27.5516C17.2865 27.3662 17.0702 27.1027 16.9425 26.7943C16.8147 26.486 16.7813 26.1467 16.8464 25.8193C16.9115 25.492 17.0723 25.1913 17.3083 24.9553C17.5443 24.7193 17.8449 24.5586 18.1723 24.4935C18.4996 24.4283 18.8389 24.4618 19.1473 24.5895C19.4556 24.7172 19.7192 24.9335 19.9046 25.211C20.09 25.4885 20.189 25.8148 20.189 26.1485C20.189 26.5961 20.0112 27.0253 19.6947 27.3418C19.3783 27.6582 18.9491 27.836 18.5015 27.836Z"
                fill="#203946"
              />
            </Box>
          </Flex>

          <Box>
            <Text
              fontSize={{ base: '24px', md: '36px', xl: '40px' }}
              lineHeight="1.2"
              fontWeight="semibold"
              color="primary"
            >
              {dashboardData.errorCount}
            </Text>
            <Text fontSize={{ base: '9px', md: '16px' }} color="#292D32">
              Errors Captured
            </Text>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

NetRevenueGraph.propTypes = {
  intervalValue: PropTypes.number,
  revenueGraphData: PropTypes.any,
  currency: PropTypes.string,
  dashboardData: PropTypes.any,
};

export default NetRevenueGraph;
