import { createSlice } from '@reduxjs/toolkit';

const AuthSlice = createSlice({
  name: 'AuthSlice',
  initialState: {
    isLoading: false,
    loginToken: JSON.parse(localStorage.getItem('revive_admin__token')) ?? null,
    adminDetails: JSON.parse(localStorage.getItem('adminData')) ?? null,
    fieldValidationError: null,
    isError: null,
  },
  reducers: {
    /////// Login start //////
    requestLogin: (state) => {
      return { ...state, isLoading: true };
    },
    responseLogin: (state, action) => {
      localStorage.setItem(
        'revive_admin__token',
        JSON.stringify(action?.payload?.data?.token),
      );
      localStorage.setItem('adminData', JSON.stringify(action?.payload?.data));
      return {
        ...state,
        isLoading: false,
        adminDetails: action?.payload?.data?.user,
        loginToken: action?.payload?.data?.token,
        isCompanyLogin: action?.payload?.data?.user?.role,
        fieldValidationError: null,
      };
    },
    failureLogin: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        fieldValidationError: action?.payload,
      };
    },

    ////// logout //////
    requestLogout: (state) => {
      return { ...state, isLoading: true };
    },
    responseLogout: (state, action) => {
      localStorage.removeItem('revive_admin__token');
      localStorage.removeItem('adminData');
      return {
        ...state,
        isLoading: false,
        logoutData: action?.payload,
        loginToken: null,
        adminDetails: null,
        fieldValidationError: null,
      };
    },
    failureLogout: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase('update', (state, action) => {
  //     localStorage.setItem('adminData', JSON.stringify(action?.response?.data));
  //     return {
  //       ...state,
  //       adminDetails: action?.response?.data,
  //     };
  //   });
  // },
});

export const {
  requestLogin,
  responseLogin,
  failureLogin,
  requestLogout,
  responseLogout,
  failureLogout,
} = AuthSlice.actions;
export default AuthSlice.reducer;
