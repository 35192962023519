import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import CommonTable from 'components/commonTable/CommonTable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListStationData } from 'reactRedux/thunks/stationThunk/stationThunk';
import { stationStatusColors } from 'utils/StaticData';
import { stationStatus } from 'utils/StaticData';

const StationAllocationListModal = ({
  isOpen,
  onClose,
  setStationIds,
  editmerchantId,
  setTempStationIds,
  temStationIds,
}) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { isLoading, stationListData } = useSelector(
    (state) => state.StationSlice,
  );

  useEffect(() => {
    dispatch(
      ListStationData({
        page: page,
        limit: 10,
        allocated: 'false',
        merchantId: editmerchantId,
      }),
    );
  }, [page, dispatch, editmerchantId]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.stationId, {
      id: 'selectStation',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="60px"
          whiteSpace="nowrap"
        >
          Select
        </Text>
      ),
      cell: (info) => (
        <Checkbox
          value={info.getValue()}
          isChecked={temStationIds.includes(info.getValue())}
          onChange={() => handleCheckboxChange(info.getValue())}
        />
      ),
    }),
    columnHelper.accessor((row) => row?.stationId, {
      id: 'stationId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
        >
          Station Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.slots, {
      id: 'slots',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="50px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Slots
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            marginLeft="10px"
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.status, {
      id: 'status',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        const status = info.getValue();
        const statusText = stationStatus[status] || 'N/A';
        const statusColor = stationStatusColors[status] || 'red.500';
        return (
          <Text color={statusColor} fontSize="sm" fontWeight="600" align="left">
            {statusText}
          </Text>
        );
      },
    }),
  ];

  const handleCheckboxChange = (stationId) => {
    setTempStationIds((prev) => {
      if (prev.includes(stationId)) {
        return prev.filter((id) => id !== stationId);
      } else {
        return [...prev, stationId];
      }
    });
  };

  const handleSelectStations = () => {
    setStationIds(temStationIds);
    onClose();
  };

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Station List</ModalHeader>
          <ModalBody>
            <CommonTable
              columns={columns}
              data={stationListData?.list}
              isLoading={isLoading}
              keyValue="id"
              rowclick={['actions', 'status']}
              excludes={['actions', 'status']}
            />
            {stationListData?.total > 10 && (
              <CommonPagination
                totalCount={stationListData?.total}
                activePage={page}
                pageCount={Math.ceil(
                  stationListData?.total / stationListData?.limit,
                )}
                onPageChange={(e) => setPage(e)}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSelectStations}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

StationAllocationListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  stationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setStationIds: PropTypes.func.isRequired,
  editmerchantId: PropTypes.string.isRequired,
  // setIsShowSubmit: PropTypes.func.isRequired,
  temStationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTempStationIds: PropTypes.func.isRequired,
};

export default StationAllocationListModal;
