/* eslint-disable no-unused-vars */

import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ListOrderData } from 'reactRedux/thunks/orderThunk/orderThunk';
import { rentalStatusMap } from 'utils/StaticData';
import CommonTable from '../../../components/commonTable/CommonTable';
import { PiEye } from 'react-icons/pi';
import { SyncDataOrders } from 'reactRedux/thunks/orderThunk/orderThunk';
import SyncDataLoader from 'components/wholePageLoader/SyncDataLoader';
import { sideBarRoutes } from 'utils/StaticData';

const OrderList = () => {
  const [page, setPage] = useState(1);
  const { state } = useLocation();
  const { adminDetails } = useSelector((state) => state.authSlice);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [rentalStatus, setRentalStatus] = useState('');
  const [search, setSearch] = useState(
    state?.merchantId ? state?.merchantId : '',
  );
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const dispatch = useDispatch();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { isLoading, orderListData, isSyncLoading } = useSelector(
    (state) => state.OrderSlice,
  );

  const getOrderList = useCallback(
    (debouncedSearch, status, rental) => {
      dispatch(
        ListOrderData({
          page: page,
          limit: 10,
          merchantId: debouncedSearch,
          paymentStatus: status,
          rentalStatus: rental,
        }),
      );
    },
    [dispatch, page],
  );

  useEffect(() => {
    getOrderList(debouncedSearch, paymentStatus, rentalStatus);
    // eslint-disable-next-line
  }, [page]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.orderId, {
      id: 'orderId',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Order Id
        </Text>
      ),
      cell: (info) => (
        <Link
          to={`/admin/orders/details/${info?.row?.original?.orderId}`}
          className="btn btn-primary rounded-2 p-1"
        >
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {info.getValue()}
          </Text>
        </Link>
      ),
    }),
    columnHelper.accessor((row) => row?.powerbankId, {
      id: 'powerbankId',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Powerbank
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.paymentType, {
      id: 'paymentType',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Payment Type
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalStatus, {
      id: 'rentalStatus',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Rental Status
        </Text>
      ),
      cell: (info) => {
        const statusValue = info.getValue();
        const statusText = rentalStatusMap[statusValue] || 'N/A';
        return (
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {statusText}
          </Text>
        );
      },
    }),
    columnHelper.accessor((row) => row?.paymentStatus, {
      id: 'paymentStatus',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Payment Status
        </Text>
      ),
      cell: (info) => (
        <Text
          color={info.getValue() === 1 ? 'green.500' : 'red.500'}
          fontSize="sm"
          fontWeight="600"
          align="left"
        >
          {info.getValue() === 1 ? 'Paid' : 'Unpaid'}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.settlementAmount, {
      id: 'settlementAmount',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Settlement Amount
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight="600"
          align="left"
          whiteSpace="nowrap"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.rentTime, {
      id: 'rentTime',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Rent Time
        </Text>
      ),
      cell: (info) => {
        const rentTime = info.getValue();
        const formattedDateTime = moment(rentTime).format(
          'YYYY-MM-DD HH:mm:ss',
        );
        return (
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="600"
            align="left"
            whiteSpace="nowrap"
          >
            {formattedDateTime}
          </Text>
        );
      },
    }),
    columnHelper.accessor((row) => row?.duration, {
      id: 'duration',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Duration
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight="600"
          align="left"
          whiteSpace="nowrap"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalMerchantId, {
      id: 'rentalMerchantId',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Rental Merchant
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight="600"
          align="left"
          whiteSpace="nowrap"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalMerchant, {
      id: 'rentalMerchant',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Rental Merchant
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight="600"
          align="left"
          whiteSpace="nowrap"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalStationId, {
      id: 'rentalStationId',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Rental Stations
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight="600"
          align="left"
          whiteSpace="nowrap"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalSlot, {
      id: 'rentalSlot',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          whiteSpace="nowrap"
        >
          Rental Slots
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight="600"
          align="left"
          whiteSpace="nowrap"
        >
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor((row) => row?.actions, {
      id: 'actions',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          ACTION
        </Text>
      ),
      cell: (row) => (
        <Flex justify="left">
          <Link
            to={`/admin/orders/details/${row?.row?.original?.orderId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <PiEye size={20} title="View" className="text-white" />
          </Link>
        </Flex>
      ),
    }),
  ];

  useEffect(() => {
    if (state?.merchantId) {
      setSearch(state?.merchantId);
    }
  }, [state?.merchantId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const handleSyncData = () => {
    dispatch(SyncDataOrders(() => getOrderList('', '', '')));
  };

  return (
    <>
      {isSyncLoading && <SyncDataLoader />}
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex
          alignItems={'center'}
          flexWrap={'wrap'}
          mb={4}
          gap={{ base: '14px', md: '20px' }}
        >
          {adminDetails?.role === sideBarRoutes?.Admin && (
            <Flex
              gap={{ base: '10px', md: '20px' }}
              width={{ base: '100%', md: 'auto' }}
              alignItems={{ base: 'start', md: 'center' }}
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <label>Merchant Id:</label>
              <Input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                width={{ base: '100%', md: '200px' }}
              />
            </Flex>
          )}
          <Flex
            gap={{ base: '10px', md: '20px' }}
            width={{ base: '100%', md: 'auto' }}
            alignItems={{ base: 'start', md: 'center' }}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <label>Payment Status : </label>
            <Select
              value={paymentStatus}
              onChange={(e) => setPaymentStatus(e.target.value)}
              width={{ base: '100%', md: '200px' }}
            >
              <option value="">All</option>
              <option value="1">Paid</option>
              <option value="0">Unpaid</option>
            </Select>
          </Flex>
          <Flex
            gap={{ base: '10px', md: '20px' }}
            width={{ base: '100%', md: 'auto' }}
            alignItems={{ base: 'start', md: 'center' }}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <label>Rental Status : </label>
            <Select
              value={state?.rentalStatus ? state?.rentalStatus : rentalStatus}
              onChange={(e) => setRentalStatus(e.target.value)}
              width={{ base: '100%', md: '200px' }}
            >
              <option value="">All</option>
              {Object.entries(rentalStatusMap).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
          </Flex>

          <Flex gap={{ base: '14px', md: '20px' }} alignItems="center">
            <Button
              onClick={() => {
                setPage(1);
                getOrderList(debouncedSearch, paymentStatus, rentalStatus);
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setPage(1);
                setPaymentStatus('');
                setRentalStatus('');
                setSearch('');
                setDebouncedSearch('');
                getOrderList('', '', '');
              }}
            >
              Reset
            </Button>
          </Flex>
          {adminDetails?.role === sideBarRoutes?.Admin && (
            <Flex gap="20px" alignItems="center">
              <Button
                backgroundColor={'red.500'}
                onClick={() => handleSyncData()}
              >
                Sync Data
              </Button>
            </Flex>
          )}
        </Flex>
        <CommonTable
          columns={columns}
          data={orderListData?.list}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />
        {orderListData?.total > 10 && (
          <CommonPagination
            totalCount={orderListData?.total}
            activePage={page}
            pageCount={Math.ceil(orderListData?.total / orderListData?.limit)}
            onPageChange={(e) => setPage(e)}
          />
        )}
      </Box>
    </>
  );
};

export default OrderList;
