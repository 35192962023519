import * as Yup from 'yup';

export const signInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string()
    // .min(8, "Password must be at least 8 characters")
    .required('Password is required'),
});

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string().required('New password is required'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});

export const userAddEditValidation = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  // email: Yup.string()
  //   .email("Invalid email format")
  //   .required("Email is required"),
  phone: Yup.string()
    .required('Phone number is required')
    .max(12, 'Phone number should be less than 12 digits'),
});

export const merchantAddEditValidation = Yup.object().shape({
  // merchantId: Yup.string().required('Merchant Id is required'),
  name: Yup.string().required('Merchant Name is required'),
  freeDuration: Yup.number()
    .transform((value) => (value === '' || isNaN(value) ? undefined : value))
    .required('Free Duration is required')
    .positive('Must be a positive number'),
  price: Yup.number()
    .transform((value) => (value === '' || isNaN(value) ? undefined : value))
    .required('Price is required')
    .positive('Must be a positive number')
    .moreThan(0, 'Price must be greater than 0'),
  maxPrice: Yup.number()
    .transform((value) => (value === '' || isNaN(value) ? undefined : value))
    .required('Day capped amount is required')
    .min(0, 'amount must be 0 or greater')
    .test(
      'maxPrice-less-than-deposit',
      'Max amount must be less than deposit',
      function (value) {
        return value <= this.parent.deposit;
      },
    ),
  deposit: Yup.number()
    .transform((value) => (value === '' || isNaN(value) ? undefined : value))
    .required('Deposit is required')
    .positive('Must be a positive number'),
  daysLimit: Yup.number()
    .transform((value) => (value === '' || isNaN(value) ? undefined : value))
    .required('Days Limit is required')
    .min(0, 'Days Limit must be 0 or greater'),
});

export const stationAddEditValidation = Yup.object().shape({
  stationId: Yup.string().required('Station Id is required'),
  slots: Yup.number()
    .typeError('Slots must be a number')
    .required('Slots is required')
    .positive('Slots must be a positive number')
    .integer('Slots must be an integer')
    .min(1, 'Slots must be at least 1')
    .max(100, 'Slots cannot exceed 100'),
  // terminalId: Yup.string().required('Terminal Id is required'),
  // coordinates: Yup.array()
  //     .of(Yup.object().shape({
  //         value: Yup.string().required('Coordinate is required')
  //     }))
  //     .min(1, 'At least one coordinate is required'),
});

export const powerbankIdAddEditValidation = Yup.object().shape({
  powerbankId: Yup.string().required('Powerbank Id is required'),
  slot: Yup.number()
    .typeError('Slots must be a number')
    .required('Slots is required')
    .positive('Slots must be a positive number')
    .integer('Slots must be an integer')
    .min(1, 'Slots must be at least 1')
    .max(100, 'Slots cannot exceed 100'),
  // stationId: Yup.string().required('Station Id is required'),
});

// eslint-disable-next-line no-unused-vars
export const userAdminSchema = (selectedMerchants, adminEditUserId = false) =>
  Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    // password: adminEditUserId
    //   ? Yup.string().notRequired()
    //   : Yup.string().required('Password is required'),
    // confirmPassword: adminEditUserId
    //   ? Yup.string().notRequired()
    //   : Yup.string()
    //       .oneOf([Yup.ref('password'), null], 'Passwords must match')
    //       .required('Confirm Password is required'),
    roleId: Yup.mixed().required('Role is required'),
    merchantId: Yup.string().test(
      'is-required-if-role-3',
      'Merchant is required',
      function (value) {
        const roleId = this.parent.roleId?.id;
        if (roleId === 3 && !value) {
          return this.createError({
            path: 'merchantId',
            message: 'Merchant is required',
          });
        }
        return true;
      },
    ),
  });

export const editProfileValidationSchema = (showChangePassword) =>
  Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    // lastName: Yup.string().required('Last name is required'),
    newPassword: showChangePassword
      ? Yup.string().required('New password is required')
      : Yup.string(),
    // .min(6, 'Password must be at least 6 characters')
    confirmPassword: showChangePassword
      ? Yup.string()
          .required('Confirm password is required')
          .oneOf([Yup.ref('newPassword')], 'Passwords must match')
      : Yup.string(),
  });
