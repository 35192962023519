import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const SuccessModal = ({ title, isOpen, onClose, SolidBTNText, onConfirm }) => {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent
          maxWidth="400px"
          borderRadius="lg"
          boxShadow="lg"
          p={4}
        >
          <AlertDialogBody
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            py={6}
          >
            <FaCheckCircle color="#28a745" size={60} mb={4} />
            <Text fontSize="18px" fontWeight="medium" textAlign="center" mt={5}>
              {title}
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter justifyContent="center">
            <Button
              colorScheme="green"
              padding="10px 20px"
              onClick={() => onConfirm()}
            >
              {SolidBTNText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

SuccessModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  SolidBTNText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default React.memo(SuccessModal);
