import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { responseLogout } from 'reactRedux/slices/authSlice/authSlice';

const useAuthCheck = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginToken } = useSelector((state) => state.authSlice);

  useEffect(() => {
    const checkToken = () => {
      const storedToken = JSON.parse(
        localStorage.getItem('revive_admin__token'),
      );

      if (loginToken && storedToken !== loginToken) {
        dispatch(responseLogout());
        navigate('/auth/sign-in');
      }
    };

    const interval = setInterval(checkToken, 5000);
    return () => clearInterval(interval);
  }, [loginToken, dispatch, navigate]);

  return null;
};

export default useAuthCheck;
