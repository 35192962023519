import React, { useEffect } from 'react';
import { Box, Card, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ViewOrderDetailsData } from 'reactRedux/thunks/orderThunk/orderThunk';
import PageLoader from 'components/wholePageLoader/PageLoader';
import moment from 'moment';
import { rentalStatusMap } from 'utils/StaticData';

const OrderDetail = () => {
  const { ordersDetailId } = useParams();
  const dispatch = useDispatch();
  const { viewOrderDetails, isViewLoading } = useSelector(
    (state) => state.OrderSlice,
  );

  useEffect(() => {
    if (ordersDetailId) {
      dispatch(ViewOrderDetailsData({ id: ordersDetailId }));
    }
  }, [ordersDetailId, dispatch]);

  return (
    <>
      {isViewLoading ? (
        <PageLoader />
      ) : (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Flex direction="column" align="center" justify="center">
            {viewOrderDetails ? (
              <>
                <Card
                  w="100%"
                  boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                  borderRadius="20px"
                  paddingInline="40px"
                  paddingBlock="40px"
                  overflowX={{ sm: 'scroll', lg: 'hidden' }}
                >
                  <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Order ID:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.orderId}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Powerbank ID:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.powerbankId}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Payment Type:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.paymentType}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Rental Status:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {rentalStatusMap[viewOrderDetails?.rentalStatus] ||
                          'N/A'}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Payment Status:
                      </Text>
                      <Text
                        fontSize="sm"
                        color={
                          viewOrderDetails?.paymentStatus === 1
                            ? 'green.500'
                            : 'red.500'
                        }
                      >
                        {viewOrderDetails?.paymentStatus === 1
                          ? 'Paid'
                          : 'Unpaid'}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Rent Time:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {moment(viewOrderDetails?.rentTime).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Return Time:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.returnTime ? (
                          <Text fontSize="sm" color="gray.600">
                            {moment(viewOrderDetails.returnTime).format(
                              'YYYY-MM-DD HH:mm:ss',
                            )}
                          </Text>
                        ) : (
                          ''
                        )}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Duration:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.duration}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Rental Merchant:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.rentalMerchant}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Rental Station:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.rentalStationId}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Rental Slot:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.rentalSlot}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Return Slot:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.returnSlot}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        User Id:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.userId}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Settlement Amount:
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {viewOrderDetails?.settlementAmount}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="8px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontSize="lg" fontWeight="600">
                        Discount Amount:
                      </Text>
                      <Text fontSize="sm" color="green.600">
                        {viewOrderDetails?.discountAmount}
                      </Text>
                    </Flex>
                  </SimpleGrid>
                </Card>
                {viewOrderDetails?.note && (
                  <Flex
                    alignSelf="start"
                    direction="row"
                    gap="8px"
                    padding="16px 16px"
                    w="100%"
                  >
                    <Text fontSize="lg" fontWeight="600">
                      Note:
                    </Text>
                    <Text fontSize="sm" color="red" marginTop="3px">
                      {viewOrderDetails?.note
                        ? viewOrderDetails?.note
                        : 'Hellooo'}
                    </Text>
                  </Flex>
                )}
              </>
            ) : (
              <Text>No order details found.</Text>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default OrderDetail;
