import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import CommonTable from 'components/commonTable/CommonTable';
import DeleteModal from 'components/popUpModel/DeleteModal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PiEye, PiPencil, PiTrash } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  DeletePowerBankData,
  ListPowerBankData,
} from 'reactRedux/thunks/powerBankThunk/powerBankThunk';
import { powerBankStatus, sideBarRoutes } from 'utils/StaticData';

const PowerBankList = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const powerBankDeleteRef = useRef(null);
  // const [search, setSearch] = useState("")
  // const navigate = useNavigate();
  const { isLoading, powerBankListData } = useSelector(
    (state) => state.PowerBankSlice,
  );
  const { adminDetails } = useSelector((state) => state.authSlice);

  const getPowerbankList = useCallback(() => {
    dispatch(
      ListPowerBankData({
        page: page,
        limit: 10,
        // search : search
      }),
    );
  }, [dispatch, page]);

  useEffect(() => {
    getPowerbankList();
  }, [getPowerbankList]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.powerbankId, {
      id: 'powerbankId',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Powerbank Id
        </Text>
      ),
      cell: (info) => (
        <Link
          to={`/admin/powerbanks/details/${info?.row?.original?.powerbankId}`}
          className="btn btn-primary rounded-2 p-1"
        >
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {info.getValue()}
          </Text>
        </Link>
      ),
    }),
    columnHelper.accessor((row) => row?.stationId, {
      id: 'stationId',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Station Id
        </Text>
      ),
      cell: (info) => {
        const stationId = info.getValue();
        const stationIdValue =
          stationId && typeof stationId === 'object'
            ? stationId.stationId
            : stationId;
        return (
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {stationIdValue || 'N/A'}
          </Text>
        );
      },
    }),
    columnHelper.accessor((row) => row?.slot, {
      id: 'slot',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Slot
        </Text>
      ),
      cell: (info) => {
        const slot = info.getValue();
        const slotValue = slot && typeof slot === 'object' ? slot.slot : slot;
        return (
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {slotValue || 'N/A'}
          </Text>
        );
      },
    }),
    columnHelper.accessor((row) => row?.batteryLevel, {
      id: 'batteryLevel',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Battery Level(%)
        </Text>
      ),
      cell: (info) => {
        return (
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {info.getValue() ? `${info.getValue()} %` : 'N/A'}
          </Text>
        );
      },
    }),
    columnHelper.accessor((row) => row?.status, {
      id: 'status',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        const status = info.getValue();
        const statusText = powerBankStatus[status] || 'N/A';
        return (
          <Text color={textColor} fontSize="sm" fontWeight="600" align="left">
            {statusText}
          </Text>
        );
      },
    }),
    columnHelper.accessor((row) => row?.actions, {
      id: 'actions',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          ACTION
        </Text>
      ),
      cell: (row) => (
        <Flex gap={'20px'}>
          {adminDetails?.role === sideBarRoutes?.Admin && (
            <Link
              to={`/admin/powerbanks/edit/${row?.row?.original?.powerbankId}`}
              className="btn btn-light rounded-3 p-1"
            >
              <PiPencil size={20} title="Edit" />
            </Link>
          )}
          <Link
            to={`/admin/powerbanks/details/${row?.row?.original?.powerbankId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <PiEye size={20} title="View" className="text-white" />
          </Link>

          {adminDetails?.role === sideBarRoutes?.Admin && (
            <div className="btn btn-danger rounded-3 p-1">
              <PiTrash
                size={20}
                className="text-white"
                onClick={() => {
                  // setShow(true);
                  onOpen();
                  powerBankDeleteRef.current = row?.row?.original?.powerbankId;
                }}
                title="Delete"
              />
            </div>
          )}
        </Flex>
      ),
    }),
  ];

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex justify="flex-end" mb={4} gap="20px">
          {/* <CommonSearch search={search} setSearch={setSearch} setPage={setPage} /> */}
          {/* <Button
            bg="reviveOrange"
            color="primary"
            borderRadius="12px"
            _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
            onClick={() => navigate('/admin/powerbanks/add')}
          >
            <Icon
              as={FaPlus}
              width="15px"
              height="15px"
              marginRight="5px"
              color="inherit"
            />
            ADD Powerbank
          </Button> */}
        </Flex>
        <CommonTable
          columns={columns}
          data={powerBankListData?.list}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />
        {powerBankListData?.total > 10 && (
          <CommonPagination
            totalCount={powerBankListData?.total}
            activePage={page}
            pageCount={Math.ceil(
              powerBankListData?.total / powerBankListData?.limit,
            )}
            onPageChange={(e) => setPage(e)}
          />
        )}

        <DeleteModal
          isOpen={isOpen}
          onClose={onClose}
          title="Are you sure you want to delete this powerbank?"
          SolidBTNText="Delete"
          onDelete={() => {
            dispatch(
              DeletePowerBankData(powerBankDeleteRef.current, {}, () => {
                getPowerbankList();
                onClose();
              }),
            );
          }}
          loader={isLoading}
        />
      </Box>
    </>
  );
};

export default PowerBankList;
