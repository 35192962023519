import { Box, Card, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ViewMerchantData } from '../../../reactRedux/thunks/merchnatThunk/merchantThunk';
import StationMerchantDetail from './StationMerchantDetail';
import PageLoader from 'components/wholePageLoader/PageLoader';

const MerchantDetail = () => {
  const { merchantDetailId } = useParams();
  const dispatch = useDispatch();
  const { isLoading, viewMerchantDetails, isViewLoading } = useSelector(
    (state) => state.MerchantSlice,
  );

  useEffect(() => {
    if (merchantDetailId) {
      dispatch(ViewMerchantData({ id: merchantDetailId }));
    }
  }, [merchantDetailId]);

  return (
    <>
      {isViewLoading ? (
        <PageLoader />
      ) : viewMerchantDetails ? (
        <>
          <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Flex direction="column">
              <Card
                w="100%"
                boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                borderRadius="20px"
                paddingInline="40px"
                paddingBlock="40px"
                overflowX={{ sm: 'scroll', lg: 'hidden' }}
              >
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="24px">
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '10px' }}
                    borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                  >
                    <Text fontWeight="normal">Merchant Name :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.name}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '10px' }}
                    borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                  >
                    <Text fontWeight="normal">Commission :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.commission}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '10px' }}
                  >
                    <Text fontWeight="normal">Price :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.price}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '10px' }}
                    borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                  >
                    <Text fontWeight="normal">Days Limit :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.daysLimit}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '10px' }}
                    borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                  >
                    <Text fontWeight="normal">Deposit :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.deposit}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '10px' }}
                  >
                    <Text fontWeight="normal">Free Duration :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.freeDuration}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '10px' }}
                  >
                    <Text fontWeight="normal">Max Price :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.maxPrice}
                    </Text>
                  </Flex>
                </SimpleGrid>
              </Card>

              {viewMerchantDetails?.stations?.length > 0 && (
                <>
                  <Box pt="30px" alignItems="left">
                    <Text fontSize="2xl" fontWeight="medium">
                      Allocated Stations
                    </Text>
                  </Box>
                  <Card
                    w="100%"
                    boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                    borderRadius="20px"
                    paddingInline="40px"
                    paddingBlock="40px"
                    overflowX={{ sm: 'scroll', lg: 'hidden' }}
                    mt="20px"
                  >
                    <StationMerchantDetail
                      tableData={viewMerchantDetails?.stations}
                      isLoading={isViewLoading}
                    />
                  </Card>
                </>
              )}
            </Flex>
          </Box>
        </>
      ) : (
        <Text>No merchant details found.</Text>
      )}
    </>
  );
};

export default MerchantDetail;
