import React from 'react';
import { Box, Card, Flex, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import MapView from 'components/detailMapData/MapView';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ViewStationData } from 'reactRedux/thunks/stationThunk/stationThunk';
import PageLoader from '../../../components/wholePageLoader/PageLoader';
import PowerBankListDataTable from './PowerBankListDataTable';
import { stationStatus } from 'utils/StaticData';
import { stationStatusColors } from 'utils/StaticData';

const StationDetail = () => {
  const { stationDetailId } = useParams();
  const dispatch = useDispatch();
  const { viewStationDetails, isViewLoading } = useSelector(
    (state) => state.StationSlice,
  );

  useEffect(() => {
    if (stationDetailId) {
      dispatch(ViewStationData({ id: stationDetailId }));
    }
  }, [dispatch, stationDetailId]);

  return (
    <>
      {isViewLoading ? (
        <PageLoader />
      ) : (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Flex direction="column">
            {viewStationDetails ? (
              <>
                <Card
                  w="100%"
                  boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                  borderRadius="20px"
                  paddingInline="40px"
                  paddingBlock="40px"
                  overflowX={{ sm: 'scroll', lg: 'hidden' }}
                >
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="10px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontWeight="normal" width="90px">
                        Station Id :
                      </Text>
                      <Text fontWeight="semibold">
                        {viewStationDetails.stationId}
                      </Text>
                    </Flex>

                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="10px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontWeight="normal" flexShrink="0">
                        Slots :
                      </Text>
                      <Text fontWeight="semibold">
                        {viewStationDetails?.slots}
                      </Text>
                    </Flex>

                    <Flex
                      alignItems={{ sm: 'start', md: 'center' }}
                      direction={{ sm: 'column', md: 'row' }}
                      gap="10px"
                      padding="4px 8px"
                      borderLeft={'1px solid #ccc'}
                    >
                      <Text fontWeight="normal">Status :</Text>
                      <Text
                        fontWeight="semibold"
                        color={
                          stationStatusColors[viewStationDetails?.status] ||
                          'gray.500'
                        }
                      >
                        {stationStatus[viewStationDetails?.status] || 'N/A'}
                      </Text>
                    </Flex>

                    {viewStationDetails?.merchant &&
                      Object.keys(viewStationDetails.merchant).length > 0 && (
                        <>
                          <Flex
                            alignItems={{ sm: 'start', md: 'center' }}
                            direction={{ sm: 'column', md: 'row' }}
                            gap="10px"
                            padding="4px 8px"
                            borderLeft={'1px solid #ccc'}
                          >
                            <Text fontWeight="normal" flexShrink="0">
                              Merchant Id :
                            </Text>
                            <Text fontWeight="semibold">
                              {viewStationDetails?.merchant?.merchantId}
                            </Text>
                          </Flex>

                          <Flex
                            alignItems={{ sm: 'start', md: 'center' }}
                            direction={{ sm: 'column', md: 'row' }}
                            gap="10px"
                            padding="4px 8px"
                            borderLeft={'1px solid #ccc'}
                          >
                            <Text fontWeight="normal" flexShrink="0">
                              Merchant Name :
                            </Text>
                            <Text fontWeight="semibold">
                              {viewStationDetails?.merchant?.name}
                            </Text>
                          </Flex>

                          <Flex
                            alignItems={{ sm: 'start', md: 'center' }}
                            direction={{ sm: 'column', md: 'row' }}
                            gap="10px"
                            padding="4px 8px"
                            borderLeft={'1px solid #ccc'}
                          >
                            <Text fontWeight="normal" flexShrink="0">
                              Address :
                            </Text>
                            <Text fontWeight="semibold">
                              {viewStationDetails?.merchant?.address}
                            </Text>
                          </Flex>

                          {viewStationDetails?.coordinates && (
                            <GridItem colSpan={{ base: 1, md: 2 }}>
                              <Text fontWeight="normal" mb={2}>
                                Coordinates :
                              </Text>
                              <MapView
                                coordinates={
                                  viewStationDetails?.coordinates || [0, 0]
                                }
                                isChange={false}
                              />
                            </GridItem>
                          )}
                        </>
                      )}
                  </SimpleGrid>
                </Card>
                {viewStationDetails?.powerbank &&
                  viewStationDetails?.powerbank?.length > 0 && (
                    <>
                      <Box pt="30px" alignItems="left">
                        <Text fontSize="2xl" fontWeight="medium">
                          Allocated Powerbanks
                        </Text>
                      </Box>
                      <Card
                        w="100%"
                        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                        borderRadius="20px"
                        paddingInline="40px"
                        paddingBlock="40px"
                        overflowX={{ sm: 'scroll', lg: 'hidden' }}
                        mt="20px"
                      >
                        <PowerBankListDataTable
                          tableData={viewStationDetails?.powerbank}
                          isLoading={isViewLoading}
                        />
                      </Card>
                    </>
                  )}
              </>
            ) : (
              <Text>No station details found.</Text>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default StationDetail;
