import Swal from 'sweetalert2';
import requestApi from 'utils/Request';
import {
  EDIT_ADMIN_PROFILE,
  FORGOT_PASSWORD,
  LOGIN,
  RESET_PASSWORD,
  UPDATEPROFILE,
} from '../../../utils/StaticApiEndpoints';
import {
  failureEditProfile,
  failureForgotPassword,
  failureLogin,
  failureResetPassword,
  failureUpdateProfile,
  requestEditProfile,
  requestForgotPassword,
  requestLogin,
  requestResetPassword,
  requestUpdateProfile,
  responseEditProfile,
  responseForgotPassword,
  responseLogin,
  responseResetPassword,
  responseUpdateProfile,
} from '../../slices/authSlice/authSlice';

/////////// Login //////////
export const UserLogin = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestLogin());
    const res = await requestApi.post(LOGIN, payload);
    dispatch(responseLogin(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureLogin(error?.message));
  }
};

///////// Forgot Password //////
export const ForgotPasswordAuth = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestForgotPassword());
    const res = await requestApi.post(FORGOT_PASSWORD, payload);

    dispatch(responseForgotPassword(res));

    Swal.fire({
      icon: 'success',
      text: res?.message,
    }).then(() => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureForgotPassword(error?.message));
  }
};

//////// Reset Password //////
export const ResetPasswordAuth = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestResetPassword());
    const res = await requestApi.post(RESET_PASSWORD, payload);
    dispatch(responseResetPassword(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureResetPassword(error?.message));
  }
};

//////// Update Profile //////
export const UpdateProfile = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestUpdateProfile());
    const res = await requestApi.get(UPDATEPROFILE, payload);
    dispatch(responseUpdateProfile(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureUpdateProfile(error?.message));
  }
};

//////// Edit Profile //////
export const EditProfile = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestEditProfile());
    const res = await requestApi.put(EDIT_ADMIN_PROFILE, payload);
    dispatch(responseEditProfile(res));
    Swal.fire({
      icon: 'success',
      text: res?.message,
    }).then(() => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureEditProfile(error?.message));
  }
};
