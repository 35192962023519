import Swal from 'sweetalert2';
import requestApi from 'utils/Request';
import { LOGIN } from '../../../utils/StaticApiEndpoints';
import {
  failureLogin,
  requestLogin,
  responseLogin,
} from '../../slices/authSlice/authSlice';

/////////// Login //////////
export const UserLogin = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestLogin());
    const res = await requestApi.post(LOGIN, payload);
    dispatch(responseLogin(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureLogin(error?.message));
  }
};
