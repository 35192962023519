import { addDays, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import { createStaticRanges } from 'react-date-range';

export const userData = [
  { id: 1, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 2, title: 'Jane Smith', tech: ['windows'] },
  { id: 3, title: 'Michael Johnson', tech: ['apple'] },
  { id: 4, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 5, title: 'Jane Smith', tech: ['windows'] },
  { id: 6, title: 'Michael Johnson', tech: ['apple'] },
  { id: 7, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 8, title: 'Jane Smith', tech: ['windows'] },
  { id: 9, title: 'Michael Johnson', tech: ['apple'] },
  { id: 10, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 11, title: 'Jane Smith', tech: ['windows'] },
  { id: 12, title: 'Michael Johnson', tech: ['apple'] },
  { id: 13, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 14, title: 'Jane Smith', tech: ['windows'] },
  { id: 15, title: 'Michael Johnson', tech: ['apple'] },
  { id: 16, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 17, title: 'Jane Smith', tech: ['windows'] },
  { id: 18, title: 'Michael Johnson', tech: ['apple'] },
  { id: 19, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 20, title: 'Jane Smith', tech: ['windows'] },
  { id: 21, title: 'Michael Johnson', tech: ['apple'] },
  { id: 22, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 23, title: 'Jane Smith', tech: ['windows'] },
  { id: 24, title: 'Michael Johnson', tech: ['apple'] },
];

export const rentalStatusMap = {
  0: 'Can Rental',
  1: 'On Rent',
  2: 'Revoked',
  3: 'Returned',
  4: 'Trouble Order',
  5: 'Customer Purchase',
  6: 'Suspend Billing',
};

export const powerBankStatus = {
  1: 'Can Rent',
  2: 'On Rent',
  3: 'Customer Purchase',
};

export const stationStatus = {
  1: 'Online',
  2: 'Offline',
};

export const stationStatusColors = {
  1: 'green.500',
  2: 'red.500',
};

export const sideBarRoutes = {
  Admin: 1,
  Merchant: 3,
};
export const loginUserRole = {
  1: 'Admin',
  2: 'User',
  3: 'Merchant',
};

export const RoleOption = [
  {
    id: 1,
    label: 'Admin',
  },
  {
    id: 3,
    label: 'Merchant',
  },
];

export const errorsColor = [
  '#FBB03B', // Vibrant Orange
  '#53EB95', // Bright Green
  '#203946', // Dark Teal
  '#16A34A', // Deep Green
  '#E63946', // Bold Red
  '#3A86FF', // Vivid Blue
  '#8338EC', // Rich Purple
  '#FF006E', // Hot Pink
  '#06D6A0', // Mint Green
  '#F77F00', // Warm Orange
  '#118AB2', // Deep Cyan
  '#8D99AE', // Muted Gray-Blue
  '#FFB400', // Golden Yellow
  '#2D6A4F', // Forest Green
  '#EF476F', // Coral Pink
  '#26547C', // Deep Navy
];

export const customStaticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: startOfDay(new Date()),
      endDate: new Date(),
    }),
  },
  {
    label: 'Last 7 days',
    range: () => ({
      startDate: addDays(new Date(), -6),
      endDate: new Date(),
    }),
  },
  {
    label: 'Last 4 weeks',
    range: () => ({
      startDate: addDays(new Date(), -28),
      endDate: new Date(),
    }),
  },
  {
    label: 'Last 3 Months',
    range: () => {
      const today = new Date();
      const last3Months = new Date();
      last3Months.setMonth(today.getMonth() - 3);

      return {
        startDate: last3Months,
        endDate: today,
      };
    },
  },
  {
    label: 'Last 12 Months',
    range: () => {
      const today = new Date();
      const last12Months = new Date();
      last12Months.setMonth(today.getMonth() - 12);

      return {
        startDate: last12Months,
        endDate: today,
      };
    },
  },
  {
    label: 'Month to date',
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
    }),
  },
  {
    label: 'Quarter to date',
    range: () => {
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(today.getMonth() - 3); // Subtract 3 months from today

      return {
        startDate: threeMonthsAgo,
        endDate: today,
      };
    },
  },
  {
    label: 'Year to date',
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: new Date(),
    }),
  },
  {
    label: 'All time',
    range: () => ({
      startDate: new Date(2000, 0, 1), // Set a very old date to include all time data
      endDate: new Date(),
    }),
    // range: () => {
    //   const currentYear = new Date().getFullYear();
    //   return {
    //     startDate: new Date(currentYear, 0, 1),
    //     endDate: new Date(),
    //   };
    // },
  },
]);
