import React from 'react';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import CommonTable from 'components/commonTable/CommonTable';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PiEye, PiPencil, PiTrash } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useColorModeVariables } from '../../../components/colorModeVarialbels/ColorModeValues';
import DeleteModal from '../../../components/popUpModel/DeleteModal';
import {
  DeleteUserData,
  ListUserData,
} from '../../../reactRedux/thunks/userThunk/userThunk';
const UserList = () => {
  const { tabletextColor } = useColorModeVariables();
  const [page, setPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userDeleteRef = useRef(null);
  const { isLoading, userListData } = useSelector((state) => state.UserSlice);
  // const [search,setSearch] = useState("")
  const dispatch = useDispatch();

  const getUserList = useCallback(() => {
    dispatch(
      ListUserData({
        page: page,
        limit: 10,
        // search: search
      }),
    );
  }, [dispatch, page]);

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.firstName, {
      id: 'firstName',
      header: () => (
        <Text
          whiteSpace="nowrap"
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
        >
          First Name
        </Text>
      ),
      cell: (info) => (
        <Link
          to={`/admin/users/details/${info?.row?.original?.userId}`}
          className="btn btn-primary rounded-2 p-1"
        >
          <Text
            color={tabletextColor}
            fontSize="sm"
            fontWeight="600"
            align="left"
          >
            {info.getValue()}
          </Text>
        </Link>
      ),
    }),
    columnHelper.accessor((row) => row?.lastName, {
      id: 'lastName',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Last Name
        </Text>
      ),
      cell: (info) => (
        <Text
          color={tabletextColor}
          fontSize="sm"
          fontWeight="600"
          align="left"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.email, {
      id: 'email',
      header: () => (
        <Text
          whiteSpace="nowrap"
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
        >
          Email
        </Text>
      ),
      cell: (info) => (
        <Text
          color={tabletextColor}
          fontSize="sm"
          fontWeight="600"
          align="left"
        >
          {info.getValue() ? info.getValue() : 'N/A'}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.phone, {
      id: 'phone',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Contact Number
        </Text>
      ),
      cell: (info) => (
        <Text
          color={tabletextColor}
          fontSize="sm"
          fontWeight="600"
          align="left"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row?.actions, {
      id: 'actions',
      header: () => (
        <Text
          align="left"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          ACTION
        </Text>
      ),
      cell: (row) => (
        <Flex align="left">
          <Link
            to={`/admin/users/edit/${row?.row?.original?.userId}`}
            className="btn btn-light rounded-3 p-1"
          >
            <PiPencil size={20} title="Edit" style={{ marginRight: '20px' }} />
          </Link>
          <Link
            to={`/admin/users/details/${row?.row?.original?.userId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <PiEye
              size={20}
              title="View"
              className="text-white"
              style={{ marginRight: '20px' }}
            />
          </Link>
          <div className="btn btn-danger rounded-3 p-1">
            <PiTrash
              size={20}
              className="text-white"
              onClick={() => {
                // setShow(true);
                onOpen();
                userDeleteRef.current = row?.row?.original?.userId;
              }}
              title="Delete"
            />
          </div>
        </Flex>
      ),
    }),
  ];

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex justify="flex-end" mb={4} gap="20px">
          {/* <CommonSearch search={search} setSearch={setSearch} setPage={setPage}/> */}
          {/* <Button
            bg="reviveOrange"
            color="primary"
            borderRadius="12px"
            _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
            onClick={() => navigate('/admin/users/add')}
          >
            <Icon
              as={FaPlus}
              width="15px"
              height="15px"
              marginRight="5px"
              color="inherit"
            />
            Add User
          </Button> */}
        </Flex>
        <CommonTable
          columns={columns}
          data={userListData?.list}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />

        {userListData?.total > 10 && (
          <CommonPagination
            totalCount={userListData?.total}
            activePage={page}
            pageCount={Math.ceil(userListData?.total / userListData?.limit)}
            onPageChange={(e) => setPage(e)}
          />
        )}

        <DeleteModal
          isOpen={isOpen}
          onClose={onClose}
          title="Are you sure you want to delete this user?"
          SolidBTNText="Delete"
          onDelete={() => {
            dispatch(
              DeleteUserData(userDeleteRef.current, {}, () => {
                if (page > 1 && userListData?.list?.length === 1) {
                  setPage(page - 1);
                } else {
                  getUserList();
                }
                onClose();
              }),
            );
          }}
          loader={isLoading}
        />
      </Box>
    </>
  );
};

export default UserList;
