import React from 'react';
import { Icon } from '@chakra-ui/react';
import { FaChargingStation, FaUsers } from 'react-icons/fa';
import { GrUserAdmin } from 'react-icons/gr';
import {
  MdAddHome,
  MdHome,
  MdOutlineEvStation,
  MdPerson,
  MdAssignment,
} from 'react-icons/md';
import MainDashboard from 'views/admin/default';
import Profile from 'views/admin/profile';
import MerchantDetail from 'views/admin/merchantPages/MerchantDetail';
import PowerBankAddEdit from 'views/admin/powerBankPages/PowerBankAddEdit';
import PowerBankDetails from 'views/admin/powerBankPages/PowerBankDetails';
import PowerBankList from 'views/admin/powerBankPages/PowerBankList';
import StationAddEdit from 'views/admin/stationPages/StationAddEdit';
import StationDetail from 'views/admin/stationPages/StationDetail';
import StationList from 'views/admin/stationPages/StationList';
import UserDetails from 'views/admin/usersPages/UserDetails';
import UserEdit from 'views/admin/usersPages/UserEdit';
import UserList from 'views/admin/usersPages/UserList';
import MerchantAddEdit from '../src/views/admin/merchantPages/MerchantAddEdit';
import MerchantList from '../src/views/admin/merchantPages/MerchantList';
import OrderList from 'views/admin/orderPages/OrderList';
import OrderDetail from 'views/admin/orderPages/OrderDetail';
import AdminUserList from 'views/admin/adminUserPages/AdminUserList';
import AdminUserAddEdit from 'views/admin/adminUserPages/AdminUserAddEdit';
import AdminUserDetail from 'views/admin/adminUserPages/AdminUserDetail';
import { sideBarRoutes } from 'utils/StaticData';

const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboard',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
    roles: [sideBarRoutes?.Admin, sideBarRoutes?.Merchant],
    hidden: false,
  },

  //////  Admin User Module /////
  {
    name: 'Admin Users',
    layout: '/admin',
    path: '/admin-users',
    icon: <Icon as={GrUserAdmin} width="20px" height="20px" color="inherit" />,
    component: <AdminUserList />,
    roles: [sideBarRoutes?.Admin],
    hidden: false,
  },
  {
    name: 'Add Admin User',
    layout: '/admin',
    path: '/admin-users/add',
    icon: <Icon as={GrUserAdmin} width="20px" height="20px" color="inherit" />,
    component: <AdminUserAddEdit />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  {
    name: 'Edit User',
    layout: '/admin',
    path: '/admin-users/edit/:adminEditUserId',
    icon: <Icon as={GrUserAdmin} width="20px" height="20px" color="inherit" />,
    component: <AdminUserAddEdit />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  {
    name: 'User Details',
    layout: '/admin',
    path: '/admin-users/details/:adminUserDetailId',
    icon: <Icon as={GrUserAdmin} width="20px" height="20px" color="inherit" />,
    component: <AdminUserDetail />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },

  //////   User Module ////
  {
    name: 'Users',
    layout: '/admin',
    path: '/users',
    icon: <Icon as={FaUsers} width="20px" height="20px" color="inherit" />,
    component: <UserList />,
    roles: [sideBarRoutes?.Admin],
    hidden: false,
  },
  {
    name: 'Add User',
    layout: '/admin',
    path: '/users/add',
    icon: <Icon as={FaUsers} width="20px" height="20px" color="inherit" />,
    component: <UserEdit />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  {
    name: 'Edit User',
    layout: '/admin',
    path: '/users/edit/:editUserId',
    icon: <Icon as={FaUsers} width="20px" height="20px" color="inherit" />,
    component: <UserEdit />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  {
    name: 'User Details',
    layout: '/admin',
    path: '/users/details/:userDetailId',
    icon: <Icon as={FaUsers} width="20px" height="20px" color="inherit" />,
    component: <UserDetails />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },

  ///// Merchant Module ///
  {
    name: 'Merchants',
    layout: '/admin',
    path: '/merchants',
    icon: <Icon as={MdAddHome} width="20px" height="20px" color="inherit" />,
    component: <MerchantList />,
    roles: [sideBarRoutes?.Admin],
    hidden: false,
  },
  {
    name: 'Add Merchant',
    layout: '/admin',
    path: '/merchants/add',
    icon: <Icon as={MdAddHome} width="20px" height="20px" color="inherit" />,
    component: <MerchantAddEdit />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  {
    name: 'Edit Merchant',
    layout: '/admin',
    path: '/merchants/edit/:editmerchantId',
    icon: <Icon as={MdAddHome} width="20px" height="20px" color="inherit" />,
    component: <MerchantAddEdit />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  {
    name: 'Merchant Details',
    layout: '/admin',
    path: '/merchants/details/:merchantDetailId',
    icon: <Icon as={MdAddHome} width="20px" height="20px" color="inherit" />,
    component: <MerchantDetail />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  /////// Station Module /////

  {
    name: 'Stations',
    layout: '/admin',
    path: '/stations',
    icon: (
      <Icon as={FaChargingStation} width="20px" height="20px" color="inherit" />
    ),
    component: <StationList />,
    roles: [sideBarRoutes?.Admin, sideBarRoutes?.Merchant],
    hidden: false,
  },
  {
    name: 'Add Station',
    layout: '/admin',
    path: '/stations/add',
    icon: (
      <Icon as={FaChargingStation} width="20px" height="20px" color="inherit" />
    ),
    component: <StationAddEdit />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  {
    name: 'Edit Station',
    layout: '/admin',
    path: '/stations/edit/:editStationId',
    icon: (
      <Icon as={FaChargingStation} width="20px" height="20px" color="inherit" />
    ),
    component: <StationAddEdit />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  {
    name: 'Station Details',
    layout: '/admin',
    path: '/stations/details/:stationDetailId',
    icon: (
      <Icon as={FaChargingStation} width="20px" height="20px" color="inherit" />
    ),
    component: <StationDetail />,
    roles: [sideBarRoutes?.Admin, sideBarRoutes?.Merchant],
    hidden: true,
  },

  ///////// PowerBank Detail //////
  {
    name: 'Powerbanks',
    layout: '/admin',
    path: '/powerbanks',
    icon: (
      <Icon
        as={MdOutlineEvStation}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <PowerBankList />,
    roles: [sideBarRoutes?.Admin, sideBarRoutes?.Merchant],
    hidden: false,
  },
  {
    name: 'Add Powerbank',
    layout: '/admin',
    path: '/powerbanks/add',
    icon: (
      <Icon
        as={MdOutlineEvStation}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <PowerBankAddEdit />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  {
    name: 'Edit Powerbank',
    layout: '/admin',
    path: '/powerbanks/edit/:editpowerbanksId',
    icon: (
      <Icon
        as={MdOutlineEvStation}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <PowerBankAddEdit />,
    roles: [sideBarRoutes?.Admin],
    hidden: true,
  },
  {
    name: 'Powerbank Details',
    layout: '/admin',
    path: '/powerbanks/details/:powerbanksDetailId',
    icon: (
      <Icon
        as={MdOutlineEvStation}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <PowerBankDetails />,
    roles: [sideBarRoutes?.Admin, sideBarRoutes?.Merchant],
    hidden: true,
  },

  ////// Order Module ///
  {
    name: 'Orders',
    layout: '/admin',
    path: '/orders',
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: <OrderList />,
    roles: [sideBarRoutes?.Admin, sideBarRoutes?.Merchant],
    hidden: false,
  },
  {
    name: 'Orders Details',
    layout: '/admin',
    path: '/orders/details/:ordersDetailId',
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: <OrderDetail />,
    roles: [sideBarRoutes?.Admin, sideBarRoutes?.Merchant],
    hidden: true,
  },

  /////// Profile Module /////
  {
    name: 'Profile',
    layout: '/admin',
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
    roles: [sideBarRoutes?.Admin, sideBarRoutes?.Merchant],
    hidden: true,
  },
];

export default routes;
