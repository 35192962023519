import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import DeleteModal from 'components/deleteModalPopUp/DeleteModal';
import { useEffect, useRef, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { PiEye, PiPencil, PiTrash } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  DeleteMerchnatData,
  ListMerchantData,
} from 'reactRedux/thunks/merchnatThunk/merchantThunk';
import CommonTable from '../../../components/commonTable/CommonTable';
const MerchantList = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const merchnatDeleteRef = useRef(null);
  const navigate = useNavigate();
  const { isLoading, merchantListData } = useSelector(
    (state) => state.MerchantSlice,
  );

  const getMerchantList = () => {
    dispatch(ListMerchantData({ page: page, limit: 10 }));
  };

  useEffect(() => {
    getMerchantList();
  }, [page]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.merchantId, {
      id: 'merchantId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Merchant Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.name, {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Merchant Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue() ? info.getValue() : 'N/A'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.commission, {
      id: 'commission',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Commission
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.daysLimit, {
      id: 'daysLimit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="80px"
          whiteSpace="nowrap"
        >
          Days Limit
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.deposit, {
      id: 'deposit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="60px"
          whiteSpace="nowrap"
        >
          Deposit
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.freeDuration, {
      id: 'freeDuration',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="110px"
          whiteSpace="nowrap"
        >
          Free Duration
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.maxPrice, {
      id: 'maxPrice',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="75px"
          whiteSpace="nowrap"
        >
          Max Price
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.price, {
      id: 'price',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="40px"
          whiteSpace="nowrap"
        >
          Price
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.actions, {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="50px"
          whiteSpace="nowrap"
        >
          ACTION
        </Text>
      ),
      cell: (row) => (
        <Flex align="center">
          <Link
            to={`/admin/merchants/edit/${row?.row?.original?.merchantId}`}
            className="btn btn-light rounded-3 p-1"
          >
            <PiPencil size={20} title="Edit" style={{ marginRight: '20px' }} />
          </Link>
          <Link
            to={`/admin/merchants/details/${row?.row?.original?.merchantId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <PiEye
              size={20}
              title="View"
              className="text-white"
              style={{ marginRight: '20px' }}
            />
          </Link>
          <div className="btn btn-danger rounded-3 p-1">
            <PiTrash
              size={20}
              className="text-white"
              onClick={() => {
                // setShow(true);
                onOpen();
                merchnatDeleteRef.current = row?.row?.original?.merchantId;
              }}
              title="Delete"
            />
          </div>
        </Flex>
      ),
    }),
  ];

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex justify="space-between" mb={4}>
          <Text fontSize="2xl" fontWeight="medium">
            Merchants List
          </Text>
          <Button
            bg="reviveOrange"
            color="primary"
            borderRadius="12px"
            _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
            onClick={() => navigate('/admin/merchants/add')}
          >
            <Icon
              as={FaPlus}
              width="15px"
              height="15px"
              borderRadius="12px"
              marginRight="5px"
              color="inherit"
            />
            Add Merchant
          </Button>
        </Flex>
        <CommonTable
          columns={columns}
          data={merchantListData?.list}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />
        {merchantListData?.list?.length > 10 && (
          <CommonPagination
            totalCount={merchantListData?.total}
            activePage={page}
            pageCount={Math.ceil(
              merchantListData?.total / merchantListData?.limit,
            )}
            onPageChange={(e) => setPage(e)}
          />
        )}

        <DeleteModal
          isOpen={isOpen}
          onClose={onClose}
          title="Are you sure you want to delete this merchant?"
          SolidBTNText="Delete"
          onDelete={() => {
            dispatch(
              DeleteMerchnatData(merchnatDeleteRef.current, {}, () => {
                getMerchantList();
                onClose();
              }),
            );
          }}
          loader={isLoading}
        />
      </Box>
    </>
  );
};

export default MerchantList;
