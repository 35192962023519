import { createSlice } from '@reduxjs/toolkit';

const DashboardSlice = createSlice({
  name: 'DashboardSlice',
  initialState: {
    isLoading: false,
    dashboardData: [],
    revenueGraphData: [],
    revenueOverviewData: [],
    errorOverviewData: [],
    merchantNameList: [],
    recurringUsers: [],
    fieldValidationError: null,
    isError: null,
  },
  reducers: {
    /////// Login start //////
    requestDashboardData: (state) => {
      return { ...state, isLoading: true };
    },
    responseDashboardData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        dashboardData: action?.payload?.data,
        fieldValidationError: null,
      };
    },
    failureDashboardData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
        fieldValidationError: action?.payload,
      };
    },

    requestRevenueGraphData: (state) => {
      return { ...state, isLoading: true };
    },
    responseRevenueGraphData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        revenueGraphData: action?.payload?.data,
        fieldValidationError: null,
      };
    },
    failureRevenueGraphData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
        fieldValidationError: action?.payload,
      };
    },

    requestRevenueOverviewData: (state) => {
      return { ...state, isLoading: true };
    },
    responseRevenueOverviewData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        revenueOverviewData: action?.payload?.data,
        fieldValidationError: null,
      };
    },
    failureRevenueOverviewhData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
        fieldValidationError: action?.payload,
      };
    },

    ///  ERROR OVERVIEW ///
    requestErrorOverviewData: (state) => {
      return { ...state, isLoading: true };
    },
    responseErrorOverviewData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        errorOverviewData: action?.payload?.data,
        fieldValidationError: null,
      };
    },
    failureErrorOverviewData: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
        fieldValidationError: action?.payload,
      };
    },

    ///  RECURRING USERS ///
    requestRecurringUsers: (state) => {
      return { ...state, isLoading: true };
    },
    responseRecurringUsers: (state, action) => {
      return {
        ...state,
        isLoading: false,
        recurringUsers: action?.payload?.data,
        fieldValidationError: null,
      };
    },
    failureRecurringUsers: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
        fieldValidationError: action?.payload,
      };
    },

    requestMerchantNameList: (state) => {
      return { ...state, isLoading: true };
    },
    responseMerchantNameList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        merchantNameList: action?.payload?.data,
        fieldValidationError: null,
      };
    },
    failureMerchantNameList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
        fieldValidationError: action?.payload,
      };
    },
  },
});

export const {
  failureDashboardData,
  requestDashboardData,
  responseDashboardData,
  requestRevenueGraphData,
  responseRevenueGraphData,
  failureRevenueGraphData,
  requestRevenueOverviewData,
  responseRevenueOverviewData,
  failureRevenueOverviewhData,
  requestMerchantNameList,
  responseMerchantNameList,
  failureMerchantNameList,
  requestErrorOverviewData,
  responseErrorOverviewData,
  failureErrorOverviewData,
  requestRecurringUsers,
  responseRecurringUsers,
  failureRecurringUsers,
} = DashboardSlice.actions;
export default DashboardSlice.reducer;
