import { Box, Flex } from '@chakra-ui/react';
import * as dateFns from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetDashboardGraphData,
  GetMerchantNameList,
  GetRevenueGraphData,
  RevenueOverview,
  ErrorsOverview,
  ReoccurringUsers,
} from 'reactRedux/thunks/dashboardThunk/dashboardThunk';
import { customStaticRanges, sideBarRoutes } from 'utils/StaticData';
import './custom-calendar.css';
import NetRevenueGraph from './NetRevenueGraph';
import RecurringuserGraph from './RecurringuserGraph';
import RevenueOverViewGraph from './RevenueOverViewGraph';

export default function UserReports() {
  const addDays = dateFns.addDays;
  const dispatch = useDispatch();
  const currency = 'EUR';
  const { adminDetails } = useSelector((state) => state.authSlice);
  const {
    dashboardData,
    revenueGraphData,
    revenueOverviewData,
    merchantNameList,
    errorOverviewData,
    recurringUsers,
  } = useSelector((state) => state.DashboardSlice);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [dateRange, setDateRange] = React.useState([
    {
      endDate: new Date(),
      startDate: addDays(new Date(), -6), // Default 7 days range
      key: 'selection',
    },
  ]);
  const [calendarFilterTitle, setCalendarFilterTitle] = useState('Last 7 days');
  const [tempCalendarFilterTitle, setTempCalendarFilterTitle] =
    useState('Last 7 days');
  const [tempDateRange, setTempDateRange] = useState(dateRange);
  const [intervalValue, setIntervalValue] = useState(0);
  const [selectedMerchant, setSelectedMerchant] = useState('');

  useEffect(() => {
    if (adminDetails?.role === sideBarRoutes?.Admin) {
      dispatch(GetMerchantNameList());
    }
    dispatch(GetDashboardGraphData());
    dispatch(GetRevenueGraphData());

    const startDate = dateRange[0]?.startDate;
    const endDate = dateRange[0]?.endDate;

    dispatch(RevenueOverview({ start: startDate, end: endDate }));
    dispatch(ErrorsOverview({ start: startDate, end: endDate }));
    dispatch(ReoccurringUsers({ start: startDate, end: endDate }));
  }, [adminDetails, dispatch]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        dispatch(GetDashboardGraphData());
        dispatch(GetRevenueGraphData());
      },
      5 * 60 * 1000,
    ); // 5 minutes in milliseconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  useEffect(() => {
    const updateInterval = () => {
      const width = window.innerWidth;
      if (width > 1200) {
        setIntervalValue(1); // Show all labels on large screens
      } else if (width > 768) {
        setIntervalValue(2); // Skip every 2nd label on tablets
      } else {
        setIntervalValue(4); // Skip more labels on smaller screens
      }
    };

    updateInterval();
    window.addEventListener('resize', updateInterval);

    return () => window.removeEventListener('resize', updateInterval);
  }, []);

  const handleSelect = (ranges) => {
    const selectedRange = customStaticRanges.find(
      (range) =>
        range.range().startDate.getTime() ===
          ranges.selection.startDate.getTime() &&
        range.range().endDate.getTime() === ranges.selection.endDate.getTime(),
    );

    setTempCalendarFilterTitle(selectedRange?.label || 'Custom');
    setTempDateRange([ranges.selection]);
  };

  const handleApply = async () => {
    setDateRange(tempDateRange);
    const startDate = tempDateRange[0]?.startDate;
    const endDate = tempDateRange[0]?.endDate;

    setCalendarFilterTitle(tempCalendarFilterTitle);
    dispatch(
      RevenueOverview({
        start: startDate,
        end: endDate,
        merchantId: selectedMerchant,
      }),
    );
    dispatch(
      ErrorsOverview({
        start: startDate,
        end: endDate,
        merchantId: selectedMerchant,
      }),
    );
    dispatch(ReoccurringUsers({ start: startDate, end: endDate }));

    setShowCalendar(false);
  };

  const handleClear = () => {
    setDateRange(tempDateRange);
    setShowCalendar(false);
  };

  const handleSelectChange = (event) => {
    const merchantId = event.target.value;

    setSelectedMerchant(merchantId);

    const startDate = dateRange[0]?.startDate;
    const endDate = dateRange[0]?.endDate;

    dispatch(RevenueOverview({ start: startDate, end: endDate, merchantId }));
    dispatch(ErrorsOverview({ start: startDate, end: endDate, merchantId }));
  };

  return (
    <>
      <Box pt={{ base: '60px', md: '80px', xl: '80px' }}>
        {/* Net Revanue */}
        <Flex
          mt="6"
          gap="6"
          flexDirection={{ base: 'column-reverse', xl: 'row' }}
        >
          <NetRevenueGraph
            intervalValue={intervalValue}
            revenueGraphData={revenueGraphData}
            currency={currency}
            dashboardData={dashboardData}
          />
        </Flex>

        <Box
          mt="40px"
          bg="white"
          p="20px"
          pt="25px"
          borderRadius="20px"
          boxShadow="0px 0px 40px rgba(32, 57, 70, 0.06)"
        >
          <RevenueOverViewGraph
            adminDetails={adminDetails}
            handleSelectChange={handleSelectChange}
            selectedMerchant={selectedMerchant}
            currency={currency}
            merchantNameList={merchantNameList}
            revenueOverviewData={revenueOverviewData}
            showCalendar={showCalendar}
            setShowCalendar={setShowCalendar}
            tempDateRange={tempDateRange}
            setTempDateRange={setTempDateRange}
            dateRange={dateRange}
            setDateRange={setDateRange}
            calendarFilterTitle={calendarFilterTitle}
            handleApply={handleApply}
            handleClear={handleClear}
            handleSelect={handleSelect}
          />
        </Box>

        <Flex
          mt={{ base: '24px', md: '40px' }}
          gap={{ base: '16px', lg: '24px' }}
          flexWrap="wrap"
        >
          <RecurringuserGraph
            adminDetails={adminDetails}
            showTooltip={showTooltip}
            setShowTooltip={setShowTooltip}
            errorOverviewData={errorOverviewData}
            recurringUsers={recurringUsers}
          />
        </Flex>
      </Box>
    </>
  );
}
