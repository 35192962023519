import { Box, FormLabel, Input, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const CommonInput = ({
  labelText,
  textColor,
  brandStars,
  placeholder,
  register,
  errors,
  name,
  type,
  value,
  readOnly,
  isValidate = true,
  isEmailReadOnly,
}) => {
  return (
    <>
      <Box>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          {labelText}
          {isValidate && <Text color={brandStars}>*</Text>}
        </FormLabel>
        <Input
          type={type}
          isRequired
          variant="auth"
          fontSize="sm"
          placeholder={placeholder}
          fontWeight="500"
          size="lg"
          {...register}
          name={name}
          value={value}
          readOnly={readOnly}
          sx={{
            backgroundColor: isEmailReadOnly ? '#f5f5f5' : 'white',
            color: isEmailReadOnly ? '#888' : '#000',
            border: isEmailReadOnly ? '1px solid #ddd' : '1px solid #ccc',
            cursor: isEmailReadOnly ? 'not-allowed' : 'text',
          }}
        />
        {errors && (
          <p style={{ color: 'red', fontSize: '14px' }}>{errors?.message}</p>
        )}
      </Box>
    </>
  );
};

CommonInput.propTypes = {
  labelText: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  brandStars: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.object,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  isValidate: PropTypes.bool,
  isEmailReadOnly: PropTypes.bool,
};

export default CommonInput;
