import {
  failurePowerbankAdd,
  failurePowerbankDelete,
  failurePowerbankList,
  failurePowerbankView,
  requestPowerbankAdd,
  requestPowerbankDelete,
  requestPowerbankList,
  requestPowerbankView,
  responsePowerbankAdd,
  responsePowerbankDelete,
  responsePowerbankList,
  responsePowerbankView,
} from '../../slices/powerBankSlice/powerBankSlice';
import Swal from 'sweetalert2';
import requestApi from 'utils/Request';
import { POWERBANKLIST, POWERBANK } from 'utils/StaticApiEndpoints';

export const ListPowerBankData = (payload) => async (dispatch) => {
  try {
    dispatch(requestPowerbankList());
    const res = await requestApi.get(POWERBANKLIST, { params: payload });
    dispatch(responsePowerbankList(res));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failurePowerbankList(error?.message));
  }
};

export const AddPowerBankData = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestPowerbankAdd());
    const res = await requestApi.post(POWERBANK, payload);
    dispatch(responsePowerbankAdd(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failurePowerbankAdd(error?.message));
  }
};

////// detail PowerBank /////
export const ViewPowerBankData = (payload) => async (dispatch) => {
  try {
    dispatch(requestPowerbankView());
    const { data } = await requestApi.get(
      `${POWERBANK}/${payload?.id}`,
      payload,
    );
    dispatch(responsePowerbankView(data));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failurePowerbankView(error?.message));
  }
};

/////// edit merchnat ///
export const EditPowerBankData =
  (id, payload, callback) => async (dispatch) => {
    try {
      dispatch(requestPowerbankAdd());
      const res = await requestApi.put(`${POWERBANK}/${id}`, payload);
      dispatch(responsePowerbankAdd(res));
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error?.message,
      });
      dispatch(failurePowerbankAdd(error?.message));
    }
  };

///// delete PowerBank ///
export const DeletePowerBankData =
  (id, payload, callback) => async (dispatch) => {
    try {
      dispatch(requestPowerbankDelete());
      const res = await requestApi.delete(`${POWERBANK}/${id}`, payload);
      dispatch(responsePowerbankDelete(res));
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error?.message,
      });
      dispatch(failurePowerbankDelete(error?.message));
    }
  };
