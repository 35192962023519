// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tel-input {
  width: 100%;
}

.react-tel-input input {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  font-size: 0.875rem;
  padding-left: 45px; /* Adjust for flag icon space */
  border: 1px solid #e2e8f0; /* Same border as Chakra UI inputs */
  background-color: #f7fafc; /* Match background */
  color: #1a202c;
  font-weight: 500;
}

.react-tel-input .flag-dropdown {
  border: none;
  background: transparent;
}

.react-tel-input .flag-dropdown:hover {
  cursor: pointer;
}

.react-tel-input input:focus {
  outline: none;
  border-color: #805ad5; /* Match brand color */
  box-shadow: 0 0 0 1px #805ad5;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/PhoneInputCustom.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB,EAAE,+BAA+B;EACnD,yBAAyB,EAAE,oCAAoC;EAC/D,yBAAyB,EAAE,qBAAqB;EAChD,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,qBAAqB,EAAE,sBAAsB;EAC7C,6BAA6B;AAC/B","sourcesContent":[".react-tel-input {\n  width: 100%;\n}\n\n.react-tel-input input {\n  width: 100%;\n  height: 50px;\n  border-radius: 8px;\n  font-size: 0.875rem;\n  padding-left: 45px; /* Adjust for flag icon space */\n  border: 1px solid #e2e8f0; /* Same border as Chakra UI inputs */\n  background-color: #f7fafc; /* Match background */\n  color: #1a202c;\n  font-weight: 500;\n}\n\n.react-tel-input .flag-dropdown {\n  border: none;\n  background: transparent;\n}\n\n.react-tel-input .flag-dropdown:hover {\n  cursor: pointer;\n}\n\n.react-tel-input input:focus {\n  outline: none;\n  border-color: #805ad5; /* Match brand color */\n  box-shadow: 0 0 0 1px #805ad5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
