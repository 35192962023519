import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react';
import CommonInput from 'components/commonInput/CommonInput';
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../assets/css/PhoneInputCustom.css';
import { useColorModeVariables } from '../../../components/colorModeVarialbels/ColorModeValues';
import CommonSubmitBtn from '../../../components/commonInput/CommonSubmitBtn';
import PageLoader from 'components/wholePageLoader/PageLoader';
import CommonReactSelect from 'components/reactSelectCommon/CommonReactSelect';
import { RoleOption } from 'utils/StaticData';
import { yupResolver } from '@hookform/resolvers/yup';
import { userAdminSchema } from 'utils/CommonValidation';
import { AddAdminUserData } from 'reactRedux/thunks/adminUserThunk/adminUserThunk';
import { CloseIcon } from '@chakra-ui/icons';
import { FaPlus } from 'react-icons/fa';
import MerchantListModal from '../stationPages/MerchantListModal';
import { EditAdminUserData } from 'reactRedux/thunks/adminUserThunk/adminUserThunk';
import { ViewAdminUserData } from 'reactRedux/thunks/adminUserThunk/adminUserThunk';

const AdminUserAddEdit = () => {
  const { adminEditUserId } = useParams();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [selectedMerchants, setSelectedMerchants] = useState('');
  const [isRemove, setIsRemove] = useState(false);
  const [selectedMerchantName, setSelectedMerchantName] = useState('');
  const { textColor, brandStars } = useColorModeVariables();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isViewLoading, viewAdminUserDetails, isLoading } = useSelector(
    (state) => state.AdminUserSlice,
  );
  const {
    handleSubmit,
    setValue,
    register,
    control,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(
      userAdminSchema(selectedMerchants, !!adminEditUserId),
    ),
  });

  const handleRemoveMerchant = () => {
    setSelectedMerchants('');
    setIsRemove(true);
  };
  const onSubmit = (data) => {
    // eslint-disable-next-line no-unused-vars
    const { confirmPassword, roleId, ...rest } = data;
    let payload = {};

    if (adminEditUserId) {
      payload = {
        firstName: data.firstName,
        lastName: data.lastName,
      };
    } else {
      payload = {
        ...rest,
        roleId: roleId?.id || 0,
        ...(roleId?.id === 3 ? { merchantId: Number(data.merchantId) } : {}),
      };
    }

    if (adminEditUserId) {
      dispatch(
        EditAdminUserData(adminEditUserId, payload, () => {
          navigate('/admin/admin-users');
        }),
      );
    } else {
      dispatch(
        AddAdminUserData(payload, () => {
          navigate('/admin/admin-users');
        }),
      );
    }
  };

  useEffect(() => {
    if (adminEditUserId) {
      dispatch(ViewAdminUserData({ id: adminEditUserId }));
    }
  }, [dispatch, adminEditUserId]);

  useEffect(() => {
    if (viewAdminUserDetails && adminEditUserId) {
      setValue('firstName', viewAdminUserDetails?.firstName);
      setValue('lastName', viewAdminUserDetails?.lastName);
      setValue('email', viewAdminUserDetails?.email);
      const selectedRole = RoleOption?.find(
        (role) => role?.id === viewAdminUserDetails?.role,
      );
      setValue('roleId', selectedRole || null);

      if (viewAdminUserDetails?.role === 3) {
        setSelectedMerchants(viewAdminUserDetails?.merchantId || '');
        setSelectedMerchantName(viewAdminUserDetails?.merchantName || '');
        setValue('merchantId', viewAdminUserDetails?.merchantId);
      }
    }
  }, [viewAdminUserDetails, adminEditUserId, setValue]);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column" align="center" justify="center">
          <Card
            w="100%"
            boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
            borderRadius="20px"
            paddingInline="40px"
            paddingBlock="50px"
            overflowX={{ sm: 'scroll', lg: 'hidden' }}
          >
            {isViewLoading ? (
              <PageLoader />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormControl>
                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 2 }} // 1 column for mobile, 2 for tablets, 3 for desktop
                    spacing="24px"
                  >
                    {/* First Name */}
                    <CommonInput
                      type="text"
                      name="firstName"
                      labelText="First Name"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="First Name"
                      register={register('firstName')}
                      errors={errors?.firstName}
                      isValidate={true}
                    />

                    {/* Last Name */}
                    <CommonInput
                      type="text"
                      name="lastName"
                      labelText="Last Name"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Last Name"
                      register={register('lastName')}
                      errors={errors?.lastName}
                      isValidate={true}
                    />

                    {/* Role Option select */}
                    <Controller
                      name="roleId"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CommonReactSelect
                          label="Role"
                          name="roleId"
                          type="select"
                          options={RoleOption}
                          value={value}
                          onChange={(selectedRole) => {
                            onChange(selectedRole);
                            setSelectedMerchants('');
                          }}
                          register={register}
                          isRequired={true}
                          error={errors?.roleId}
                          getOptionLabel={(e) => e?.label}
                          getOptionValue={(e) => e?.id}
                          isDisabled={!!adminEditUserId}
                        />
                      )}
                    />

                    {/* Email */}
                    <CommonInput
                      type="email"
                      name="email"
                      labelText="Email"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Email"
                      register={register('email')}
                      errors={errors?.email}
                      isValidate={true}
                      readOnly={!!adminEditUserId}
                    />

                    {/* Password and Confirm-Password  */}
                    {/* {!adminEditUserId && (
                      <>
                        <CommonInput
                          type="password"
                          name="password"
                          labelText="Password"
                          textColor={textColor}
                          brandStars={brandStars}
                          placeholder="Password"
                          register={register('password')}
                          errors={errors?.password}
                          isValidate={true}
                        />

                        <CommonInput
                          type="password"
                          name="confirmPassword"
                          labelText="Confirm Password"
                          textColor={textColor}
                          brandStars={brandStars}
                          placeholder="Confirm Password"
                          register={register('confirmPassword')}
                          errors={errors?.confirmPassword}
                          isValidate={true}
                        />
                      </>
                    )} */}
                  </SimpleGrid>

                  {watch('roleId')?.id === 3 &&
                    !selectedMerchants &&
                    !adminEditUserId && (
                      <>
                        <SimpleGrid
                          columns={{ base: 1, md: 2, lg: 3 }}
                          spacing="24px"
                        >
                          <Button
                            type="button"
                            bg="reviveOrange"
                            mt="50px"
                            h="50"
                            color="primary"
                            borderRadius="12px"
                            _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
                            onClick={onOpen}
                          >
                            <Icon
                              as={FaPlus}
                              width="15px"
                              height="15px"
                              borderRadius="12px"
                              marginRight="5px"
                              color="inherit"
                            />
                            Add Merchant
                          </Button>
                        </SimpleGrid>
                        {errors.merchantId && (
                          <p style={{ color: 'red', marginTop: '5px' }}>
                            {errors.merchantId.message}
                          </p>
                        )}
                      </>
                    )}

                  {selectedMerchants && watch('roleId')?.id === 3 && (
                    <SimpleGrid
                      columns={{ base: 1, md: 2, lg: 3 }}
                      spacing="24px"
                    >
                      <Flex align="center" mt={2} marginTop={'50px'}>
                        <FormLabel flex={'none'} color={textColor}>
                          Merchant
                        </FormLabel>
                        <Input
                          value={
                            selectedMerchantName
                              ? selectedMerchantName
                              : selectedMerchants
                          }
                          readOnly
                          variant="auth"
                          fontSize="sm"
                          mb="2px"
                          fontWeight="500"
                          size="lg"
                        />
                        {!adminEditUserId && (
                          <IconButton
                            aria-label="Remove Merchant"
                            icon={<CloseIcon />}
                            size="sm"
                            ms={3}
                            onClick={handleRemoveMerchant}
                          />
                        )}
                      </Flex>
                    </SimpleGrid>
                  )}

                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing="24px"
                  >
                    <CommonSubmitBtn BTNtext="Submit" isLoading={isLoading} />
                  </SimpleGrid>

                  {watch('roleId')?.id === 3 && (
                    <MerchantListModal
                      isOpen={isOpen}
                      onClose={onClose}
                      setSelectedMerchants={setSelectedMerchants}
                      setIsRemove={setIsRemove}
                      isRemove={isRemove}
                      setSelectedMerchantName={setSelectedMerchantName}
                      setValue={setValue}
                    />
                  )}
                </FormControl>
              </form>
            )}
          </Card>
        </Flex>
      </Box>
    </>
  );
};

export default AdminUserAddEdit;
