import { Box, Card, Flex, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import MapView from 'components/detailMapData/MapView';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ViewStationData } from 'reactRedux/thunks/stationThunk/stationThunk';
import PageLoader from '../../../components/wholePageLoader/PageLoader';

const StationDetail = () => {
  const { stationDetailId } = useParams();
  const dispatch = useDispatch();
  const { viewStationDetails, isViewLoading } = useSelector(
    (state) => state.StationSlice,
  );

  useEffect(() => {
    if (stationDetailId) {
      dispatch(ViewStationData({ id: stationDetailId }));
    }
  }, [stationDetailId]);

  return (
    <>
      {isViewLoading ? (
        <PageLoader />
      ) : (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Flex direction="column" align="center" justify="center">
            {viewStationDetails ? (
              <>
                <Card
                  w="100%"
                  boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                  borderRadius="20px"
                  paddingInline="40px"
                  paddingBlock="40px"
                  overflowX={{ sm: 'scroll', lg: 'hidden' }}
                >
                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 2 }}
                    spacing="24px"
                  >
                    <Flex
                      align="center"
                      gap="10px"
                      paddingBlock={{ base: '0', md: '10px' }}
                      borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                    >
                      <Text fontWeight="normal" width="90px">
                        Station Id :
                      </Text>
                      <Text fontWeight="semibold">
                        {viewStationDetails.stationId}
                      </Text>
                    </Flex>
                    <Flex
                      align="center"
                      gap="10px"
                      paddingBlock={{ base: '0', md: '10px' }}
                    >
                      <Text fontWeight="normal" width="90px">
                        terminal Id :
                      </Text>
                      <Text fontWeight="semibold">
                        {viewStationDetails.terminalId}
                      </Text>
                    </Flex>

                    <GridItem colSpan={{ base: 1, lg: 2 }}>
                      <Text fontWeight="normal" mb={2}>
                        Coordinates :
                      </Text>
                      <MapView
                        coordinates={viewStationDetails?.coordinates || [0, 0]}
                      />
                    </GridItem>
                  </SimpleGrid>
                </Card>
              </>
            ) : (
              <Text>No station details found.</Text>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default StationDetail;
