import Swal from 'sweetalert2';
import {
  failureMerchantAdd,
  failureMerchantDelete,
  failureMerchantList,
  failureMerchantView,
  failureSyncDataMerchant,
  requestMerchantAdd,
  requestMerchantDelete,
  requestMerchantList,
  requestMerchantView,
  requestSyncDataMerchant,
  responseMerchantAdd,
  responseMerchantDelete,
  responseMerchantList,
  responseMerchantView,
  responseSyncDataMerchant,
} from '../../slices/merchantSlice/merchantSlice';
import {
  MERCHANT,
  MERCHANTLIST,
  SYNCDATAMERCHANT,
} from '../../../utils/StaticApiEndpoints';
import requestApi from 'utils/Request';

export const ListMerchantData = (payload) => async (dispatch) => {
  try {
    dispatch(requestMerchantList());
    const res = await requestApi.get(MERCHANTLIST, { params: payload });
    dispatch(responseMerchantList(res));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureMerchantList(error?.message));
  }
};

export const AddMerchantData = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestMerchantAdd());
    const res = await requestApi.post(MERCHANT, payload);
    dispatch(responseMerchantAdd(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureMerchantAdd(error?.message));
  }
};

////// detail merchant /////
export const ViewMerchantData = (payload) => async (dispatch) => {
  try {
    dispatch(requestMerchantView());
    const { data } = await requestApi.get(
      `${MERCHANT}/${payload?.id}`,
      payload,
    );
    dispatch(responseMerchantView(data));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureMerchantView(error?.message));
  }
};

/////// edit merchnat ///
export const EditMerchantData = (id, payload, callback) => async (dispatch) => {
  try {
    dispatch(requestMerchantAdd());
    const res = await requestApi.put(`${MERCHANT}/${id}`, payload);
    dispatch(responseMerchantAdd(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureMerchantAdd(error?.message));
  }
};

///// delete merchant ///
export const DeleteMerchnatData =
  (id, payload, callback) => async (dispatch) => {
    try {
      dispatch(requestMerchantDelete());
      const res = await requestApi.delete(`${MERCHANT}/${id}`, payload);
      dispatch(responseMerchantDelete(res));
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error?.message,
      });
      dispatch(failureMerchantDelete(error?.message));
    }
  };

export const SyncDataMerchant = (callback) => async (dispatch) => {
  try {
    dispatch(requestSyncDataMerchant());
    const response = await requestApi.get(`${SYNCDATAMERCHANT}`);
    dispatch(responseSyncDataMerchant(response));
    Swal.fire({
      icon: 'success',
      text: response.message,
    }).then(() => callback());
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureSyncDataMerchant(error?.message));
  }
};
