export const formatDate = (isoDateString) => {
  if (!isoDateString) return '';

  const date = new Date(isoDateString);
  const day = String(date.getDate()).padStart(2, '0'); // Ensure 2-digit day
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const formatCurrency = (amount, currency) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

// Function to get color dynamically
export const getColor = (key) => {
  const colors = {
    pin: '#00FFAA',
    transaction: '#051F2C',
    honor: '#008000',
    funds: '#FFA500',
  };
  return colors[key] || '#000'; // Default color if key is not found
};

// Function to get legend labels
export const getLabel = (key) => {
  const labels = {
    pin: 'Online or Offline Pin Required',
    transaction: 'Transaction not Allowed',
    honor: 'Do not honor',
    funds: 'Insufficient Funds',
  };
  return labels[key] || key;
};

// Function to check if the bar is the topmost in the stack
export const getDynamicRadius = (data, keysArray) => {
  return data.map((entry) => {
    // Find the topmost key with a non-zero value
    const topKey = keysArray.findLast((key) => entry[key] > 0);

    // Default to [0, 0, 0, 0] if no top key is found
    return keysArray.reduce((acc, key) => {
      acc[key] = key === topKey ? [10, 10, 0, 0] : [0, 0, 0, 0];
      return acc;
    }, {});
  });
};

export const isTopBar = (keysArray, currentIndex) => {
  console.log(keysArray, currentIndex, "====>")
  return currentIndex === keysArray.length - 1; // Last element is at the top of the stack
};

export const CustomLabel = ({ viewBox, value }) => {
  const { cx, cy } = viewBox;
  return (
    <text
      x={cx}
      y={cy}
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={40}
      fontWeight="bold"
      fill="#0D1B2A"
    >
      {value}
    </text>
  );
};
