import {} from '../../slices/userSlice/userSlice';
import requestApi from 'utils/Request';
import Swal from 'sweetalert2';
import { requestAdminUserList } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { responseAdminUserList } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { failureAdminUserList } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { ADMINUSERLIST } from 'utils/StaticApiEndpoints';
import { requestAdminUserDelete } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { responseAdminUserDelete } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { failureAdminUserDelete } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { ADMINUSERDELETE } from 'utils/StaticApiEndpoints';
import { requestAdminUserAdd } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { responseAdminUserAdd } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { failureAdminUserAdd } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { ADMINADDUSER } from 'utils/StaticApiEndpoints';
import { ADMINUSEREDIT } from 'utils/StaticApiEndpoints';
import { requestAdminUserView } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { responseAdminUserView } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { failureAdminUserView } from 'reactRedux/slices/adminUserSlice/adminUserSlice';
import { ADMINDETAILUSER } from 'utils/StaticApiEndpoints';

//////// User List //////////
export const ListAdminUserData = (payload) => async (dispatch) => {
  try {
    dispatch(requestAdminUserList());
    const res = await requestApi.get(ADMINUSERLIST, { params: payload });
    dispatch(responseAdminUserList(res));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureAdminUserList(error?.message));
  }
};

//////// User Add //////
export const AddAdminUserData = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestAdminUserAdd());
    const res = await requestApi.post(ADMINADDUSER, payload);
    dispatch(responseAdminUserAdd(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureAdminUserAdd(error?.message));
  }
};

//////// User Detail /////
export const ViewAdminUserData = (payload) => async (dispatch) => {
  try {
    dispatch(requestAdminUserView());
    const { data } = await requestApi.get(
      `${ADMINDETAILUSER}/${payload?.id}`,
      payload,
    );
    dispatch(responseAdminUserView(data));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureAdminUserView(error?.message));
  }
};

/////// User Edit //////
export const EditAdminUserData =
  (id, payload, callback) => async (dispatch) => {
    try {
      dispatch(requestAdminUserAdd());
      const res = await requestApi.put(`${ADMINUSEREDIT}/${id}`, payload);
      dispatch(responseAdminUserAdd(res));
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error?.message,
      });
      dispatch(failureAdminUserAdd(error?.message));
    }
  };

/////// Admin User Delete //////
export const DeleteAdminUserData =
  (id, payload, callback) => async (dispatch) => {
    try {
      dispatch(requestAdminUserDelete());
      const res = await requestApi.delete(`${ADMINUSERDELETE}/${id}`, payload);
      dispatch(responseAdminUserDelete(res));
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error?.message,
      });
      dispatch(failureAdminUserDelete(error?.message));
    }
  };
