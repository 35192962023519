import {
  Box,
  Button,
  Flex,
  Card,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  SimpleGrid,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { stationAddEditValidation } from '../../../utils/CommonValidation';
import {
  AddStationData,
  EditStationData,
  ViewStationData,
} from '../../../reactRedux/thunks/stationThunk/stationThunk';
import GoogleLatLng from './GoogleLatLng';
import MerchantListModal from './MerchantListModal';
import { CloseIcon } from '@chakra-ui/icons';
import { FaPlus } from 'react-icons/fa';
import { Icon } from '@chakra-ui/react';
import { useColorModeVariables } from '../../../components/colorModeVarialbels/ColorModeValues'
import CommonInput from "components/commonInput/CommonInput";
import CommonSubmitBtn from "../../../components/commonInput/CommonSubmitBtn";



const StationAddEdit = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { textColor, brandStars } = useColorModeVariables()
  const { editStationId } = useParams();
  const [coordinates, setCoordinates] = useState({ lat: 23.01052, lng: 72.51129 })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [initialAddress, setInitialAddress] = useState('')
  const [selectedMerchants, setSelectedMerchants] = useState("");
  const [selectedMerchantName, setSelectedMerchantName] = useState("");
  const [isRemove, setIsRemove] = useState(false)
  const { isLoading, viewStationDetails } = useSelector((state) => state.StationSlice);
  const { handleSubmit, reset, setValue, register, formState: { errors }, control } = useForm({ mode: "onChange", resolver: yupResolver(stationAddEditValidation) })

  const onSubmit = (data) => {
    const { stationId, ...restData } = data;
    const transformedData = {
      ...restData,
      coordinates: [coordinates.lng, coordinates.lat],
      merchantId: selectedMerchants,
    };
    if (editStationId) {
      dispatch(
        EditStationData(editStationId, transformedData, () => {
          navigate('/admin/stations');
        }),
      );
    } else {
      dispatch(
        AddStationData({ ...transformedData, stationId }, () => {
          navigate('/admin/stations');
        }),
      );
    }
  };

  useEffect(() => {
    if (editStationId) {
      dispatch(ViewStationData({ id: editStationId }));
    }
  }, [editStationId]);

  const fetchAddress = async (coordinates) => {
    try {
      const response = await fetch(
        `https://cors.stage.eugeniuses.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates[1]},${coordinates[0]}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setInitialAddress(data.results[0].formatted_address); // Set the initial address
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  useEffect(() => {
    if (viewStationDetails && editStationId) {
      setValue('stationId', viewStationDetails?.stationId);
      setValue('terminalId', viewStationDetails?.terminalId);
      setSelectedMerchants(viewStationDetails?.merchant?.merchantId || '');
      setSelectedMerchantName(viewStationDetails?.merchant?.name || '');
      if (
        viewStationDetails.coordinates &&
        viewStationDetails.coordinates.length === 2
      ) {
        setCoordinates({
          lat: viewStationDetails.coordinates[1],
          lng: viewStationDetails.coordinates[0],
        });
        fetchAddress(viewStationDetails.coordinates);
      }
    }
  }, [viewStationDetails, editStationId, setValue]);

  const handleRemoveMerchant = () => {
    setSelectedMerchants('');
    setIsRemove(true);
  };

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} >
        <Flex direction="column" align="center" justify="center">
          <Card
            w="100%"
            boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
            borderRadius="20px"
            paddingInline="40px"
            paddingBlock="50px"
            overflowX={{ sm: 'scroll', lg: 'hidden' }}
          >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl>
                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 3 }}
                  spacing="24px"
                >

                  {/* station ID */}
                  <CommonInput
                    type="text"
                    name="stationId"
                    labelText="Station Id"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Station Id"
                    register={register("stationId")}
                    errors={errors?.stationId}
                  />

                  {/* terminal ID */}
                  <CommonInput
                    type="text"
                    name="terminalId"
                    labelText="Terminal Id"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Terminal Id"
                    register={register("terminalId")}
                    errors={errors?.terminalId}
                  />
                  <GoogleLatLng coordinates={coordinates} setCoordinates={setCoordinates} initialAddress={initialAddress} />
                </SimpleGrid>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="24px">
                  {!selectedMerchants ? (
                    <Button
                      type="button"
                      bg="reviveOrange"
                      mt="50px"
                      h="50"
                      color="primary"
                      borderRadius="12px"
                      _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
                      onClick={onOpen}
                    >
                      <Icon
                        as={FaPlus}
                        width="15px"
                        height="15px"
                        borderRadius="12px"
                        marginRight="5px"
                        color="inherit"
                      />
                      Add Merchant
                    </Button>
                  ) : (
                    <Flex align="center" mt={2} marginTop={'50px'}>
                      <FormLabel flex={'none'} color={textColor}>
                        Merchant
                      </FormLabel>
                      <Input
                        value={
                          selectedMerchantName
                            ? selectedMerchantName
                            : selectedMerchants
                        }
                        readOnly
                        variant="auth"
                        fontSize="sm"
                        mb="2px"
                        fontWeight="500"
                        size="lg"
                      />
                      <IconButton
                        aria-label="Remove Merchant"
                        icon={<CloseIcon />}
                        size="sm"
                        ms={3}
                        onClick={handleRemoveMerchant}
                      />
                    </Flex>
                  )}
                </SimpleGrid>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="24px" >
                  <CommonSubmitBtn BTNtext="Submit" isLoading={isLoading}/>
                </SimpleGrid>
                <MerchantListModal
                  isOpen={isOpen}
                  onClose={onClose}
                  setSelectedMerchants={setSelectedMerchants}
                  setIsRemove={setIsRemove}
                  isRemove={isRemove}
                  setSelectedMerchantName={setSelectedMerchantName}
                />
              </FormControl>
            </form>
          </Card>
        </Flex>
      </Box>
    </>
  );
};

export default StationAddEdit;
