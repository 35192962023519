import { Box, Card, Flex, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import MapView from 'components/detailMapData/MapView';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ViewPowerBankData } from 'reactRedux/thunks/powerBankThunk/powerBankThunk';
import PageLoader from '../../../components/wholePageLoader/PageLoader';

const PowerBankDetails = () => {
  const { powerbanksDetailId } = useParams();
  const dispatch = useDispatch();
  const { isLoading, viewPowerbankDetails, isViewLoading } = useSelector(
    (state) => state.PowerBankSlice,
  );
  useEffect(() => {
    if (powerbanksDetailId) {
      dispatch(ViewPowerBankData({ id: powerbanksDetailId }));
    }
  }, [powerbanksDetailId]);

  return (
    <>
      {isViewLoading ? (
        <PageLoader />
      ) : (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Flex direction="column" align="center" justify="center">
            {viewPowerbankDetails ? (
              <>
                <Card
                  w="100%"
                  boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                  borderRadius="20px"
                  paddingInline="40px"
                  paddingBlock="40px"
                  overflowX={{ sm: 'scroll', lg: 'hidden' }}
                >
                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 2 }}
                    spacing="24px"
                  >
                    <Flex
                      align="center"
                      gap="10px"
                      paddingBlock={{ base: '0', md: '10px' }}
                      borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                    >
                      <Text fontWeight="normal">Powerbank Id :</Text>
                      <Text fontWeight="semibold">
                        {viewPowerbankDetails.powerbankId}
                      </Text>
                    </Flex>
                    <Flex
                      align="center"
                      gap="10px"
                      paddingBlock={{ base: '0', md: '10px' }}
                    >
                      <Text fontWeight="normal">terminal Id :</Text>
                      <Text fontWeight="semibold">
                        {viewPowerbankDetails.terminalId}
                      </Text>
                    </Flex>

                    <GridItem colSpan={{ base: 1, lg: 2 }}>
                      <Text fontWeight="normal" mb={2}>
                        Coordinates :
                      </Text>
                      <MapView
                        coordinates={
                          viewPowerbankDetails?.coordinates || [0, 0]
                        }
                      />
                    </GridItem>
                  </SimpleGrid>
                </Card>
              </>
            ) : (
              <Text>No powerbank details found.</Text>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default PowerBankDetails;
