// // Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import routes from 'routes.js';

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);
    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  }, []);

  const { secondary, message } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue('navy.700', 'white');
  let secondaryText = useColorModeValue('gray.700', 'white');
  let navbarPosition = 'fixed';
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBg = useColorModeValue(
    'rgba(244, 247, 254, 0.2)',
    'rgba(11,20,55,0.5)',
  );
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '15px';
  let gap = '0px';

  const changeNavbar = () => {
    setScrolled(window.scrollY > 1);
  };

  const getBreadcrumbs = () => {
    const pathSegments = location.pathname.split('/').filter(Boolean); // Split the URL into segments
    let accumulatedPath = '';
    const breadcrumbs = [];

    // Iterate over the path segments to find the matching route
    pathSegments.forEach((segment, index) => {
      accumulatedPath += `/${segment}`;

      // Find the exact matching route or dynamic match
      const matchingRoute = routes.find((route) => {
        const routeSegments = route.path.split('/').filter(Boolean);

        // Exact match: Full path matches
        const isExactMatch = accumulatedPath === `${route.layout}${route.path}`;

        // Dynamic match: Account for parameters like :editUserId, :merchantDetailId, etc.
        const isDynamicMatch =
          routeSegments.length === pathSegments.length &&
          routeSegments.every(
            (seg, i) => seg.startsWith(':') || seg === pathSegments[i],
          );

        // Check if the route matches either dynamically or exactly
        return (isExactMatch || isDynamicMatch) && route.layout === '/admin';
      });

      // If we find a matching route, push the route name and check for dynamic parameters
      if (matchingRoute) {
        breadcrumbs.push(matchingRoute.name);

        // Check if there are dynamic parameters in the route path
        const routeSegments = matchingRoute.path.split('/').filter(Boolean);
        routeSegments.forEach((routeSegment, idx) => {
          // If the segment is dynamic (e.g., :userDetailId), push the actual value
          if (routeSegment.startsWith(':')) {
            breadcrumbs.push(pathSegments[idx]); // Push the dynamic parameter value (e.g., '90')
          }
        });
      }
    });

    return breadcrumbs.length > 0 ? breadcrumbs : []; // Return only the matched breadcrumbs
  };

  const breadcrumbs = getBreadcrumbs();

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      minH="75px"
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
      px={{
        sm: paddingX,
        md: '10px',
      }}
      ps={{
        xl: '12px',
      }}
      pt="8px"
      top={{ base: '12px', md: '16px', lg: '20px', xl: '20px' }}
      w={{
        base: 'calc(100vw - 6%)',
        md: 'calc(100vw - 8%)',
        lg: 'calc(100vw - 6%)',
        xl: 'calc(100vw - 350px)',
        '2xl': 'calc(100vw - 365px)',
      }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
        mb={gap}
      >
        <Box mb={{ sm: '8px', md: '0px' }}>
          <Breadcrumb>
            <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
              {/* Wrap the "Pages" link with RouterLink component */}
              <RouterLink to="/admin/dashboard" color={secondaryText}>
                Dashboard
              </RouterLink>
            </BreadcrumbItem>
            {breadcrumbs?.map((breadcrumb, index) => (
              <BreadcrumbItem
                key={index}
                color={secondaryText}
                fontSize="sm"
                mb="5px"
              >
                {index === breadcrumbs.length - 1 ? (
                  <span>{breadcrumb}</span>
                ) : (
                  <RouterLink
                    to={`/admin/${breadcrumb.toLowerCase().replace(/ /g, '-')}`}
                    color={secondaryText}
                  >
                    {breadcrumb}
                  </RouterLink>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
          {/* Here we create navbar brand, based on route name */}
          <Link
            color={mainText}
            to={
              breadcrumbs.length > 0
                ? `/admin/${breadcrumbs[breadcrumbs.length - 1]
                    .toLowerCase()
                    .replace(/ /g, '-')}`
                : ''
            }
            // to={`/admin/${breadcrumbs[breadcrumbs.length - 1].toLowerCase().replace(/ /g, '-')}`}
            bg="inherit"
            borderRadius="inherit"
            fontWeight="medium"
            fontSize="34px"
            _hover={{ color: { mainText } }}
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            {breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs?.length - 1] : ''}
            {/* {breadcrumbs[breadcrumbs.length - 1]} */}
          </Link>
        </Box>
        <Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            scrolled={scrolled}
          />
        </Box>
      </Flex>
      {secondary ? <Text color="white">{message}</Text> : null}
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
