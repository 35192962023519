import { createSlice } from '@reduxjs/toolkit';

const UserSlice = createSlice({
  name: 'UserSlice',
  initialState: {
    isLoading: false,
    isViewLoading: false,
    userListData: [],
    isError: null,
    viewUserDetails: null,
  },
  reducers: {
    /////// User list start //////

    requestUserList: (state) => {
      return { ...state, isLoading: true };
    },
    responseUserList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        userListData: action?.payload?.data,
      };
    },
    failureUserList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
      };
    },

    /////// User Add-Edit //////
    requestUserAdd: (state) => {
      return { ...state, isLoading: true };
    },
    responseUserAdd: (state, action) => {
      return { ...state, isLoading: false };
    },
    failureUserAdd: (state, action) => {
      return { ...state, isLoading: false, isError: action?.payload };
    },

    ////////// User View /////////
    requestUserView: (state) => {
      return { ...state, isViewLoading: true };
    },
    responseUserView: (state, action) => {
      return {
        ...state,
        isViewLoading: false,
        viewUserDetails: action.payload,
      };
    },
    failureUserView: (state, action) => {
      return { ...state, isViewLoading: false, isError: action?.payload };
    },

    ////// User Delete ////
    requestUserDelete: (state) => {
      return { ...state, isLoading: true };
    },
    responseUserDelete: (state, action) => {
      return { ...state, isLoading: false };
    },
    failureUserDelete: (state, action) => {
      return { ...state, isLoading: false, isError: action?.payload };
    },
  },
});

export const {
  failureUserList,
  requestUserList,
  responseUserList,
  failureUserAdd,
  requestUserAdd,
  responseUserAdd,
  failureUserView,
  requestUserView,
  responseUserView,
  failureUserDelete,
  requestUserDelete,
  responseUserDelete,
} = UserSlice.actions;
export default UserSlice.reducer;
