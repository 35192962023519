import { Box, Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

const PageLoader = () => {
  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column" align="center" justify="center">
          <Spinner size="lg" color="teal.500" />
        </Flex>
      </Box>
    </>
  );
};

export default PageLoader;
