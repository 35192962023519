import React from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import illustration from 'assets/img/auth/auth.png';
import DefaultAuth from 'layouts/auth/Default';
import { useForm } from 'react-hook-form';
import { forgotPasswordValidationSchema } from 'utils/CommonValidation';
import { Link } from 'react-router-dom';
import { ForgotPasswordAuth } from 'reactRedux/thunks/authThunk/authThunk';
import { useDispatch, useSelector } from 'react-redux';

const ForgotPassword = () => {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('secondary', 'brand.400');
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.authSlice);

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  const onSubmit = (data) => {
    dispatch(
      ForgotPasswordAuth(data, () => {
        reset();
      }),
    );
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading
            color={textColor}
            fontSize="36px"
            mb="10px"
            fontFamily="Montserrat"
          >
            Forgot Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email to receive password reset instructions.
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormControl>
              <Box mb="24px">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  type="email"
                  placeholder="mail@simmmple.com"
                  mb="2px"
                  fontWeight="500"
                  size="lg"
                  {...register('email')}
                  name="email"
                />
                {errors?.email && (
                  <p style={{ color: 'red', fontSize: '14px' }}>
                    {errors?.email?.message}
                  </p>
                )}
              </Box>
              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                bg="primary"
                color="white"
                _hover={{ bg: 'primary', opacity: 0.8 }}
                w="100%"
                h="50"
                mb="24px"
              >
                {isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  'Request for Reset Password'
                )}
              </Button>
              <Box textAlign="right">
                <Text
                  fontSize="sm"
                  color="blue.500"
                  _hover={{ textDecoration: 'underline' }}
                >
                  <Link to="/auth/sign-in">Back to Sign In</Link>
                </Text>
              </Box>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default ForgotPassword;
