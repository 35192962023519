import {
  failureStationAdd,
  failureStationDelete,
  failureStationList,
  failureStationView,
  requestStationAdd,
  requestStationDelete,
  requestStationList,
  requestStationView,
  responseStationAdd,
  responseStationDelete,
  responseStationList,
  responseStationView,
} from '../../slices/stationSlice/stationSlice';
import Swal from 'sweetalert2';
import requestApi from 'utils/Request';
import { STATIONLIST } from 'utils/StaticApiEndpoints';
import { STATION } from 'utils/StaticApiEndpoints';

export const ListStationData = (payload) => async (dispatch) => {
  try {
    dispatch(requestStationList());
    const res = await requestApi.get(STATIONLIST, { params: payload });
    dispatch(responseStationList(res));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureStationList(error?.message));
  }
};

export const AddStationData = (payload, callback) => async (dispatch) => {
  try {
    dispatch(requestStationAdd());
    const res = await requestApi.post(STATION, payload);
    dispatch(responseStationAdd(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureStationAdd(error?.message));
  }
};

////// detail Station /////
export const ViewStationData = (payload) => async (dispatch) => {
  try {
    dispatch(requestStationView());
    const { data } = await requestApi.get(`${STATION}/${payload?.id}`, payload);
    dispatch(responseStationView(data));
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureStationView(error?.message));
  }
};

/////// edit merchnat ///
export const EditStationData = (id, payload, callback) => async (dispatch) => {
  try {
    dispatch(requestStationAdd());
    const res = await requestApi.put(`${STATION}/${id}`, payload);
    dispatch(responseStationAdd(res));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error?.message,
    });
    dispatch(failureStationAdd(error?.message));
  }
};

///// delete Station ///
export const DeleteStationData =
  (id, payload, callback) => async (dispatch) => {
    try {
      dispatch(requestStationDelete());
      const res = await requestApi.delete(`${STATION}/${id}`, payload);
      dispatch(responseStationDelete(res));
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error?.message,
      });
      dispatch(failureStationDelete(error?.message));
    }
  };
