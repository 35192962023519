import axios from 'axios';

const requestApi = axios.create({
  // baseURL: import.meta.env.REACT_APP_API_HOST,
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    accept: '*/*',
    language: 'en',
    'Access-Control-Allow-Origin': '*',
  },
});

requestApi.interceptors.request.use(
  (config) => {
    const token =
      JSON.parse(localStorage.getItem('revive_admin__token')) || null;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

requestApi.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (response.status === 201 || response) {
      return Promise.resolve(data);
    } else {
      return Promise.reject({
        status: data?.status,
        // eslint-disable-next-line no-undef
        message: errors?.message,
      });
    }
  },
  async (error) => {
    if (error?.response?.status === 422) {
      return await Promise.reject({
        status: 422,
        message: error?.response?.data?.message || 'Validation failed',
        // fieldValidationError: error?.response?.data?.errors,
      });
    } else if (error?.response?.status === 403) {
      return Promise.reject({
        status: 403,
        message: error?.response?.data?.message || 'Forbidden access',
      });
    } else if (error?.response?.status === 400) {
      return Promise.reject({
        status: 400,
        message: error?.response?.data?.message || 'Incorrect input value',
      });
    } else if (error?.response?.status === 405) {
      return Promise.reject({
        status: 405,
        message: error?.response?.data?.message || 'Incorrect input value',
      });
    } else if (error?.response?.status === 401) {
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      return Promise.reject({
        status: 401,
        message:
          error?.response?.data?.message ||
          'Your Session is expired. Please Login again',
      });
    } else {
      const response = {
        message:
          error?.response?.data?.error?.message || 'Something went to wrong.',
        status: 404,
      };
      return Promise.reject(response);
    }
  },
);

export default requestApi;
