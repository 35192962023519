import React from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const CommonReactSelect = ({
  label,
  isRequired,
  type,
  options,
  value,
  onChange,
  error,
  register,
  name,
  getOptionLabel,
  getOptionValue,
  isMulti,
  isDisabled,
}) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500">
        {label}
        {isRequired && <span style={{ color: 'red' }}>*</span>}
      </FormLabel>
      {type === 'select' ? (
        <Select
          isMulti={isMulti ? isMulti : false}
          options={options}
          value={value}
          onChange={onChange}
          placeholder={`Select ${label}`}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          styles={{
            control: (base, state) => ({
              ...base,
              width: '100%',
              height: '48px',
              borderRadius: '12px',
              fontSize: '0.875rem',
              border: state.isFocused
                ? '1px solid #e0e5f2'
                : '1px solid #e0e5f2',
              backgroundColor: '#fff',
              color: '#203946',
              fontWeight: '500',
              boxShadow: state.isFocused ? '0 0 0 1px #e0e5f2' : 'none',
            }),
            placeholder: (base) => ({
              ...base,
              paddingLeft: '10px',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: '#203946',
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: '10px',
            }),
            option: (base, { isFocused, isSelected }) => ({
              ...base,
              backgroundColor: isSelected
                ? '#35505e'
                : isFocused
                  ? '#f1f1f1'
                  : '#fff',
              color: isSelected ? '#fff' : '#000',
              cursor: 'pointer',
            }),
          }}
          isDisabled={isDisabled}
        />
      ) : (
        <Input
          type={type}
          variant="auth"
          fontSize="sm"
          placeholder={label}
          mb="24px"
          fontWeight="500"
          size="lg"
          {...register(name)}
        />
      )}
      {error && <p style={{ color: 'red' }}>{error.message}</p>}
    </FormControl>
  );
};

CommonReactSelect.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  type: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default CommonReactSelect;
