import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  SimpleGrid,
  Spinner
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import CommonInput from "components/commonInput/CommonInput";
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useColorModeVariables } from "../../../components/colorModeVarialbels/ColorModeValues";
import { AddMerchantData, EditMerchantData, ViewMerchantData } from "../../../reactRedux/thunks/merchnatThunk/merchantThunk";
import { merchantAddEditValidation } from "../../../utils/CommonValidation";
import CommonSubmitBtn from "components/commonInput/CommonSubmitBtn";


const MerchantAddEdit = () => {
  const { editmerchantId } = useParams();
  const { textColor, brandStars } = useColorModeVariables();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isLoading, viewMerchantDetails } = useSelector((state) => state.MerchantSlice);
  const { handleSubmit, reset, setValue, register, formState: { errors }, control } = useForm({ mode: "onChange", resolver: yupResolver(merchantAddEditValidation) })
  const onSubmit = (data) => {
    const stationIdArray = data?.stationIds?.map(
      (station) => station?.stationId,
    );
    const { merchantId, stationIds, ...payload } = data;
    const editPayload = {
      ...payload,
      stationId: stationIdArray,
    };
    if (editmerchantId) {
      dispatch(
        EditMerchantData(editmerchantId, editPayload, () => {
          navigate('/admin/merchants');
        }),
      );
    } else {
      dispatch(
        AddMerchantData(data, () => {
          navigate('/admin/merchants');
        }),
      );
    }
  };

  useEffect(() => {
    if (editmerchantId) {
      dispatch(ViewMerchantData({ id: editmerchantId }));
    }
  }, [editmerchantId]);

  useEffect(() => {
    if (viewMerchantDetails && editmerchantId) {
      setValue('commission', viewMerchantDetails?.commission);
      setValue('daysLimit', viewMerchantDetails?.daysLimit);
      setValue('deposit', viewMerchantDetails?.deposit);
      setValue('freeDuration', viewMerchantDetails?.freeDuration);
      setValue('maxPrice', viewMerchantDetails?.maxPrice);
      // setValue("merchantId", viewMerchantDetails?.merchantId);
      setValue('name', viewMerchantDetails?.name);
      setValue('price', viewMerchantDetails?.price);
    }
  }, [viewMerchantDetails, editmerchantId, setValue]);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column" align="center" justify="center">
          <Card
            w="100%"
            boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
            borderRadius="20px"
            paddingInline="40px"
            paddingBlock="50px"
            overflowX={{ sm: 'scroll', lg: 'hidden' }}
          >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="24px">
                  {/* Merchant Name */}
                  <CommonInput
                    type="text"
                    name="name"
                    labelText="Merchant Name"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Merchant Name"
                    register={register("name")}
                    errors={errors?.name}
                  />

                  {/* Free Duration */}
                  <CommonInput
                    type="number"
                    name="freeDuration"
                    labelText="Free Duration"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Free Duration"
                    register={register("freeDuration")}
                    errors={errors?.freeDuration}
                  />

                  {/* Price */}
                  <CommonInput
                    type="number"
                    name="price"
                    labelText="Price"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Price"
                    register={register("price")}
                    errors={errors?.price}
                  />

                  {/* Deposit */}
                  <CommonInput
                    type="number"
                    name="deposit"
                    labelText="Deposit"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Deposit"
                    register={register("deposit")}
                    errors={errors?.deposit}
                  />

                  {/* commission */}
                  <CommonInput
                    type="number"
                    name="commission"
                    labelText="Commission"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Commission"
                    register={register("commission")}
                    errors={errors?.commission}
                  />

                  {/* Max Price */}
                  <CommonInput
                    type="number"
                    name="maxPrice"
                    labelText="Max Price"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Max Price"
                    register={register("maxPrice")}
                    errors={errors?.maxPrice}
                  />

                  {/* Days Limit */}
                  <CommonInput
                    type="number"
                    name="daysLimit"
                    labelText="Days Limit"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Days Limit"
                    register={register("daysLimit")}
                    errors={errors?.daysLimit}
                  />
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="24px">
                  <CommonSubmitBtn BTNtext="Submit" isLoading={isLoading} />
                </SimpleGrid>
              </FormControl>
            </form>
          </Card>
        </Flex>
      </Box>
    </>
  );
};

export default MerchantAddEdit;
