import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Center,
  Text,
  Flex,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import PropTypes from 'prop-types';

function CommonTable({
  columns,
  data = [],
  excludes = [],
  getfilter,
  isLoading = false,
  isLink = false,
  parentLink = '/',
  keyValue,
  rowclick = [],
  tableTitle = '',
}) {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    sortBy: '',
    sortKey: '',
  });
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Card>
      <Flex flexDirection="column" w="100%" overflowX="auto">
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
            color="secondaryGray.900"
          >
            {tableTitle}
          </Text>
        </Flex>
        <Table variant="simple">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    borderColor="#e9ecef"
                    fontFamily="Montserrat"
                    key={header.id}
                    onClick={() => {
                      if (!excludes.includes(header.id)) {
                        setFilter((pre) => {
                          if (pre.sortKey === header.id) {
                            return {
                              ...pre,
                              sortBy:
                                pre.sortBy === 'Ascending'
                                  ? 'Descending'
                                  : 'Ascending',
                            };
                          }
                          return {
                            ...pre,
                            sortKey: header.id,
                            sortBy: 'Ascending',
                          };
                        });
                        if (typeof getfilter === 'function') {
                          getfilter(
                            filter.sortKey === header.id
                              ? {
                                  ...filter,
                                  sortBy:
                                    filter.sortBy === 'Ascending'
                                      ? 'Descending'
                                      : 'Ascending',
                                }
                              : {
                                  ...filter,
                                  sortKey: header.id,
                                  sortBy: 'Ascending',
                                },
                          );
                        }
                      }
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    <span className="sort-body">
                      {filter.sortBy !== '' && filter.sortKey === header.id ? (
                        filter.sortBy === 'Ascending' ? (
                          <i className="icon-backarrow active"></i>
                        ) : (
                          <i className="icon-backarrow down active"></i>
                        )
                      ) : null}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td colSpan={columns.length}>
                  <Center>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : data?.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      onClick={() => {
                        if (isLink && !rowclick?.includes(cell.column?.id)) {
                          navigate(`${parentLink}/${row.original?.[keyValue]}`);
                        }
                      }}
                      className={isLink ? 'cursor-pointer' : ''}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={columns.length}>
                  <Center>
                    <Text>No Data Available</Text>
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Flex>
    </Card>
  );
}

CommonTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  excludes: PropTypes.array,
  getfilter: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isLink: PropTypes.bool,
  parentLink: PropTypes.string,
  keyValue: PropTypes.string.isRequired,
  rowclick: PropTypes.array,
  tableTitle: PropTypes.string,
};

export default memo(CommonTable);
