import React from 'react';
import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  IconButton,
  SimpleGrid,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useColorModeVariables } from 'components/colorModeVarialbels/ColorModeValues';
import CommonInput from 'components/commonInput/CommonInput';
import CommonSubmitBtn from 'components/commonInput/CommonSubmitBtn';
import PasswordInput from 'components/passwordInputs/PasswordInput';
import PageLoader from 'components/wholePageLoader/PageLoader';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateProfile } from 'reactRedux/thunks/authThunk/authThunk';
import { editProfileValidationSchema } from 'utils/CommonValidation';
import { EditProfile } from 'reactRedux/thunks/authThunk/authThunk';
import { CloseIcon } from '@chakra-ui/icons';

export default function Overview() {
  const dispatch = useDispatch();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [isEditData, setIsEditData] = useState(true);
  const { adminDetails, isLoading, isEditProfileLoading } = useSelector(
    (state) => state.authSlice,
  );
  const { textColor, brandStars } = useColorModeVariables();
  const {
    handleSubmit,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(editProfileValidationSchema(showChangePassword)),
  });

  useEffect(() => {
    dispatch(UpdateProfile());
  }, [dispatch]);

  useEffect(() => {
    if (adminDetails) {
      setValue('firstName', adminDetails?.firstName);
      setValue('lastName', adminDetails?.lastName);
      setValue('email', adminDetails?.email);
    }
  }, [adminDetails, setValue, isEditData]);

  const onSubmit = (data) => {
    delete data.email;

    if (data.newPassword) {
      data.password = data.newPassword;
      delete data.newPassword;
      delete data.confirmPassword;
    } else if (data.newPassword === '') {
      delete data.newPassword;
      delete data.confirmPassword;
    }

    dispatch(
      EditProfile(data, () => {
        setIsEditData(true);
        setShowChangePassword(false);
      }),
    );
  };

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '78px' }}>
        <Flex justify="flex-end" mb={4} gap="20px">
          {/* {isEditData && ( */}
          <Box
            onClick={() => {
              setIsEditData(!isEditData);
              setShowChangePassword(false);
            }}
          >
            <Button
              type="button"
              bg="reviveOrange"
              mb="20px"
              h="50"
              color="primary"
              borderRadius="12px"
              _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
              onClick={() => setShowChangePassword(true)}
            >
              {isEditData ? 'Update Profile' : 'Cancel'}
            </Button>
          </Box>
          {/* )} */}
        </Flex>
        <Flex direction="column" align="center" justify="center">
          <Card
            w="100%"
            boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
            borderRadius="20px"
            paddingInline="40px"
            paddingBlock="50px"
            overflowX={{ sm: 'scroll', lg: 'hidden' }}
          >
            {isLoading ? (
              <PageLoader />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormControl>
                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }} // 1 column for mobile, 2 for tablets, 3 for desktop
                    spacing="24px"
                  >
                    {/* First Name */}
                    <CommonInput
                      type="text"
                      name="firstName"
                      labelText="First Name"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="First Name"
                      register={register('firstName')}
                      errors={errors?.firstName}
                      isValidate={true}
                      readOnly={isEditData}
                    />

                    {/* Last Name */}
                    <CommonInput
                      type="text"
                      name="lastName"
                      labelText="Last Name"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Last Name"
                      register={register('lastName')}
                      errors={errors?.lastName}
                      isValidate={false}
                      readOnly={isEditData}
                    />
                    {/* Email */}
                    <CommonInput
                      type="email"
                      name="email"
                      labelText="Email"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Email"
                      register={register('email')}
                      errors={errors?.email}
                      isValidate={true}
                      readOnly={isEditData || !!adminDetails?.email}
                      isEmailReadOnly={true}
                    />

                    {/* Password and Confirm-Password  */}
                    {showChangePassword && (
                      <>
                        <PasswordInput
                          label="New Password"
                          placeholder="New Password"
                          register={register}
                          name="newPassword"
                          errors={errors}
                          textColor={textColor}
                          brandStars={brandStars}
                        />

                        <PasswordInput
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          register={register}
                          name="confirmPassword"
                          errors={errors}
                          textColor={textColor}
                          brandStars={brandStars}
                        />
                        <Box marginTop={6}>
                          <IconButton
                            aria-label="Remove Merchant"
                            icon={<CloseIcon />}
                            size="sm"
                            ms={3}
                            marginTop={3}
                            onClick={() => {
                              setShowChangePassword(false);
                              clearErrors(['newPassword', 'confirmPassword']);
                              setValue('newPassword', '');
                              setValue('confirmPassword', '');
                            }}
                          />
                        </Box>
                      </>
                    )}
                  </SimpleGrid>

                  {!isEditData && (
                    <>
                      {!showChangePassword && (
                        <SimpleGrid
                          columns={{ base: 1, md: 2, lg: 3 }}
                          spacing="24px"
                        >
                          <Button
                            type="button"
                            bg="reviveOrange"
                            mt="50px"
                            h="50"
                            color="primary"
                            borderRadius="12px"
                            _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
                            onClick={() => setShowChangePassword(true)}
                          >
                            Update Password
                          </Button>
                        </SimpleGrid>
                      )}

                      <SimpleGrid
                        columns={{ base: 1, md: 2, lg: 3 }}
                        spacing="24px"
                      >
                        <CommonSubmitBtn
                          BTNtext="Submit"
                          isLoading={isEditProfileLoading}
                        />
                      </SimpleGrid>
                    </>
                  )}
                </FormControl>
              </form>
            )}
          </Card>
        </Flex>
      </Box>
    </>
  );
}
