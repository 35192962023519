import React, { useState } from 'react';
import {
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Text,
  Box,
} from '@chakra-ui/react';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import PropTypes from 'prop-types';

const PasswordInput = ({
  label,
  placeholder,
  register,
  name,
  errors,
  textColor,
  brandStars,
  isRequired,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Box>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          {label}
          {isRequired && <Text color={brandStars}>*</Text>}
        </FormLabel>

        <InputGroup size="md">
          <Input
            isRequired={true}
            fontSize="sm"
            placeholder={placeholder}
            mb="2px"
            size="lg"
            type={showPassword ? 'text' : 'password'}
            variant="auth"
            {...register(name)}
          />
          <InputRightElement display="flex" alignItems="center" mt="4px">
            <Icon
              color="gray.500"
              _hover={{ cursor: 'pointer' }}
              as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
              onClick={() => setShowPassword(!showPassword)}
            />
          </InputRightElement>
        </InputGroup>

        {errors?.[name] && (
          <p style={{ color: 'red', fontSize: '14px' }}>
            {errors[name]?.message}
          </p>
        )}
      </Box>
    </>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  textColor: PropTypes.string,
  brandStars: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default PasswordInput;
