import { Center, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UpdateProfile } from 'reactRedux/thunks/authThunk/authThunk';
import routes from 'routes.js';

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const { loginToken, adminDetails } = useSelector((state) => state.authSlice);
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loginToken && !adminDetails?.role) {
      dispatch(UpdateProfile()).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [loginToken, adminDetails, dispatch]);

  // Prevent rendering until `adminDetails` is available
  if (loading) {
    return (
      <>
        <Center
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          bg="rgba(255, 255, 255, 0.8)"
          zIndex="9999"
        >
          <Spinner size="xl" thickness="4px" speed="0.65s" />
        </Center>
      </>
    );
  }

  if (!loginToken) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  const currentPath = location.pathname;

  // Check if the current route is defined and if the role is allowed
  const isRouteAllowed = routes?.some((route) => {
    const pathRegex = new RegExp(
      `^${route?.layout}${route?.path?.replace(/:\w+/g, '[^/]+')}$`,
    );
    return (
      pathRegex?.test(currentPath) && route.roles?.includes(adminDetails?.role)
    );
  });

  return isRouteAllowed ? <Outlet /> : <Navigate to="/404" replace />;
};

export default ProtectedRoute;
